import React from 'react'
import { TextInput } from 'grommet'
import { TimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import moment from 'moment'
import MomentUtils from '@date-io/moment'
import { Constants, GlobalConfig, I18n } from 'galarm-config'

const TimeInput = ({ value, onChange, ...timeInputProps }) => {
  const timeFormat = GlobalConfig.timeFormat || GlobalConfig.defaultTimeFormat
  const format =
    timeFormat === Constants.TimeFormats.TWELVE_HOUR_FORMAT ? 'h:mm A' : 'H:mm'
  const ampm = timeFormat === Constants.TimeFormats.TWELVE_HOUR_FORMAT
  const timeValue = value ? moment(value) : null

  const onTimeChange = date => {
    onChange(date.toDate())
  }
  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <TimePicker
        ampm={ampm}
        value={timeValue}
        onChange={onTimeChange}
        format={format}
        TextFieldComponent={props => (
          <TextInput {...props} placeholder={I18n.t('chooseTime')} />
        )}
        {...timeInputProps}
      />
    </MuiPickersUtilsProvider>
  )
}

export default TimeInput
