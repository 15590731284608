module.exports = {
  specificDates: 'Datas específicas',
  specificDatesRepetition: {
    one: 'Datas específicas (1 ocorrência)',
    other: 'Datas específicas ({{count}} ocorrências)'
  },
  specificDatesRepetitionSummary:
    'Datas específicas ({{remaining}}/{{total}} restantes)',
  nOccurrences: { one: '1 Ocorrência', other: '{{count}} Ocorrências' },
  addOrRemoveAlarmOccurrences:
    'Pode adicionar ocorrências por qualquer ordem. Pode tocar em qualquer data ou hora para a alterar.',
  chooseTime: 'Escolher um momento...',
  specificDatesRepetitionPremiumScreenDescription:
    'Pode definir um alarme para tocar em datas específicas utilizando esta repetição.',
  chooseAtLeastOneOccurrence: 'Escolha pelo menos uma ocorrência.',
  editRotation: 'Editar rotação',
  alertIncidentClosed: 'Encerrado por {{memberName}} em {{dateString}}',
  alertIncidentRecovered: 'Recuperação automática em {{dateString}}',
  alertedAssignees: 'Alertado {{assigneesString}}',
  acknowledgedBy: 'Reconhecido por {{memberName}}',
  closedBy: 'Encerrado por {{memberName}}',
  docs: 'Documentação',
  docsSmall: 'Documentos',
  openingBillingPortal: 'Abrir o portal de faturação',
  releaseNotes: 'Actualizações recentes',
  loadingReleaseNotes:
    'A carregar actualizações recentes. Certifique-se de que está ligado à Internet.',
  unableToChangePlan:
    'Não é possível mudar para o plano anual. Contacte o apoio técnico através de "Definições > Feedback".',
  customSnooze: 'Outros',
  customSnoozeDuration: 'Outros ({{durationString}})',
  snoozeDuration: 'Duração da soneca',
  action: 'Ação',
  unableToAuthenticateUser: 'Não é possível autenticar o utilizador. {{error}}',
  addOccurrence: 'Adicionar ocorrência',
  closeIncidents: 'Incidentes próximos',
  closingIncidents: 'Encerramento de incidentes',
  olderThanHours: 'Mais de (horas)',
  errorLoadingRecaptcha:
    'Ocorreu um erro ao carregar o desafio reCAPTCHA. Tente novamente após algum tempo e contacte o apoio técnico se o problema persistir.',
  completeRecaptcha: 'Complete o seguinte desafio para continuar o registo.',
  incidentNotesPlaceholder: 'Adicionar notas a este incidente (opcional)',
  rotationMustHaveMembers: 'A rotação deve ter pelo menos um membro.',
  listenerMustHaveTriggers: 'O ouvinte deve ter pelo menos um acionador.',
  rotationExpiresOn: 'Expira em {{dateString}}',
  editAndSaveRotationToSetExpirationDate:
    'Editar e guardar a rotação para definir a data de expiração.',
  cantEnableAlarmNoValidNextDate:
    'Não é possível ativar o alarme. Não existe uma data seguinte válida para o alarme.',
  noValidNextDateForAlarm: 'Não existe uma próxima data válida para o alarme.',
  chords: 'Acordes',
  discovery: 'Descoberta',
  rainforest: 'Floresta tropical',
  wishes: 'Desejos',
  cantUpdateTimezoneForAllAlarms:
    'Não é possível atualizar o fuso horário dos alarmes existentes',
  cantUpdateRingtonesForAllAlarms:
    'Não é possível atualizar as definições de som e vibração dos alarmes existentes',
  preReminderWillRingAt: {
    zero: 'Um pré-aviso para este alarme tocará \nem {{timeString}}.',
    one: 'Um pré-aviso para este alarme tocará \nem {{timeString}} no dia anterior.',
    other:
      'Um pré-aviso para este alarme tocará \nem {{timeString}} {{count}} dias antes.'
  },
  verficationCodeInfo:
    'Receberá uma mensagem de texto ou uma chamada telefónica com um código de 4 dígitos.',
  verficationCodeInfoWhatsApp:
    'Receberá uma mensagem WhatsApp com um código de 4 dígitos.',
  deletePastAlarmsNotificationMessage:
    'Tem vários alarmes inactivos. Elimine os alarmes de que já não necessita.',
  confirmCountryCodeAndEnterNumber:
    'Confirme o código do seu país e introduza o seu número de telefone.',
  sendCodeOnSms: 'Enviar código por SMS',
  sendCodeOnWhatsApp: 'Enviar código via WhatsApp',
  verifyNumberWhatsApp:
    'Enviaremos uma mensagem WhatsApp com o código de verificação para:\n\n{{formattedMobileNumber}}',
  verifyNumberCall:
    'Receberá uma chamada telefónica com o código de verificação em:\n    \n{{formattedMobileNumber}}',
  defaultTimezoneForAlarm: 'Fuso horário predefinido para os alarmes',
  changeDefaultTimezoneForAllExistingAlarms: {
    zero: 'Pretende repor o fuso horário de todos os alarmes existentes?',
    one: 'Pretende alterar o fuso horário de todos os alarmes existentes para este fuso horário? Os alarmes tocarão à hora configurada no novo fuso horário.'
  },
  changeDefaultRingerSettingsForAllExistingAlarms:
    'Pretende alterar as definições de som e vibração dos alarmes existentes?\n  \n  Se selecionar Sim, os alarmes existentes tocarão com as novas definições. Se selecionar Não, os alarmes existentes continuarão a tocar com as definições antigas.',
  defaultTimezoneChangedForAllAlarms:
    'Fuso horário alterado para todos os alarmes.',
  ringerSettingsChangedForAllAlarms:
    'Definições de Som e Vibração alteradas para alarmes existentes.',
  unlimitedAlarmsFeatureDescription: 'Criar mais alarmes de grupo e de amigos.',
  instantAlarmsPremiumFeature: 'Enviar alertas instantâneos',
  instantAlarmsFeatureDescription:
    'Envie vários alertas instantâneos para os seus contactos e grupos.',
  duplicateAlarmPremiumFeature: 'Alarme duplicado',
  duplicateAlarmFeatureDescription:
    'Crie rapidamente cópias de um alarme com as mesmas definições e efectue alterações antes de as guardar.',
  showingAlarmsForDate: 'Mostrar alarmes para {{date}}',
  shareAlarm: 'Partilhar a ligação do alarme',
  shareAlarmDescription:
    'Pode partilhar este alarme com qualquer pessoa utilizando uma ligação sem a adicionar como contacto. Quando alguém toca na ligação no seu telemóvel, é criada uma cópia do alarme.',
  sendCodeAgain: 'Enviar código novamente',
  selectDefaultTimezoneForAlarmDefaultTooltipMessage:
    'Escolha um fuso horário predefinido para os seus alarmes. Pode alterar o fuso horário dos alarmes quando os cria.',
  alarmLinkRelativeToTimezone: '{{timeString}} no seu fuso horário.',
  alarmLinkNotRelativeToTimezone:
    'O alarme de toda a gente tocará às {{timeString}}',
  alarmShouldRingAt: 'O alarme de toda a gente tocará às:',
  showHourlyAlarms: 'Mostrar alarmes horários',
  critical: 'Crítico',
  alarmsWillBeAutomaticallyDeleted:
    'Os alarmes serão removidos permanentemente 30 dias após a sua eliminação.',
  unlimitedAlarmsPremiumFeature: 'Alarmes ilimitados de grupos e amigos',
  notAcknowledged: 'Não reconhecido',
  closed: 'Fechado',
  showFullScreenIntents: 'Mostrar o alarme no ecrã inteiro',
  showFullScreenIntentsDescription:
    'O alarme aparece no ecrã inteiro quando o telemóvel está bloqueado.',
  percentVolume: '{{volume}}% do volume',
  selectADate: 'Selecione uma data para ver os alarmes.',
  affiliates: 'Afiliados',
  referralCount: 'Contagem de referências',
  addAffiliate: 'Adicionar afiliado',
  creatingAffiliate: 'Criar afiliado',
  affiliateLink: 'Ligação de afiliado',
  upgradeRequiredForWeb: 'Atualização necessária',
  upgradeToPremiumForWeb:
    'Esta funcionalidade requer uma subscrição premium. Para utilizar esta funcionalidade, adquira uma subscrição premium a partir da aplicação móvel.',
  accountType: 'Tipo de conta',
  profileAndSettings: 'Perfil e definições',
  confirmIncidents: 'Confirmar vários incidentes',
  removeIncidents: 'Remover vários incidentes',
  incidents: 'Incidentes',
  closeSelectedIncidents: 'Encerrar incidentes selecionados',
  viewIncidentNotes: 'Ver notas de incidentes',
  incidentNotes: 'Notas de incidentes',
  addNote: 'Adicionar nota',
  noAdditionalNotesForIncident: 'Não há notas adicionais para este incidente.',
  fromIncident: 'Do incidente:',
  fromUsers: 'Dos utilizadores:',
  database: 'Base de dados',
  chooseFirstDayOfWeek: 'Selecionar o primeiro dia da semana',
  firstDayOfWeek: 'Primeiro dia da semana',
  changePlan: 'Alterar plano',
  currentPremiumSubscriptionStatus:
    'Está atualmente inscrito no plano {{planName}}.',
  currentProSubscriptionStatus: 'É membro da organização {{orgName}}.',
  changeToAnnualPlan: 'Alteração do plano anual',
  confirmChangeToAnnualPlan: 'Pretende mudar para o plano anual?',
  changeToAnnualPlanDescription:
    'Ser-lhe-á cobrado o valor de {{price}} para o plano anual. Quaisquer dias restantes no seu plano atual serão ajustados para o plano anual.',
  alarmTimezoneChanged:
    'Alguns alarmes foram actualizados para utilizarem o fuso horário do dispositivo porque o fuso horário utilizado para esses alarmes não é suportado por este dispositivo.',
  defaultTimezoneChanged:
    'O fuso horário predefinido para os alarmes é alterado para o fuso horário do dispositivo porque {{defaultTimezoneForAlarm}} não é suportado por este dispositivo.',
  invalidEmail: 'Endereço de correio eletrónico inválido.',
  unableToValidateEmail:
    'Não foi possível validar o correio eletrónico. {{error}}',
  havingProblems: 'Está a ter problemas?',
  alertData: 'Dados de alerta',
  notificationActionBehavior: 'Comportamento da ação de notificação',
  foreground: 'Primeiro plano',
  background: 'Antecedentes',
  notificationActionBehaviorTooltipMessage:
    'Quando definido para "Primeiro plano", tocar numa ação de notificação abrirá a aplicação em primeiro plano. Quando definido para "Fundo", tocar numa ação de notificação manterá a aplicação em segundo plano.',
  getCodeOnWhatsApp: 'Obter código no WhatsApp',
  getCode: 'Obter código',
  dontHaveWhatsApp: 'Não tem o WhatsApp?',
  unableToSendCodeViaWhatsAppTitle:
    'Não é possível enviar um código através do WhatsApp',
  unableToSendCodeViaWhatsAppMessage:
    '{{error}}\n      \nPor favor, solicite o código novamente num canal diferente.',
  dataSecurity: 'Segurança dos dados',
  alarms: 'Alarmes',
  contacts: 'Contactos',
  groups: 'Grupos',
  settings: 'Definições',
  notificationsTab: 'Notificações',
  specifyTimeInFutureForAlarm: 'O alarme não pode estar no passado.',
  specifyTimeInFutureForPreReminder:
    'O pré-aviso de alarme pertence ao passado.',
  specifyNameForGroup: 'O nome do grupo é obrigatório.',
  updatingGroupName: 'Atualização das informações do grupo.',
  unableToEditGroupName:
    'Não é possível atualizar as informações do grupo. Tente novamente após algum tempo.',
  selectSmallerImage:
    'A imagem é maior do que 10 MB. Seleccione uma imagem mais pequena.',
  sendingVerificationCode:
    'Envio do código de verificação para {{formattedMobileNumber}}.',
  verifyingCode: 'Verificação do código.',
  creatingUserProfile: 'Configurar...',
  unabeToCreateUserProfile:
    'Não é possível criar uma conta neste momento. Tente novamente dentro de alguns instantes.',
  unableToSendVerificationCode:
    'Não é possível enviar o código de verificação. {{error}}',
  unableToVerifyCode: 'Não é possível verificar o código. {{error}}',
  unableToRegisterUserWithoutMobileNumber:
    'Não é possível efetuar a configuração. {{error}}',
  permissionDeniedToAccessCameraIos:
    'Galarm não tem permissão para aceder à câmara. \n\nVá a Definições > Galarm e permita o acesso à câmara.',
  permissionDeniedToAccessCameraAndroid:
    'Galarm não tem permissão para aceder à câmara.\n\nVá a Definições > Aplicações > Galarm > Permissões e permita o acesso à câmara.',
  permissionDeniedToAccessPhotosIos:
    'Galarm não tem permissão para aceder a fotografias.\n\nAceda a Definições > Galarm e permita o acesso às fotografias.',
  permissionDeniedToAccessPhotosAndroid:
    'Galarm não tem permissão para aceder a fotografias.\n\nAceda a Definições > Aplicações > Galarm > Permissões e permita o acesso ao armazenamento.',
  permissionDeniedToAccessCalendarIos:
    'O Galarm não tem permissão para aceder ao calendário.\nAceda a Definições > Calendários e permita o acesso ao Galarm.',
  permissionDeniedToAccessCalendarAndroid:
    'O Galarm não tem permissão para aceder ao calendário.\nAceda a Definições > Aplicações > Galarm > Permissões e permita o acesso ao Calendário.',
  verificationCodeSent:
    'Código de verificação enviado para {{formattedMobileNumber}}.',
  rateTheApp:
    'Esperamos que o Galarm seja útil para si. Por favor, reserve um momento para nos avaliar na Play Store. Obrigado pelo vosso apoio.',
  backgroundAppRefreshDisabledPermission:
    'Os alarmes podem não tocar porque o Galarm não tem permissão para efetuar a atualização da aplicação em segundo plano.\n\nToque em "Ativar" e active a "Atualização da aplicação em segundo plano".',
  selectedGroupUnselected:
    'Pode selecionar um grupo ou um conjunto de contactos como participantes. O grupo anteriormente selecionado deixa de ser selecionado. Continuar?',
  selectedContactsUnselected:
    'Pode selecionar um grupo ou um conjunto de contactos como participantes. Os contactos anteriormente seleccionados deixarão de ser seleccionados. Continuar?',
  notificationsDisabledIos:
    'Os alarmes não tocarão porque o Galarm não tem permissão para mostrar notificações.\n\nAceda a Definições > Galarm > Notificações e active "Alertas", "Sons" e "Banners".',
  notificationsDisabledAndroid:
    'As notificações de alarme não serão apresentadas no ecrã.\n\nToque em "Ativar", seleccione "Notificações" e permita as notificações.',
  alarmNotificationChannelDisabled:
    'As notificações de alarme não aparecem no ecrã. \n\nToque em "Ativar" e permita que as notificações de alarme apareçam no ecrã.',
  alarmLockScreenNotificationlDisabled:
    'As notificações de alarme não serão apresentadas no ecrã de bloqueio. \n\nToque em "Ativar" e permita que as notificações de alarme sejam apresentadas no ecrã de bloqueio.',
  alarmWillNotRingDueToNotificationsDisabledIos:
    'Os alarmes não tocarão porque as notificações estão desactivadas.\n\nAceda a Definições > Galarm > Notificações e active "Alertas", "Sons" e "Banners".',
  alarmWillNotRingDueToCriticalAlertsDisabledIos:
    'Os alarmes não tocam quando o telefone está silenciado porque os alertas críticos estão desactivados.\n\nVá a Definições > Galarm > Notificações e active os "Alertas críticos".',
  alarmWillNotRingDueToNotificationsDisabledAndroid:
    'O alarme não aparecerá no ecrã porque as notificações estão desactivadas.\n\nToque em "Ativar", seleccione "Notificações" e permita as notificações.',
  noNotificationsScheduledAlarmInPast:
    'O alarme está no passado. Não há notificações programadas.',
  alarmOverAllowedLimit: {
    zero: '\nAtingiu o seu limite de alarmes de {{limit}} alarmes.\n\nPor favor, elimine ou reutilize um alarme existente.',
    one: 'Atingiu o seu limite de alarmes de {{limit}} alarmes.\n\nTem 1 alarme inativo que pode eliminar ou reutilizar.',
    other:
      'Atingiu o seu limite de alarmes de {{limit}} alarmes.\n\nTem {{count}} alarmes inactivos que pode eliminar ou reutilizar.'
  },
  alarmAlreadyConfirmed: 'Já confirmou.',
  alarmAlreadyDeclined: 'Já recusou.',
  participantMovedToPosition: '{{name}} passou para a posição {{count}}.',
  memberIsFirstParticpant: '{{name}} é o primeiro participante.',
  memberIsLastParticpant: '{{name}} é o último participante.',
  cantContinueWithRegistrationWhenOffline:
    'Não é possível continuar com o registo porque o dispositivo está offline.',
  cantContinueWithRegistrationWhenOfflineWithTryAgain:
    'Não é possível continuar com o registo porque o dispositivo está offline. Certifique-se de que está ligado à Internet.',
  confirmBlockContact: 'Deixará de receber alarmes de {{name}}. Continuar?',
  confirmUnblockContact:
    'O desbloqueio do {{name}} também irá restaurar os alarmes bloqueados. Continuar?',
  unblockingContact: 'Desbloquear {{name}} e restabelecer os alarmes.',
  unableToUnblockContact: 'Não é possível desbloquear o contacto. {{error}}',
  blockingContact: 'Bloqueio {{name}}.',
  unableToBlockContact: 'Não é possível bloquear o contacto. {{error}}',
  cantAddContactToAlarmBecauseBlocked:
    'Bloqueou {{name}}. Desbloqueia-o para o adicionares como participante no alarme.',
  cantAddContactToGroupBecauseBlocked:
    'Bloqueou {{name}}. Desbloqueia-o para o adicionares como membro do grupo.',
  sendMessageNotAllowedForPastAlarms:
    'Não é possível enviar mensagens para alarmes inactivos.',
  nameIsRequired: 'O nome é obrigatório.',
  confirmDisableGroupAlarm:
    'A desativação do alarme de grupo também desactivará o alarme para outros participantes. Continuar?',
  removeMemberFromGroup: 'Pretende remover {{name}} deste grupo?',
  leaveGroupConfirm: 'Queres sair deste grupo?',
  deviceOffline:
    'O dispositivo está offline. Certifique-se de que está ligado à Internet e tente novamente.',
  cantUpdateGroupDefaulConfigWhenOffline:
    'A configuração de grupo modificada não será aplicada a alarmes futuros porque o dispositivo está offline.',
  specifyFeedback: 'É necessário dar feedback.',
  specifyEmailId: 'O endereço de correio eletrónico é obrigatório.',
  specifyValidEmailId: 'O endereço de correio eletrónico não é válido.',
  specifyProblem: 'É necessária uma descrição do problema.',
  appInstalledUsingSameNumberOnDifferentPhone:
    'Instalou o Galarm num dispositivo diferente utilizando o mesmo número. A aplicação neste dispositivo é reiniciada.',
  invalidUid:
    'Esta conta de utilizador já não existe. A aplicação Galarm neste dispositivo foi reiniciada.',
  accountDeleted: 'A sua conta foi eliminada.',
  deleteAccount: 'Eliminar a minha conta',
  deletingAccount: 'Eliminar a sua conta',
  howCanWeImprove: 'Lamentamos a sua partida. Como é que podemos melhorar?',
  noMusicAlert: 'Não foram encontrados ficheiros de música.',
  noLongerAvailableMusic:
    '{{fileName}} já não está disponível. O toque de chamada foi alterado para {{defaultRingtoneName}}',
  userInactiveTitle: 'Bem-vindo de volta!',
  userInactiveMessage: 'Crie alarmes para coisas importantes e nunca os perca.',
  createAlarm: 'Criar alarme',
  updatedAppAvailable: 'Está disponível uma nova versão da aplicação.',
  alarmOccurrenceDisabled: 'Desativado para esta ocorrência',
  ownAlarmOccurrenceDone: {
    zero: 'Marcado feito em {{timeString}}',
    one: 'Marcado como concluído em {{timeString}} em {{dateString}}'
  },
  ownAlarmOccurrenceSkipped: {
    zero: 'Saltou em {{timeString}}',
    one: 'Ignorado em {{timeString}} em {{dateString}}'
  },
  enabledAlarmsCount: {
    one: '1 alarme ativado.',
    other: '{{count}} alarmes activados.'
  },
  enabledAndNotEnabledAlarmsCount: {
    one: '1 alarme ativado. Os alarmes sem ocorrências futuras permanecem desactivados.',
    other:
      '{{count}} alarmes activados. Os alarmes sem ocorrências futuras permanecem desactivados.'
  },
  disabledAlarmsCount: {
    one: '1 alarme desativado.',
    other: '{{count}} alarmes desactivados.'
  },
  ownAlarmOccurrenceUnacknowledged: 'Não respondeu',
  participantAlarmOccurrenceDone: {
    zero: 'Marcação efectuada por {{participantName}} em {{timeString}}',
    one: 'Marcado feito por {{participantName}} em {{timeString}} em {{dateString}}'
  },
  participantAlarmOccurrenceSkipped: {
    zero: 'Ignorado por {{participantName}} em {{timeString}}',
    one: 'Ignorado por {{participantName}} em {{timeString}} em {{dateString}}'
  },
  participantAlarmOccurrenceUnacknowledged: '{{participantName}} não respondeu',
  groupAlarmOccurrenceConfirmed: {
    zero: 'Confirmado em {{timeString}}',
    one: 'Confirmado em {{timeString}} em {{dateString}}'
  },
  groupAlarmOccurrenceDeclined: {
    zero: 'Recusado em {{timeString}}',
    one: 'Recusado em {{timeString}} em {{dateString}}'
  },
  warning: 'Aviso',
  info: 'Informações',
  confirm: 'Confirmar',
  error: 'Erro',
  cantUploadImage: 'Não é possível carregar a imagem',
  cantDeleteImage: 'Não é possível eliminar a imagem',
  cantEditGroup: 'Não é possível editar o grupo',
  cantAddNewContact: 'Não é possível adicionar um novo contacto',
  cantDeleteContact: 'Não é possível apagar o contacto',
  cantEditContact: 'Não é possível editar o contacto',
  cantSaveAlarm: 'Não é possível salvar o alarme',
  cantSaveGroup: 'Não é possível guardar o grupo',
  cantReloadContacts: 'Não é possível recarregar contactos',
  cantTakePhoto: 'Não é possível tirar fotografias',
  cantExportToCalendar: 'Não é possível exportar para o calendário',
  cantOpenPhotoLibrary: 'Não é possível abrir a biblioteca de fotografias',
  cantDeleteAlarmHistory: 'Não é possível eliminar o histórico de alarmes',
  cannotDuplicateAlarm: 'Não é possível duplicar o alarme',
  upgradeToPremiumToDuplicateAlarm:
    'Actualize para Premium para criar mais alarmes de grupo e de amigo.',
  confirmAcceptAlarm: 'Aceitar o alarme?',
  confirmRejectAlarm: 'Alarme de declínio?',
  deleteAlarm: 'Eliminar o alarme?',
  restoreAlarmConfirmation: 'Restaurar o alarme?',
  respondToRepeatingAlarm: {
    zero: 'Foi adicionado como destinatário ao alarme {{alarmName}} por {{alarmCreatorName}}. Será alertado em {{alarmTimeString}} {{alarmRepetitionString}} a partir de {{alarmDateString}}',
    one: 'Foi adicionado como participante no alarme {{alarmName}} por {{alarmCreatorName}}. Será alertado em {{alarmTimeString}} {{alarmRepetitionString}} a partir de {{alarmDateString}}'
  },
  respondToOneTimeAlarm: {
    zero: 'Foi adicionado como destinatário do alarme {{alarmName}} por {{alarmCreatorName}}. Será alertado em {{alarmTimeString}} {{alarmDateString}}',
    one: 'Foi adicionado como participante no alarme {{alarmName}} por {{alarmCreatorName}}. Será alertado em {{alarmTimeString}} {{alarmDateString}}'
  },
  cantMoveMemberUp: 'Não é possível mover {{name}} Up',
  cantMoveMemberDown: "Can't Move {{name}} Down",
  decline: 'Declínio',
  cantEditProfile: 'Não é possível editar o perfil',
  cantSelectContact: 'Não é possível selecionar o contacto',
  cantSendMessage: 'Não é possível enviar uma mensagem',
  cantSendVerificationCode: 'Não é possível enviar o código de verificação',
  confirmBlockContactTitle: 'Confirmar o contacto do bloco',
  confirmUnblockContactTitle: 'Confirmar o desbloqueio do contacto',
  cantSaveProfile: 'Não é possível guardar o perfil',
  cantBlockContact: 'Não é possível bloquear o contacto',
  cantUnblockContact: 'Não é possível desbloquear o contacto',
  cantLoadEarlierMessages: 'Não é possível carregar mensagens anteriores',
  cantRestoreAlarm: 'Não é possível restaurar o alarme',
  cantSendFeedback: 'Não é possível enviar comentários',
  cantSubmitProblem: 'Não é possível submeter o problema',
  cantDeletePastAlarmsInBulk:
    'Não é possível eliminar alarmes inactivos em massa',
  cantAddNewGroup: 'Não é possível adicionar um novo grupo',
  alreadySnoozing: 'Já a dormir',
  markAlarmUndone:
    "Marcou este alarme como 'Concluído' {{durationString}} ago. Anular?",
  markAlarmUnskipped: 'Ignorou este alarme {{durationString}} ago. Anular?',
  cantUndo: 'Não é possível desfazer',
  fetchMusicFromPhone: 'Encontrar música disponível.',
  enableAllAlarmsInCategory: 'Ativar todos os alarmes desta categoria?',
  disableAllAlarmsInCategory: 'Desativar todos os alarmes desta categoria?',
  allAlarmsAlreadyEnabled:
    'Todos os alarmes desta categoria já estão activados.',
  allAlarmsAlreadyDisabled:
    'Todos os alarmes desta categoria já estão desactivados.',
  time: 'Tempo',
  timeForBuddy: 'Tempo para o Buddy',
  timeForYou: 'Lembrar-me em',
  name: 'Nome',
  title: 'Título',
  notes: 'Notas',
  repeat: 'Repetir',
  preReminder: 'Pré-lembrete',
  remove: 'Remover',
  preReminderShortAlert: '{{duration}} Pré-lembrete',
  preReminderTitle: '{{alarmName}} em {{date}}',
  preReminderGroupHelperText:
    'Este pré-aviso é apenas para si. Os outros participantes no alarme podem definir os seus próprios pré-avisos assim que receberem o alarme.',
  addPreReminder: 'Adicionar pré-lembrança',
  helperTextForPreReminder:
    'Toque no botão de reposição na parte superior para remover o pré-aviso.',
  edit: 'Editar',
  delete: 'Eliminar',
  creator: 'Criador',
  useAsDefaultGroupConfiguration: 'Aplicar a alarmes futuros',
  done: 'Feito',
  skipped: 'Ignorado',
  save: 'Guardar',
  members: 'Membros',
  mobileNumber: 'Número de telefone',
  enter: 'Entrar',
  addMembers: 'Adicionar membros',
  respond: 'Responder',
  yes: 'Sim',
  no: 'Não',
  cancel: 'Cancelar',
  participants: 'Participantes',
  takePhoto: 'Tirar fotografia',
  chooseFromLibrary: 'Selecionar da biblioteca',
  resendCode: 'Reenviar código',
  register: 'Registo',
  inviteFriends: 'Partilhar esta aplicação',
  invite: 'Convite',
  incorrectNumber: 'Número incorreto?',
  date: 'Data',
  cascadingAlarmInterval: 'Tempo entre alertas de participantes',
  ringParticipantAlarmsByDefault: 'Tocar alarmes sem aceitar',
  alarmRingtone: 'Toque de alarme',
  pickASong: 'Escolher música do telemóvel',
  privacyPolicy: 'Política de privacidade',
  sendFeedback: 'Feedback',
  galarmWeb: 'Galarm Web',
  galarmEnterprise: 'Galarm Pro',
  galarmAdmin: 'Galarm Admin',
  troubleshooting: 'Resolução de problemas',
  alarmDidntRingHelp: 'Ajuda! O meu alarme não tocou',
  autoStartSettingHelperText:
    'Se o seu telemóvel tiver uma definição de início automático, certifique-se de que o início automático está ativado para o Galarm.',
  batteryOptimizationHelperText:
    'Desativar a otimização da bateria para o Galarm',
  troubleshootingHelperText:
    'As definições seguintes podem estar a impedir que os alarmes toquem como esperado. Reveja estas definições.',
  getMoreHelpText: 'Continua a ter problemas?',
  autoStartSetting: 'Arranque automático',
  batteryOptimization: 'Otimização da bateria',
  battery: 'Bateria',
  rateTheAppSettings: 'Avaliar esta aplicação',
  about: 'Acerca de & Ajuda',
  leaveGroup: 'Grupo de férias',
  removeGroup: 'Remover grupo',
  viewMember: 'Ver {{memberName}}',
  removeMember: 'Remover {{memberName}}',
  changeMemberState: 'Alterar quem pode alertar {{memberName}}',
  changeYourGroupState: 'Alterar quem o pode alertar',
  numMembersInGroup: 'Membros: {{count}}',
  notifications: 'As notificações da aplicação estão desactivadas',
  backgroundAppRefresh:
    'A atualização da aplicação em segundo plano está desactivada',
  groupsInCommon: 'Grupos em comum',
  alarmsInCommon: 'Alarmes em comum',
  close: 'Fechar',
  responsePending: 'A sua resposta?',
  later: 'Mais tarde',
  install: 'Instalar',
  all: 'Todos',
  numYears: { one: '1 ano', other: '{{count}} anos' },
  numMonths: { one: '1 mês', other: '{{count}} meses' },
  numDays: { one: '1 dia', other: '{{count}} dias' },
  hours: { one: '1 hora', other: '{{count}} horas' },
  minutes: { one: '1 minuto', other: '{{count}} minutos' },
  seconds: { one: '1 segundo', other: '{{count}} segundos' },
  minutesSmall: '{{count}} min',
  secondsSmall: '{{count}} sec',
  hoursAfter: { one: '1 hora depois', other: '{{count}} horas depois' },
  minutesAfter: { one: '1 minuto depois', other: '{{count}} minutos depois' },
  hoursAfterBuddy: {
    one: '1 hora depois do amigo',
    other: '{{count}} horas depois do amigo'
  },
  minutesAfterBuddy: {
    one: '1 minuto depois do companheiro',
    other: '{{count}} minutos depois do companheiro'
  },
  longerThanAnHour: '> 1 hora',
  licenses: 'Licenciamento de terceiros',
  version: 'Versão',
  termsOfService: 'Termos do serviço',
  showPrivacyPolicy: '> Política de privacidade',
  showTermsOfService: '> Termos de serviço',
  showLicenses: '> Licenciamento de terceiros',
  change: 'Alterar',
  snooze: 'Snooze',
  ringOnVibrate: 'Tocar em silêncio',
  vibrate: 'Vibrar',
  ringOnEarphoneOnly: 'Alarme sonoro apenas nos auriculares',
  ringOnSpeakerAndEarphone: 'Alarme sonoro no altifalante e nos auscultadores',
  whenEarphoneIsConnected: 'Quando os auriculares estão ligados',
  tapGesture: 'Torneira',
  slideGesture: 'Deslizar',
  silent: 'Silencioso',
  volume: 'Volume',
  soundAndVibration: 'Som e vibração',
  advancedSettings: 'Definições avançadas',
  gestureToDismissAnAlarm: 'Gesto para desligar um alarme',
  someNotificationsAreMuted: 'Algumas notificações estão silenciadas',
  allNotificationsEnabled: 'Todas as notificações estão activadas',
  notificationSettings: 'Definições de notificação',
  galarmWebNotifications: 'Galarm Web Notifications',
  alarmsfromOthersNotifications: 'Alarmes dos participantes Notificações',
  sharedAlarmNotifications: 'Notificações de alarme partilhadas',
  alarmChatNotifictions: 'Alarme Notificações de chat',
  groupNotifications: 'Notificações de grupo',
  alarmAcknowledgementNotifications: 'Notificações de reconhecimento de alarme',
  send: 'Enviar',
  prev: 'Anterior',
  snoozing: '{{timeString}}',
  specifySnoozeDuartion: 'Especificar a duração do adiamento',
  chooseDifferentDuration: 'Escolher uma duração diferente',
  noCalendarsFound: 'Não foram encontrados calendários no dispositivo.',
  eventWillEndAt: 'O evento terminará em {{timeString}}.',
  selectDurationWithinTheSameDay:
    'O evento não pode ultrapassar o limite do dia.',
  cannotMapRepeatTypeInTheDeviceCalendar:
    'Não é possível "Exportar para calendário" porque a definição "Repetir" do alarme não é suportada pelo calendário.',
  alarmSavedToCalendar: 'O alarme foi guardado com êxito no calendário.',
  faq: 'Perguntas mais frequentes',
  openSettings: 'Abrir definições',
  openAppSettings: 'Abrir as definições da aplicação',
  alreadyDone: 'Feito',
  enableAutoStart: 'Ativar o arranque automático',
  restrictedApps: 'Aplicações restritas',
  unmonitoredApps: 'Aplicações não monitorizadas',
  enableProtected: 'Aplicações protegidas',
  manageAppLaunch: 'Gerir o lançamento da aplicação',
  unblockContact: 'Desbloquear o contacto',
  blockContact: 'Contacto do bloco',
  call: 'Chamada {{name}}',
  text: 'Texto {{name}}',
  chat: 'Conversa',
  ok: 'OK',
  stopSnooze: 'Parar a soneca',
  emailId: 'O seu endereço de correio eletrónico',
  feedback: 'Feedback',
  submit: 'Enviar',
  reportAProblem: 'Comunicar um problema',
  enableAlarm: 'Ativar o alarme',
  restoreAlarm: 'Restaurar o alarme',
  deletePastAlarms: 'Eliminar alarmes inactivos',
  accept: 'Aceitar',
  defaultPersonalAlarmName: 'Lembrete pessoal',
  defaultGroupAlarmName: 'Evento de grupo',
  defaultRecipientAlarmName: 'Alarme Buddy',
  search: 'Pesquisar',
  recent: 'Recentes',
  upcoming: 'Em breve',
  missed: 'Faltou',
  expired: 'Expirado',
  past: 'Passado',
  active: 'Ativo',
  inactive: 'Inativo',
  allAlarms: 'Todos os alarmes',
  myAlarms: 'Os meus alarmes',
  participantAlarms: 'Alarmes dos participantes',
  pendingParticipantAlarms: 'Novos alarmes de outros',
  alarmsWithUnreadChatMessages: 'Alarmes com novo chat',
  systemNotifications: 'Mensagens do sistema',
  missedAlarms: 'Alarmes perdidos',
  recentlyDeletedAlarms: 'Alarmes recentemente eliminados',
  searchAlarms: 'Pesquisar alarmes',
  participant: 'Participante',
  you: 'Você',
  none: 'Nenhum',
  onceOnly: 'Apenas uma vez',
  weekdays: 'Dias úteis',
  weekends: 'Fins-de-semana',
  everyDayString: 'Diário',
  everySunday: 'Todos os domingos',
  everyMonday: 'Todas as segundas-feiras',
  everyTuesday: 'Todas as terças-feiras',
  everyWednesday: 'Todas as quartas-feiras',
  everyThursday: 'Todas as quintas-feiras',
  everyFriday: 'Todas as sextas-feiras',
  everySaturday: 'Todos os sábados',
  sunday: 'Domingo',
  monday: 'Segunda-feira',
  tuesday: 'Terça-feira',
  wednesday: 'Quarta-feira',
  thursday: 'Quinta-feira',
  friday: 'Sexta-feira',
  saturday: 'Sábado',
  january: 'Janeiro',
  february: 'Fevereiro',
  march: 'Março',
  april: 'Abril',
  may: 'Maio',
  june: 'Junho',
  july: 'Julho',
  august: 'Agosto',
  september: 'Setembro',
  october: 'Outubro',
  november: 'Novembro',
  december: 'Dezembro',
  jan: 'Jan',
  feb: 'Fev',
  mar: 'Mar',
  apr: 'Abr',
  shortMay: 'Mai',
  jun: 'Jun',
  jul: 'Jul',
  aug: 'Ago',
  sep: 'Set',
  oct: 'Out',
  nov: 'Nov',
  dec: 'Dez',
  sun: 'Dom',
  mon: 'Seg',
  tue: 'Ter',
  wed: 'Qua',
  thu: 'Qui',
  fri: 'Sex',
  sat: 'Sáb',
  first: '1.o',
  second: '2.o',
  third: '3.o',
  fourth: '4.o',
  fifth: '5.o',
  every: 'Todos',
  oddNumberedDays: 'Datas ímpares',
  evenNumberedDays: 'Datas pares',
  noParticipants: 'Nenhum participante',
  typeYourMessage: 'Escreva a sua mensagem',
  cantPostMessageToPastAlarm:
    'Não é possível enviar uma mensagem para um alarme inativo',
  groupName: 'Grupo: {{name}}',
  alarm: 'Alarme',
  confirmed: 'confirmado',
  declined: 'recusado',
  Done: 'Feito',
  Skip: 'Saltar',
  Confirm: 'Confirmar',
  Decline: 'Declínio',
  Accept: 'Aceitar',
  today: 'hoje',
  on: 'sobre',
  recipientAlarm: 'Alarme Buddy',
  recipient: 'Beneficiário',
  remindMeAfter: 'Relembrar-me',
  remindMeIn: 'Lembrar-me em',
  newRecipientAlarm: 'Novo alarme Buddy',
  editRecipientAlarm: 'Editar o alarme de amigo',
  newInstantAlarm: 'Novo alarme instantâneo',
  instantAlarms: 'Alarmes instantâneos',
  alarmDone: 'Alarme marcado como concluído',
  alarmSkipped: 'Alarme ignorado',
  undo: 'Anular',
  enterVerificationCode: 'Introduza o código de 4 dígitos abaixo:',
  initializing: 'Inicialização',
  tryAgain: 'Tentar de novo',
  nMore: '+ {{count}} mais',
  enableNotification: 'Ativar',
  enableAlarmNotificationChannel: 'Ativar',
  enableBackgroundAppRefresh: 'Ativar',
  viewImpactedAlarms: 'Alarmes',
  viewImpactedGroups: 'Grupos',
  dismiss: 'Rejeitar',
  minsInterval: '+ {{count}}m',
  hoursInterval: '+ {{count}}h',
  daysInterval: '+ {{count}}d',
  snoozeUnitHours: 'Horas',
  snoozeUnitMinutes: 'Minutos',
  snoozeUnitDays: 'Dias',
  selectMinutes: 'Selecionar minutos',
  selectHours: 'Selecionar horas',
  selectDays: 'Selecionar dias',
  selectHoursAndMinutes: 'Selecionar horas e minutos',
  enterDuration: 'Duração',
  snoozeUnit: 'Unidade',
  specifySnoozeUnit: 'Especificar a unidade de snooze',
  specifySnoozeDuration: 'Especificar a duração do adiamento',
  duration: 'Duração',
  quickAlarmName: {
    zero: '{{minutes}} lembrete mínimo',
    one: '{{hours}} lembrete de horas',
    other: '{{hours}} hora {{minutes}} lembrete de minutos'
  },
  alarmTitleForGoogleVoiceTimerCommand: '{{durationString}} lembrete',
  addedAlarmFromVoiceCommand:
    'Adicionado um alarme para {{alarmName}} em {{dateString}}',
  addedTimerFromVoiceCommand: 'Adicionado um temporizador para {{duration}}',
  clear: 'Limpo',
  ringtoneDuration: 'Duração do toque',
  chooseDuration: 'Toque numa duração. Pode tocar várias vezes.',
  newAlarm: 'Novo alarme',
  quickAlarm: 'Lembrete rápido',
  quickAlarmWillRingAt: 'O alarme tocará em {{timeString}}.',
  random: 'Aleatório',
  enableAllAlarms: 'Ativar todos os alarmes',
  disableAllAlarms: 'Desativar todos os alarmes',
  deleteAllAlarms: 'Eliminar todos os alarmes',
  hideCategory: 'Ocultar categoria',
  unhideCategory: 'Reexibir categoria',
  dontShowHiddenCategories: 'Não mostrar categorias ocultas',
  showHiddenCategories: 'Mostrar categorias ocultas',
  welcomeAlarmsTitle: 'Vamos lá começar!',
  welcomeAlarmsMessage: 'Eis alguns dos alarmes mais utilizados:',
  onboardingAlarms: 'Alarmes de integração',
  hiddenAlarmCategories: 'Categorias ocultas',
  restorePurchases: 'Restaurar compras',
  noAvailablePurchasesFound:
    'Não foram encontradas compras disponíveis para a sua conta.\n\nEnvie-nos um feedback utilizando "Definições > Feedback" se tiver uma compra válida que não consiga restaurar.',
  restoringPurchases: 'Restaurar as compras',
  fetchingContacts: 'Verificar que contactos estão a utilizar o Galarm',
  enterNameAndUploadPhotoForUser:
    'Introduza o seu nome abaixo e, opcionalmente, carregue a sua fotografia de perfil',
  enterNameAndUploadPhotoForGroup:
    'Introduza um nome abaixo e, opcionalmente, carregue uma fotografia de grupo',
  noContactsWithAppInstalled:
    'Ninguém nos seus contactos está a utilizar o Galarm.',
  galarmContacts: {
    one: '1 contacto Galarm',
    other: '{{count}} Contactos Galarm'
  },
  contactsPendingInvitation: {
    one: '1 convite pendente',
    other: '{{count}} convites pendentes'
  },
  otherContacts: {
    one: '1 outro contacto',
    other: '{{count}} outros contactos'
  },
  over100Contacts: 'Mais de 100 outros contactos',
  noAlarms:
    'Não tem quaisquer alarmes. Toque em + para adicionar um novo alarme.',
  noContactsWeb: {
    one: 'Não encontrámos nenhum contacto que utilize o Galarm. Ou não temos permissão para aceder aos seus contactos ou ninguém nos seus contactos está a utilizar o Galarm. Pode sincronizar os seus contactos a partir da aplicação móvel.\n\nPode adicionar contactos que utilizam o Galarm aos seus alarmes de diferentes formas para beneficiar da aplicação',
    other:
      'Registe-se na aplicação móvel para começar a adicionar contactos aos seus alarmes.'
  },
  viewingAlarmsInCommon: 'Alarmes em comum com {{name}}',
  viewingGroupsInCommon: 'Grupos em comum com {{contactName}}',
  changeGroupMemberConfigTextForCascadingAlarm:
    'Os participantes activos serão alertados pela ordem apresentada.',
  changeGroupMemberConfigTextForSimultaneousAlarm:
    'Apenas os participantes activos serão alertados.',
  changeGroupMemberStateText: {
    zero: 'Alterar os membros que o podem alertar quando é criado um alarme para o grupo "{{groupName}}".\n\nSó será alertado se o alarme for criado por membros autorizados.',
    one: 'Alterar os membros que podem alertar {{memberName}} quando é criado um alarme para o grupo "{{groupName}}".\n\n{{memberName}} será alertado apenas se o alarme for criado por membros autorizados.'
  },
  allowed: 'Permitido',
  notAllowed: 'Não permitido',
  changeStatus: 'Membros permitidos',
  noUpcomingAlarms: 'Sem alarmes futuros',
  showMore: 'Mostrar mais',
  nMorePastAlarms: {
    one: 'Mais 1 alarme inativo',
    other: '{{count}} mais alarmes inactivos'
  },
  shareAppMessage:
    'Faço todas as minhas coisas atempadamente, criando lembretes para elas através da aplicação Galarm. Através desta aplicação, também nos podemos incluir uns aos outros nos nossos lembretes. Recomendo a transferência desta inovadora aplicação de alarme e lembretes. {{downloadLink}}',
  addingMembers: 'Adicionar membros.',
  unableToAddMembers: 'Não é possível adicionar membros. {{error}}',
  removingMember: 'Remoção de membro.',
  unableToRemoveMember: 'Não foi possível remover o membro. {{error}}',
  leavingGroup: 'Grupo de partida.',
  unableToLeaveGroup: 'Não é possível sair do grupo. {{error}}',
  expiredPersonalAlarmForCreator: {
    zero: 'Este alarme expirou há {{durationString}}. Toque em "Concluído" para concluir o alarme.',
    one: 'Este alarme expirou há {{durationString}}. Toque em "Concluído" para concluir o alarme e notificar os participantes.',
    other:
      'Este alarme expirou há {{durationString}}. Toque em "Concluído" para concluir o alarme e notificar os participantes.'
  },
  donePersonalAlarmForCreator:
    'Este alarme expirou há {{durationString}}. O alarme está marcado como concluído.',
  skippedPersonalAlarmForCreator:
    'Este alarme expirou há {{durationString}}. O alarme foi ignorado.',
  expiredGroupAlarm:
    'Este alarme expirou há {{durationString}}. Por favor, confirme a sua participação.',
  expiredPersonalAlarmForParticipant:
    'Este alarme expirou há {{durationString}}. Por favor, relembre {{creatorName}}.',
  alarmDurationAndroid:
    'Alarme programado para {{durationString}} a partir de agora.',
  alarmDurationIos:
    'Alarme programado para {{durationString}} a partir de agora.',
  alarmFeedbackHeader:
    'Irá enviar parte das informações deste alarme para fins de depuração. Quaisquer dados pessoais, como o nome do alarme, notas ou participantes, não serão partilhados.',
  privacyPolicyHeader:
    'O respeito pela sua privacidade está sempre presente nos nossos relógios. Tal como o utilizador, também nós valorizamos a privacidade, pelo que queremos que saiba que não partilhamos as suas informações pessoais com ninguém, exceto quando necessário para a instalação da aplicação ou quando exigido por lei. Em todos os casos, nunca vendemos as suas informações. É tão simples quanto isso!\n \nOs alarmes que cria só podem ser visualizados no seu dispositivo e, se um alarme incluir outros participantes, nos dispositivos desses participantes.\n \nAcedemos ao livro de endereços/lista de contactos do seu telefone apenas para preencher o separador de contactos no Galarm. Não armazenamos essa lista para qualquer outra finalidade. Honramos o facto de ser o seu livro de endereços!\n \nAcedemos à sua câmara e às suas fotografias apenas para lhe permitir definir uma boa imagem de perfil. Esta imagem aparece como o seu avatar ou como imagem de perfil de um grupo que edita. Outros utilizadores do Galarm que sejam membros dos alarmes que definiu poderão ver esta imagem.\n\nEnviamos o IP público do seu dispositivo e o seu ID único com todos os pedidos, por exemplo, ao pedir e verificar o código durante o processo de registo. Estas informações não estão associadas à sua conta e são apenas utilizadas para determinar qualquer potencial atividade maliciosa.\n \nConsulte a política de privacidade completa para obter mais pormenores sobre as informações que recolhemos e a forma como as utilizamos.',
  termsOfServiceHeader:
    'Ao utilizar a aplicação Galarm, está a concordar com os nossos Termos de Serviço, que regem o seu acesso e utilização desta aplicação móvel e as funcionalidades e serviços que oferece. \n\nO lema do Galarm é simples - ajudá-lo a nunca perder nenhum evento!  Seja configurando Alarmes Pessoais para alertas de despertar, chegando a tempo para compromissos e definindo lembretes para atividades como tomar medicamentos em tempo hábil, ou criando Alarmes de Grupo para organizar atividades de grupo como saídas de cinema, eventos esportivos e festas, Galarm permite que você tenha um despertador social para lembrar um ao outro e rastrear o status um do outro e se comunicar.\n\nUtilize o Galarm apenas se concordar com estes termos. Segue-se uma hiperligação para os mesmos.',
  acknowledgedPersonalAlarm:
    'Marcou o alarme como "Concluído" {{durationString}} atrás.',
  skippedPersonalAlarm: 'Passaste o alarme há {{durationString}} tempo.',
  authenticatingWithServer: 'Ligar...',
  selectParticipantsForPersonalAlarm:
    'Seleccione um grupo ou um conjunto de pessoas que funcionem como cópias de segurança do seu alarme pessoal.',
  selectParticipantsForGroupAlarm:
    'Seleccione um grupo ou um conjunto de pessoas para a sua atividade de grupo.',
  acknowledgedGroupAlarm:
    '{{acknowledgedAsString}} este alarme {{durationString}} atrás.',
  acknowledgedExpiredGroupAlarm:
    'Este alarme expirou há {{durationString}}. Você {{acknowledgedAsString}} este alarme.',
  acknowledgedPersonalAlarmForParticipant:
    '{{creatorName}} marcou o alarme como "Concluído" {{durationString}} atrás.',
  acknowledgedExpiredPersonalAlarmForParticipant:
    'Este alarme expirou há {{durationString}}. {{creatorName}} marcou o alarme como "Done" (Concluído).',
  entityNotFound:
    'Oops! Não foi possível encontrar o site selecionado {{entityType}}. Poderemos ainda estar a recuperá-lo ou poderá ter sido removido.',
  goBack: 'Voltar atrás',
  uploadingImageToServer: 'Carregamento de imagem para o servidor.',
  deletingImageFromServer: 'Eliminar a imagem do servidor',
  errorUploadingImageToServer:
    'Erro ao carregar a imagem no servidor. Por favor, tente novamente mais tarde.',
  unableToRemoveGroup: 'Não é possível remover o grupo. {{error}}',
  addingGroup: 'Adicionar grupo.',
  editingGroup: 'Grupo de edição.',
  unableToAddGroup: 'Não é possível adicionar um grupo. {{error}}',
  unableToEditGroup: 'Não é possível editar o grupo. {{error}}',
  thanksForFeedback: 'Obrigado por nos ajudar a melhorar.',
  licensesHeader:
    'O Galarm inclui software licenciado por terceiros. Consulte a nossa página de licenciamento de terceiros para obter informações sobre atribuição e licenciamento correspondentes.',
  alarmAlreadyInSnooze: 'Ser-lhe-á recordado em {{durationString}}.',
  carrierChargesMayApply: 'Podem ser aplicadas taxas de transporte.',
  selectCascadingInterval:
    'O primeiro participante será alertado {{firstParticipantDurationString}} após a hora do alarme, o segundo participante será alertado {{secondParticipantDurationString}} após a hora do alarme e assim sucessivamente se não marcar o alarme como concluído.',
  reorderParticipantsScreenDescription:
    'Os participantes serão alertados pela ordem apresentada.',
  noMembers: 'Nenhum membro',
  selectedGroupDescription: {
    zero: '{{numActiveMembers}} participantes',
    one: '{{numActiveMembers}} participante ativo e {{numInactiveMembers}} participante inativo',
    other:
      '{{numActiveMembers}} participantes activos e {{numInactiveMembers}} participantes inactivos'
  },
  groupAlarmWillRingAtSameTime:
    'Todos os participantes serão alertados em {{timeString}}.',
  welcomeToGalarm: 'Bem-vindo ao Galarm',
  checkingConnection: 'Verificação da ligação',
  deletePastAlarmsTitle: 'Eliminar alarmes inactivos com mais de',
  deletePastAlarmsOption: {
    zero: '{{option}} (0 alarmes)',
    one: '{{option}} (1 alarme)',
    other: '{{option}} ({{count}} alarmes)'
  },
  chooseSnoozeInterval: 'Lembra-me novamente em',
  personalAlarmDescription:
    'Utilize um alarme pessoal para acordar, tomar medicação ou qualquer outra tarefa para si. Opcionalmente, pode adicionar participantes que são alertados se falhar um alarme, para que o possam lembrar. Os participantes também são notificados quando marca o alarme como concluído.',
  groupAlarmDescription:
    'Utilize um alarme de grupo para actividades de grupo, como passeios, festas ou qualquer outro evento. O alarme dispara ao mesmo tempo para todos os participantes e estes podem confirmar a sua participação.',
  recipientAlarmDescription:
    'Crie alarmes para outra pessoa ("amigo") para a lembrar de coisas que tem de fazer. O utilizador é alertado para lembrar o contacto, caso este não tenha ouvido o alarme. Também será notificado quando o contacto marcar o alarme como concluído.\n  \nTenha em atenção que os alarmes são criados no seu fuso horário.',
  whatsThis: 'O que é que é isto?',
  alarmCurrentlyDisabled: 'Este alarme está atualmente desativado',
  alarmCurrentlyRejected: 'Este alarme é atualmente rejeitado',
  currentlyTypingUsers: {
    one: '{{currentlyTypingUsers}} está a escrever...',
    other: '{{currentlyTypingUsers}} estão a escrever...'
  },
  noSearchResultsFound: 'Não foi encontrado nenhum {{type}} ',
  noGroupsCreated: 'Não criou nenhum grupo.',
  noGroupsWeb: {
    one: 'Não criou nenhum grupo.',
    other:
      'Registe-se na aplicação móvel para começar a criar grupos e a adicioná-los aos alarmes.'
  },
  internalErrorDuringRegistration:
    'Ocorreu um erro interno durante o registo do utilizador. Por favor, tente novamente após alguns minutos',
  selectRecipientAlarmInterval: {
    zero: 'Será alertado ao mesmo tempo que o destinatário',
    one: 'Será alertado {{durationString}} após a hora do alarme se o destinatário não marcar o alarme como "Concluído',
    other:
      'Será alertado {{durationString}} após a hora do alarme se o destinatário não marcar o alarme como "Concluído'
  },
  selectRecipient: 'Selecionar destinatário',
  acknowledgedExpiredRecipientAlarm:
    'Este alarme expirou há {{durationString}}. Marcou este alarme como "Concluído".',
  skippedExpiredRecipientAlarm:
    'Este alarme expirou há {{durationString}}. Ignorou este alarme.',
  expiredRecipientAlarm:
    'Este alarme expirou há {{durationString}}. Toque em "Concluído" para concluir o alarme e notificar {{creatorName}}.',
  acknowledgedRecipientAlarm:
    'Marcou este alarme como "Concluído" {{durationString}} atrás.',
  skippedRecipientAlarm: 'Passou este alarme há {{durationString}}.',
  acknowledgedExpiredRecipientAlarmForCreator:
    'Este alarme expirou há {{durationString}}. {{recipientName}} marcou o alarme como "Done" (Concluído).',
  skippedExpiredRecipientAlarmForCreator:
    'Este alarme expirou há {{durationString}}. {{recipientName}} ignorou o alarme.',
  expiredRecipientAlarmForCreator:
    'Este alarme expirou há {{durationString}}. Por favor, relembre {{recipientName}}.',
  acknowledgedRecipientAlarmForCreator:
    '{{recipientName}} marcou o alarme como "Concluído" {{durationString}} atrás.',
  selectRecipientForRecipientAlarm: 'Selecionar um destinatário para o alarme',
  selectAtLeastOneParticipantForGroupAlarm:
    'Adicionar pelo menos um participante ao alarme',
  addAtLeastOneMemberToGroup: 'Adicionar pelo menos um membro ao grupo',
  atTheSameTime: 'Ao mesmo tempo',
  myself: 'Eu próprio',
  group: 'Grupo',
  someoneElse: 'Outra pessoa',
  participantsAreNotified: 'Os participantes são notificados.',
  creatorIsNotified: '{{creatorName}} é notificado.',
  membersAddedToGroup: {
    one: '{{memberNames}} é adicionado ao grupo {{groupName}}.',
    other: '{{memberNames}} são adicionados ao grupo {{groupName}}.'
  },
  memberRemovedFromGroup:
    '{{memberName}} foi removido do grupo {{groupName}}, o que pode ter afetado alguns dos seus alarmes.',
  youRemovedFromGroup:
    'Foi removido do grupo {{groupName}}, o que pode ter afetado alguns dos seus alarmes.',
  memberLeftGroup:
    '{{memberName}} deixou o grupo {{groupName}}, o que pode ter afetado alguns dos seus alarmes.',
  youLeftGroup:
    'Deixou o grupo {{groupName}}, o que pode ter afetado alguns dos seus alarmes.',
  groupLeft: 'Deixou o grupo {{groupName}}.',
  memberMovedToADifferentTimezone:
    '{{memberName}} mudou-se para um fuso horário diferente, o que pode ter afetado alguns dos seus alarmes.',
  memberDstChanged: {
    zero: '{{memberName}} deixou de observar o horário de verão. Os alarmes relevantes foram actualizados.',
    one: '{{memberName}} começou a observar o horário de verão. Os alarmes relevantes foram actualizados.'
  },
  impactedAlarms: 'Alarmes afectados:',
  alwaysRingParticipantAlarmsOn:
    'Os alarmes de outras pessoas tocarão a menos que os recuse.',
  alwaysRingParticipantAlarmsOff:
    'Os alarmes de outros não tocarão a menos que os aceite.',
  agreeToTermsOfServiceAndPrivacyPolicy:
    'Ao continuar, o utilizador aceita os Termos de Serviço e a Política de Privacidade do Galarm.',
  deleteAccountWarningHeader:
    'Aviso: Esta ação é irreversível. A eliminação da sua conta irá:',
  deleteAccountDeleteAlarms: '- Eliminar todos os alarmes que criou',
  deleteAccountDeleteRecipientAlarms:
    '- Eliminar todos os alarmes de amigos em que é o destinatário',
  deleteAccountRemoveFromGroups: '- Remover-te de todos os grupos',
  deleteAccountWarningFooter: {
    zero: 'Se estiver a mudar de telemóvel, basta instalar a aplicação no seu novo telemóvel e registar-se utilizando o mesmo número. Pode remover a aplicação neste telemóvel.\n\nDepois de apagar a sua conta, não a poderá restaurar.',
    one: 'Se estiver a mudar de telemóvel, basta instalar a aplicação no seu novo telemóvel e registar-se utilizando o mesmo número. Pode remover a aplicação neste telemóvel.\n\nDepois de eliminar a sua conta, não a poderá restaurar. Pode gerir a sua subscrição Galarm ativa na Play Store.',
    other:
      'Se estiver a mudar de telemóvel, basta instalar a aplicação no seu novo telemóvel e eliminar a aplicação deste telemóvel.\n\nDepois de eliminar a sua conta, não a poderá restaurar. Pode gerir a sua subscrição Galarm ativa na App Store.'
  },
  typeConfirmationStringToDeleteAccount:
    'Digite {{confirmationString}} para eliminar a sua conta',
  unableToDeleteAccount: 'Não é possível eliminar a conta. {{error}}',
  impactedGroups: 'Grupos afectados:',
  memberDeletedAccount:
    '{{memberName}} eliminou a sua conta, o que pode ter afetado alguns dos seus alarmes e grupos.',
  confirmCountryCodeAndEnterNumberForExistingUser:
    'Confirme o código do seu país e introduza o número de telefone que utilizou no registo anterior.',
  requiredPermissionsDisabledWarningIos:
    'As notificações estão desactivadas. Os alarmes não tocam. Toque em para corrigir.',
  requiredPermissionsDisabledWarningAndroid:
    'O Galarm não tem as permissões necessárias para tocar alarmes. Toque para corrigir.',
  notificationChannelPopupDisabledWarningAndroid:
    'As notificações de alarme não aparecem no ecrã. Toque em para corrigir.',
  alarmLockScreenNotificationDisabledWarningAndroid:
    'As notificações de alarme não aparecem no ecrã de bloqueio. Toque em para corrigir.',
  why: 'Porquê',
  how: 'Como',
  registrationRequired: 'Registo obrigatório',
  phoneNumberRequired: 'Número de telefone necessário',
  fewSeconds: 'alguns segundos',
  lessThanMinute: 'menos de um minuto',
  updateUnsupportedVersion:
    'É necessária uma versão actualizada da aplicação para continuar a utilizar o Galarm.',
  update: 'Atualização',
  back: 'Voltar',
  unableToMarkAlarmDone:
    'Não é possível marcar o alarme como concluído neste momento. Tente novamente dentro de alguns instantes.',
  unableToMarkAlarmUndone:
    'Não é possível marcar o alarme como desfeito neste momento. Tente novamente dentro de alguns instantes.',
  unableToMarkAlarmUnskipped:
    'Não é possível marcar o alarme como não ignorado neste momento. Tente novamente dentro de alguns instantes.',
  unableToSkipAlarm:
    'Não é possível ignorar o alarme neste momento. Tente novamente dentro de alguns instantes.',
  unableToSetGroupAlarmResponse:
    'Não é possível {{response}} o alarme de grupo neste momento. Por favor, tente novamente dentro de alguns instantes.',
  unableToDeleteAlarm:
    'Não é possível eliminar o alarme neste momento. Tente novamente dentro de alguns instantes.',
  unableToSetAlarmResponse:
    'Não é possível definir a sua resposta neste momento. Por favor, tente novamente dentro de alguns instantes.',
  unableToSnoozeAlarm: 'Não é possível adiar o alarme.',
  unableToStopSnoozeForAlarm: 'Não é possível parar a soneca do alarme.',
  unableToUpdateParticipantAlarmRingerSettings:
    'Não é possível atualizar as definições da campainha neste momento. Tente novamente dentro de alguns instantes.',
  updateParticipantPreReminderDuration:
    'De momento, não é possível atualizar a duração do pré-aviso. Tente novamente dentro de alguns instantes.',
  unableToUpdateAlarmRingerSettings:
    'Não é possível atualizar as definições da campainha neste momento. Tente novamente dentro de alguns instantes.',
  unableToUpdateAlarmPreReminderDuration:
    'Não é possível atualizar a duração do pré-lembrança neste momento. Tente novamente dentro de alguns instantes.',
  retrievingAlarmsFromServer: 'Recuperação de alarmes do servidor...',
  startApp: 'Iniciar >',
  phoneNumberRequiredGroupListHeaderText:
    'O grupo é uma coleção de contactos Galarm que lhe permite criar rapidamente alarmes com eles.\n\nRegiste-se utilizando o seu número de telefone para encontrar os seus contactos que utilizam o Galarm. Pode adicioná-los aos grupos.',
  phoneNumberRequiredBuddyAlarmHeaderText:
    'O Buddy alarm pode ser utilizado para definir alarmes para amigos e familiares, para os lembrar de coisas que têm de fazer.\n\nPara utilizar esta e muitas outras funcionalidades interessantes da aplicação, registe-se utilizando o seu número de telefone.',
  phoneNumberRequiredContactListHeaderText:
    'Os contactos são pessoas que podem ser adicionadas como participantes em alarmes.\n\nRegiste-se utilizando o seu número de telefone para ver os seus contactos que utilizam o Galarm. Pode adicioná-los como participantes nos seus alarmes.',
  phoneNumberRequiredGroupAlarmHeaderText:
    'O alarme de grupo pode ser utilizado para definir um lembrete para um grupo de pessoas.\n\nPara utilizar esta e muitas outras funcionalidades interessantes da aplicação, registe-se utilizando o seu número de telefone.',
  phoneNumberRequiredParticipantsHeaderText:
    'Os participantes actuam como cópias de segurança para este alarme. São recordados se falhar o alarme.\n\nPara utilizar esta e muitas outras funcionalidades interessantes da aplicação, registe-se utilizando o seu número de telefone.',
  youHaveNotRegistered:
    'Atualmente, não existe cópia de segurança dos seus alarmes.\n\nRegiste-se utilizando o seu número de telefone. Depois de se registar, os seus alarmes são guardados na nuvem. Isto permite-lhe restaurar os seus alarmes e outras definições quando muda de telemóvel.\n',
  registerNow: 'Registar agora',
  hoursAndMinutes: 'Horas e minutos',
  days: 'Dias',
  weeks: 'Semanas',
  months: 'Meses',
  years: 'Anos',
  daysOfWeek: 'Semanalmente (dias seleccionados)',
  monthly: 'Mensal',
  yearly: 'Anual',
  everyDay: 'Diário',
  selectDaysOfWeek: 'Selecionar dias da semana',
  alarmRingMessageForSpecificDates:
    'Este alarme só tocará em meses que tenham pelo menos {{dayOfMonth}} dias. Deseja continuar?',
  alarmRingMessageForSpecificYear:
    'Este alarme só tocará em anos bissextos. Deseja continuar?',
  alarmRingMessageForSpecificDayOfWeekInMonth:
    'Este alarme só tocará em meses que tenham {{weekNumberOfDayInMonth}} {{dayName}} s. Pretende continuar? ',
  alarmRepetitionAdjustedBasedOnNewDate:
    'A repetição do alarme foi actualizada com base na nova data.',
  inviteFriendsToInstallGalarm:
    'Os contactos são pessoas que podem ser adicionadas como participantes nos seus alarmes. É possível convidar contactos:\n\n1. A partir do seu livro de endereços\n\n2. Fornecendo manualmente o seu nome e número.\n\nToque em Atualizar no canto superior esquerdo para recarregar os contactos Galarm após o registo.',
  whyCreateNewGroup:
    'Os grupos são colecções de contactos que podem ser adicionados como participantes nos seus alarmes.\n  \nNão criou nenhum grupo.',
  noNotifications: 'Não tem novas notificações.',
  createGroup: 'Criar grupo',
  galarmRequiresAccessToContactsPermission:
    'O Galarm solicita autorização para aceder aos seus contactos. Esta informação é enviada aos seus servidores para identificar os seus contactos que utilizam o Galarm. Estes utilizadores podem ser adicionados como participantes nos seus alarmes.',
  galarmRequiresAccessToAudioFilesPermission:
    'Alguns alarmes estão a utilizar a música do telemóvel como toque. Forneça permissão para aceder a ficheiros de áudio no seu dispositivo para tocar esses alarmes com o toque escolhido.',
  galarmRequiresAccessToContactsSelectScreen:
    'Galarm não tem permissão para aceder aos contactos. Autorize o acesso aos contactos se pretender utilizar esta funcionalidade.',
  allow: 'Permitir',
  deny: 'Recusar',
  galarmRequiresAccessToContacts:
    'Os contactos são pessoas que podem ser adicionadas como participantes nos seus alarmes. Pode adicionar contactos através de:\n\n1. Importando automaticamente dos contactos do telefone. Toque em "Permitir acesso" e active "Contactos".\n\n2. Adicionar manualmente o nome e o número do contacto. Toque em "Adicionar contacto" para começar.',
  galarmRequiresAccessToAlarmAndRemindersPermission:
    'O Galarm necessita da permissão "Alarmes e lembretes" para poder tocar os alarmes exatamente nas horas especificadas. Active-a no ecrã seguinte.',
  accessToContactsPermissionRequiredAndroid:
    'O Galarm não tem permissão para aceder aos contactos do seu telemóvel.\n\nQuando permite o acesso, as pessoas nos contactos do telefone que estão a utilizar o Galarm aparecem como contactos do Galarm. Essas pessoas podem ser adicionadas como participantes no alarme. Toque em "Permitir acesso", seleccione "Permissão" e active "Contactos".\n\nPode ainda adicionar manualmente contactos ao Galarm.',
  accessToContactsPermissionRequiredIos:
    'O Galarm não tem permissão para aceder aos contactos do seu telemóvel.\n\nQuando permite o acesso, as pessoas nos contactos do telefone que estão a utilizar o Galarm aparecem como contactos do Galarm. Essas pessoas podem ser adicionadas como participantes no alarme. Toque em "Permitir acesso" e active "Contactos".\n\nPode ainda adicionar manualmente contactos ao Galarm.',
  giveAccess: 'Permitir o acesso',
  enterValidMobileNumber:
    'Introduza um número de telefone válido para se registar.',
  proceedAnyway: 'Continuar na mesma',
  checkInvalidPhoneNumber:
    'Pensamos que introduziu um número inválido como {{mobileNumber}}.',
  checkInvalidCountryCode:
    'Código de país inválido especificado como {{countryCode}}. Se estiver presente mais do que um código de país, editar o campo para manter o código de país aplicável.',
  editNumber: 'Editar número',
  editCountryCode: 'Editar código do país',
  sendingCodeOnCall: 'Chamando-o com o código.',
  sendCodeOnCall: 'Ligue-me com o código',
  registrationLandingScreenRegisterText:
    'Toque em Continuar para se registar utilizando o seu número de telefone.\n\nAo registar-se:\n1) Poderá adicionar participantes aos seus alarmes e conversar com eles.\n2) Será feita uma cópia de segurança dos seus alarmes na nuvem, de modo a que, quando mudar de telemóvel, os seus alarmes apareçam no novo telemóvel.',
  continue: 'Continuar',
  registerLater: 'Saltar registo',
  cancelRegistration: 'Cancelar registo',
  welcome: 'Bem-vindo',
  haventReceivedCodeYet: {
    one: 'Ainda não recebeu o código? Pode pedir o código novamente em 1 segundo.',
    other:
      'Ainda não recebeu o código? Pode voltar a pedir o código em {{count}} segundos.'
  },
  storagePermissionDenied:
    'Não é possível aceder aos ficheiros de música porque a permissão de armazenamento foi negada.',
  havingTroubleRegisteringMessage:
    'O Galarm não consegue verificar o código após várias tentativas.\n\nPretende saltar o registo e começar a utilizar a aplicação?',
  autoDetectVerificationCode:
    'Para simplificar o registo, o Galarm pode detetar automaticamente o código de verificação se permitir que o Galarm visualize mensagens SMS.',
  notNow: 'Agora não',
  verifyNumberText:
    'Enviaremos um SMS com o código de verificação para:\n\n{{formattedMobileNumber}}',
  addToContacts: 'Adicionar aos contactos',
  todayString: 'Hoje',
  tomorrowString: 'Amanhã',
  backups: 'Cópias de segurança',
  acintyoTrademark:
    '© 2025 Acintyo, Inc. Todos os direitos reservados.\nGalarm é uma marca comercial registada da Acintyo, Inc.\nProtegida pelas patentes dos EUA 10382613, 10867286.\nOutras patentes pendentes.',
  acintyoTrademarkWeb:
    '© 2025 Acintyo, Inc. Todos os direitos reservados. Galarm é uma marca comercial registada da Acintyo, Inc.\nProtegida pelas patentes dos EUA 10382613, 10867286. Outras patentes pendentes.',
  acintyoTrademarkEnterprise:
    '© 2025 Acintyo, Inc.\nTodos os direitos reservados.',
  markAlarmDone: 'Marcar o alarme como concluído',
  skipAlarm: 'Ignorar alarme',
  confirmGroupAlarm: 'Confirmar a participação',
  declineGroupAlarm: 'Diminuição da participação',
  snoozeAlarm: 'Alarme de soneca',
  deleteAlarmQuickAction: 'Eliminar o alarme',
  disableAlarm: 'Desativar o alarme',
  confirmDisableAlarm: 'Desativar o alarme?',
  saveToCalendar: 'Exportar para o calendário',
  stillRingGroupAlarmAfterConfirmingInAdvance:
    'Está a confirmar antecipadamente o alarme de grupo.  Continua a querer o lembrete à hora do alarme?',
  updateNow: 'Atualizar agora',
  nParticipants: {
    one: '1 outro participante',
    other: '{{count}} outros participantes'
  },
  details: 'Detalhes',
  changeParanthesis: '(mudar)',
  loadMoreHistory: 'Ver mais',
  alarmSummaryForHistory:
    'Respostas anteriores para {{alarmName}}.\nRepetições {{alarmRepetitionString}}\n{{previousOccurrencesString}}',
  alarmSummaryForHistoryWeb: 'Respostas anteriores para {{alarmName}}',
  alarmSummaryForHistoryRepetitionWeb: 'Repetições {{alarmRepetitionString}}',
  dontSeeCompleteHistory: 'Não vê o historial completo?',
  dontSeeCompleteHistoryExplanation:
    'O histórico de alarmes é registado a partir do momento em que o alarme é criado. Mantemos apenas um histórico limitado para cada alarme, dependendo da repetição do alarme. Por exemplo, se o alarme se repetir todos os dias, mantemos um histórico de 30 dias.\n\nAlterar a hora ou a repetição do alarme repõe o histórico de alarmes.',
  noActionAvailableForAlarmOccurrence:
    'Não existem acções disponíveis para esta ocorrência.',
  enterEmailIdForFeedback: 'Utilizado para responder e partilhar actualizações',
  noPreviousOccurrences: 'Ainda não há histórico de alarmes',
  nMissedOccurrencesOfAlarmInLastNDays: {
    zero: 'Ainda não há histórico de alarmes',
    one: '{{missedAlarmOccurrences}} perdeu o último lugar {{count}}',
    other: '{{missedAlarmOccurrences}} perdeu o último lugar {{count}}'
  },
  soundAndVibrationSummaryString: {
    zero: '{{volume}}% de volume, {{ringtoneName}}',
    one: '{{volume}}% volume, {{ringtoneName}}, Título do anúncio'
  },
  soundAndVibrationSummaryStringIos: {
    zero: '{{ringtoneName}}',
    one: 'Crítico, {{volume}}% volume, {{ringtoneName}}'
  },
  previousOccurrences: 'Histórico de alarmes',
  retrieving: 'Recuperar...',
  startTimeOfDay: 'Começa em',
  endTimeOfDay: 'Termina em',
  ringEveryHours: 'Anéis todos',
  everyNHours: 'Por hora',
  everyMHoursNMinutes: 'Horas e minutos',
  selectHourlyRepetition: 'Selecionar a repetição horária',
  everyNHoursRepetitionString: {
    one: 'De hora a hora, de {{startTimeString}} a {{endTimeString}} todos os {{daysString}}',
    other:
      'Cada {{count}} horas de {{startTimeString}} a {{endTimeString}} cada {{daysString}}'
  },
  everyNHoursAndMinutesRepetitionString:
    'Cada {{hoursAndMinutes}} de {{startTimeString}} a {{endTimeString}} cada {{daysString}}',
  everyMHoursAndNMinutesRepetitionString: 'Todos {{repeatString}}',
  everyNHoursRepetitionStringForCalendar: {
    one: 'A cada hora, de {{startTimeString}} a {{endTimeString}}',
    other: 'A cada {{count}} horas, de {{startTimeString}} a {{endTimeString}}'
  },
  everyNHoursAndMinutesRepetitionStringForCalendar:
    'Todos os {{hoursAndMinutes}} de {{startTimeString}} a {{endTimeString}}',
  everyNHoursAndMinutesRepetitionStringForAlarmSummary:
    'Todos os {{hoursAndMinutes}} até {{endTimeString}}',
  everyNHoursRepetitionStringForAlarmSummary: {
    one: 'Todas as horas até {{endTimeString}}',
    other: 'A cada {{count}} horas até {{endTimeString}}'
  },
  daysRepetitionOddString: {
    other: 'Todas as datas ímpares',
    one: 'Datas ímpares ({{daysString}})'
  },
  daysRepetitionEvenString: {
    other: 'Todas as datas pares',
    one: 'Datas pares ({{daysString}})'
  },
  oddNumberedRepetitionPreviewString: {
    one: 'O alarme tocará nas datas ímpares que caem em {{daysString}} à hora especificada.',
    other: 'O alarme tocará em todas as datas ímpares à hora especificada.'
  },
  evenNumberedRepetitionPreviewString: {
    one: 'O alarme tocará nas datas pares que caem em {{daysString}} à hora especificada.',
    other: 'O alarme tocará em todas as datas pares à hora especificada.'
  },
  hourlyRepetitionPreviewString: {
    one: 'Este alarme tocará uma vez a cada {{daysString}} em:',
    other: 'Este alarme tocará {{count}} vezes a cada {{daysString}} em:'
  },
  daysRepetitionPreviewString: {
    one: 'Este alarme tocará todos os dias.',
    other: 'Este alarme tocará a cada {{count}} dias.'
  },
  weeksRepetitionPreviewString: {
    one: 'Este alarme tocará todas as semanas.',
    other: 'Este alarme tocará a cada {{count}} semanas.'
  },
  everyNDays: { one: 'Todos os dias', other: 'A cada {{count}} dias' },
  everyNWeeks: { one: 'Todas as semanas', other: 'A cada {{count}} semanas' },
  everyNMonths: { one: 'Todos os meses', other: 'A cada {{count}} meses' },
  everyNYears: { one: 'Todos os anos', other: 'A cada {{count}} anos' },
  cantSetEndTime: 'Não é possível definir a hora de fim',
  endTimeShouldBeGreater: 'A hora de fim deve ser superior à hora de início.',
  cantSetStartTime: 'Não é possível definir a hora de início',
  startTimeShouldBeLess: 'A hora de início deve ser inferior à hora de fim.',
  everyDaySmall: 'dia',
  everyGivenDay: '{{day}}',
  everyGivenDays: '{{days}}',
  adjustingAlarmsAfterTimezoneChange:
    'Detectámos que mudou para um fuso horário diferente. Estamos a ajustar os seus alarmes.',
  ringOnDays: 'Anéis em',
  yourAlarmsMayNotRing: 'Os seus alarmes podem não tocar',
  yourAlarmsMayNotRingTapToFix:
    'Os seus alarmes podem não tocar. Toque em para corrigir.',
  xiaomiSetupInstructions:
    'Siga estas instruções para que os seus alarmes toquem sempre:\n\n - Toque em "Ativar arranque automático" e permita "Galarm" para que os seus alarmes toquem sempre. \n\nPara saber mais, visite www.galarmapp.com/setup-mi',
  oppoSetupInstructions:
    'Siga estas instruções para que os seus alarmes toquem sempre:\n\n - Toque em "Ativar arranque automático" e active "Permitir arranque automático".\n\n - Toque no botão "Aplicações recentes" no seu telemóvel, deslize o ícone da aplicação Galarm para baixo e toque em "Bloquear".  \n\nPara saber mais, visite www.galarmapp.com/setup-oppo',
  oppo8AndLowerSetupInstructions:
    'Siga estas instruções para que os seus alarmes toquem sempre:\n\n - Toque em "Ativar arranque automático" e active "Permitir arranque automático".\n\n - Toque no botão "Aplicações recentes" no seu telemóvel, deslize o ícone da aplicação Galarm para baixo e toque em "Bloquear".\n\nPara saber mais, visite www.galarmapp.com/setup-oppo-8-and-lower',
  vivo9And10SetupInstructions:
    'Siga estas instruções para que os seus alarmes toquem sempre:\n\n - Toque em "Ativar arranque automático" e permita "Galarm".\n\n - Toque em "Utilização da bateria", seleccione "Consumo elevado de energia em segundo plano" e permita o "Galarm".\n\nPara saber mais, visite www.galarmapp.com/setup-vivo',
  vivo11SetupInstructions:
    'Siga estas instruções para que os seus alarmes toquem sempre:\n\n- Toque em "Ativar arranque automático" e permita "Galarm".\n\n- Toque em "Utilização da bateria", seleccione "Consumo elevado de energia em segundo plano" e permita o "Galarm".\n\nPara saber mais, visite www.galarmapp.com/setup-vivo-11',
  vivoSetupInstructions:
    'Siga estas instruções para que os seus alarmes toquem sempre:\n\n- Abra "Definições do telefone > Aplicações > Acesso a aplicações especiais > Arranque automático" e active o arranque automático para o Galarm.\n\n- Abra "Definições do telemóvel > Bateria > Gestão do consumo de energia em segundo plano > Galarm" e escolha "Não restringir a utilização de energia em segundo plano".\n\nPara saber mais, visite www.galarmapp.com/setup-vivo-12-and-above',
  vivo8AndLowerSetupInstructions:
    'Siga estas instruções para que os seus alarmes toquem sempre:\n\n - Toque em "Ativar arranque automático" e permita "Galarm".\n\n - Toque em "Utilização da bateria", seleccione "Consumo elevado de energia em segundo plano" e permita o "Galarm".\n\nPara saber mais, visite www.galarmapp.com/setup-vivo-8-and-lower',
  huawei7AndLowerAdditionalSetupInstructions:
    'Siga estas instruções para que os seus alarmes toquem sempre:\n\n - Toque em "Aplicações protegidas" e permita "Galarm".\n\nPara saber mais, visite www.galarmapp.com/setup-huawei-7-and-lower',
  huaweiAdditionalSetupInstructions:
    'Siga estas instruções para que os seus alarmes toquem sempre:\n\n - Toque em "Gerir lançamento de aplicações" e desactive "Galarm" para "Gerir manualmente". Na mensagem que se segue, certifique-se de que "Lançamento automático" e "Executar em segundo plano" estão activados.\n\nPara saber mais, visite www.galarmapp.com/setup-huawei',
  meizuSetupInstructions:
    'Siga as instruções na ligação abaixo para que os seus alarmes toquem sempre: https://dontkillmyapp.com/meizu',
  asusSetupInstructions:
    'Siga estas instruções para que os seus alarmes toquem sempre:\n\n - Toque em "Ativar o arranque automático" e clique em "Gestor de arranque automático". Ativar o "Galarm" no "Gestor de arranque automático".\n\nPara saber mais, visite www.galarmapp.com/setup-asus',
  nokia8AndLowerAdditionalSetupInstructions:
    'Siga estas instruções para que os seus alarmes toquem sempre:\n\n - Toque em "Ativar arranque automático" e permita "Galarm".\n\nPara saber mais, visite www.galarmapp.com/setup-nokia-8-and-lower',
  nokiaAdditionalSetupInstructions:
    'Siga estas instruções para que os seus alarmes toquem sempre:\n\n - Toque em "Aplicações restritas" e desactive o "Galarm".\n\nPara saber mais, visite www.galarmapp.com/setup-nokia',
  lenovoSetupInstructions:
    'Siga estas instruções para que os seus alarmes toquem sempre:\n\n - Toque no botão "Aplicações recentes" no seu telemóvel, localize a aplicação Galarm e toque no ícone "Bloquear" no canto superior direito.\n  \nPara saber mais, visite https://dontkillmyapp.com/lenovo',
  samsungAdditionalSetupInstructions:
    'Siga estas instruções para que os seus alarmes toquem sempre:\n\n - Toque em "Aplicações não monitorizadas" e permita "Galarm".\n\nPara saber mais, visite www.galarmapp.com/setup-samsung',
  tecnoSetupInstructions:
    'Siga as instruções na ligação abaixo para que os seus alarmes toquem sempre: https://www.galarmapp.com/setup-tecno',
  infinixSetupInstructions:
    'Siga as instruções na ligação abaixo para que os seus alarmes toquem sempre: https://www.galarmapp.com/setup-infinix',
  samsungAdditionalSetupInstructionsAndroid11AndAbove:
    'Por predefinição, a Samsung mostra notificações "Breves" que desaparecem rapidamente. Siga estas instruções para ver as notificações de alarme "Detalhadas".\n\n - Abra as definições do telemóvel e escolha "Notificações"\n - Escolha o estilo de notificações "Detalhadas\n  \nPara saber mais, visite www.galarmapp.com/setup-samsung-android11-and-above',
  iosSetupInstructions:
    'Por predefinição, o estilo de faixa do iOS para notificações está definido como "Temporário", o que faz com que as notificações desapareçam rapidamente. Siga estas instruções para definir o estilo da faixa para "Persistente".\n\n- Abra as definições do telemóvel e desloque-se para Galarm\n- Escolha Notificações e defina o estilo da faixa como Persistente\n\nPara saber mais, visite www.galarmapp.com/setup-ios',
  iosSetupInstructions15AndBelow:
    'Por predefinição, o estilo de faixa do iOS para notificações está definido como "Temporário", o que faz com que as notificações desapareçam rapidamente. Siga estas instruções para definir o estilo da faixa para "Persistente".\n\n- Abra as definições do telemóvel e desloque-se para Galarm\n- Escolha Notificações e defina o estilo da faixa como Persistente\n\nPara saber mais, visite www.galarmapp.com/setup-ios-15-below',
  setNotificationsStyle: 'Definir o estilo das notificações',
  setNotificationsStyleToDetailed:
    'Definir o estilo das notificações como "Detalhado"',
  setBannerStyle: 'Definir estilo do banner',
  setBannerStyleToPersistent: 'Definir o estilo do banner como "Persistente"',
  selectAtleastOneDay: 'Selecionar os dias em que este alarme deve tocar.',
  selectAtLeastOneHourOrMinute:
    'As horas ou os minutos devem ser um valor diferente de zero',
  hourlyAlarmDefaultStartTimeSetAlert:
    'A hora de início do alarme foi definida para as 8 horas.',
  batteryUsage: 'Utilização da bateria',
  dontShowAgain: 'Não voltar a aparecer',
  alarmWillRingEveryHourTillSeen:
    'O alarme é adiado de hora a hora até que sejam tomadas medidas.',
  unknown: 'Desconhecido',
  alarmMarkedDone: '{{name}} marcou o alarme como "Concluído".',
  alarmMarkedSkipped: '{{name}} não deu o alarme.',
  alarmIsMarkedDone: '{{alarmName}} é marcado como concluído.',
  alarmIsSkipped: '{{alarmName}} é ignorado.',
  alarmIsConfirmed: '{{alarmName}} é confirmada.',
  alarmIsDeclined: '{{alarmName}} é recusado.',
  alarmIsSnoozed: '{{alarmName}} é adiada.',
  alarmIsAccepted: '{{alarmName}} é aceite.',
  alarmIsRejected: '{{alarmName}} é rejeitada.',
  newContactInfo:
    'Indique o nome e o número de telefone do contacto.\n\nApenas os utilizadores registados podem ser adicionados como contactos. Se o utilizador não for um utilizador registado do Galarm, pode convidá-lo através de "Definições > Partilhar esta aplicação". Pode adicioná-lo como contacto depois de se registar utilizando o seu número de telefone. ',
  cantEditContactMobileNumber:
    'Não é possível editar o número de telefone. Elimine o contacto e adicione-o novamente se pretender alterar o número.',
  phoneContactsNotSyncedPermissionDenied:
    'O Galarm não tem permissão para aceder aos contactos do telefone. Os contactos do telefone não serão sincronizados.',
  inviteContactMessage:
    'Olá {{contactName}}, quero incluí-lo nos meus alarmes e lembretes utilizando o Galarm. Descarregue a aplicação a partir de {{downloadLink}}. Registe-se utilizando este número e comece a partilhar alarmes.',
  noRepeat: 'Não se repete',
  deepLinkNoLongerValidTitle: 'Ops!',
  deepLinkNoLongerValidMessage:
    'O alarme não foi criado. Pode tratar-se de uma ligação antiga ou de uma ligação inválida.',
  inviteContact: 'Convidar contacto',
  fromPhoneBook: 'Do livro de endereços',
  addFromPhoneBook: 'Convidar a partir do livro de endereços',
  manually: 'Manualmente',
  addManualContact: 'Convidar manualmente',
  noOtherContactsFound:
    'Todos os contactos do seu livro de endereços estão a utilizar o Galarm.',
  cantAddPhoneContactPermissionDenied:
    'O Galarm não tem permissão para aceder aos contactos do telefone. Permissão para aceder aos contactos.',
  alarmWillRingWithRandomRingtone:
    'Será escolhido um toque aleatório para o alarme.',
  alarmWillRingWithRandomRingtoneGlobal:
    'Será escolhido um toque aleatório para os alarmes.',
  createdAlarmFromDeepLinkTitle: 'Está tudo pronto!',
  createdAlarmFromDeepLinkMessage:
    'Será recordado de "{{alarmName}}" em {{alarmTime}} em {{alarmDate}}',
  createdRepeatingAlarmFromDeepLinkMessage: {
    zero: 'Lembrar-se-á de "{{alarmName}}" {{repetitionString}}',
    one: 'Será recordado de "{{alarmName}}" {{repetitionString}} em {{alarmTime}} a partir de {{alarmDate}}'
  },
  deleteCategoryAlertTitle: 'Eliminar categoria?',
  deleteCategoryAlertMessage: {
    zero: '',
    one: '1 alarme pertence a esta categoria. A eliminação desta categoria irá repor a categoria nesse alarme.',
    other:
      '{{count}} Os alarmes pertencem a esta categoria. A eliminação desta categoria irá repor a categoria nesses alarmes.'
  },
  updateReadyToInstallAlertTitle: 'Instalar a atualização?',
  updateReadyToInstallAlertMessage:
    'As actualizações estão prontas a instalar. Deseja instalar a atualização agora? ',
  hideCategoryAlertTitle: 'Ocultar categoria?',
  hideCategoryAlertMessage: {
    zero: 'Não poderá utilizar esta categoria no futuro.',
    one: '1 alarme pertence a esta categoria. Se ocultar esta categoria, a categoria será reposta nesse alarme.\n  \nNão será possível utilizar esta categoria no futuro.',
    other:
      '{{count}} Os alarmes pertencem a esta categoria. A ocultação desta categoria irá repor a categoria nesses alarmes.\n  \nNão será possível utilizar esta categoria no futuro.'
  },
  alarmCategoryDeleteAllAlarmsPrompt: {
    one: '1 alarme pertence a esta categoria. Pretende apagá-lo?\n\nNote que os alarmes dos participantes não são eliminados.',
    other:
      '{{count}} Os alarmes pertencem a esta categoria. Pretende eliminá-los?\n\nNote que os alarmes dos participantes não são eliminados.'
  },
  enterNameForGroup: 'Introduzir um nome para o grupo',
  enterMobileNumber: 'Introduza o seu número de telefone',
  enterNotesForAlarm: 'Introduzir notas para o alarme',
  enterUserName: 'Introduza o seu nome',
  enterEmailId: 'Opcional',
  enterFeedback: 'Como podemos melhorar?',
  describeProblem: 'Descreva o seu problema',
  invited: 'Convidado',
  resend: 'Reenviar',
  addContact: 'Adicionar contacto',
  inviteAgain: 'Convidar novamente',
  contactNotRegistered:
    '{{contactName}} ainda não instalou o Galarm. Poderá adicioná-los aos alarmes assim que forem instalados e registados com o seu número de telefone.',
  registration: 'Registo',
  verify: 'Verificação',
  profile: 'Perfil',
  profileImage: 'Imagem de perfil',
  addProfileImage: 'Adicionar imagem de perfil',
  groupDetails: 'Detalhes do grupo',
  newGroup: 'Novo grupo',
  uploadImage: 'Alterar imagem',
  removeImage: 'Remover imagem',
  selectParticipants: 'Selecionar participantes',
  selectMembers: 'Selecionar membros',
  selectTimezone: 'Selecionar o fuso horário',
  searchForATimezone: 'Procurar um fuso horário',
  timezone: 'Fuso horário',
  deviceTimezone: 'Fuso horário do dispositivo',
  specifyTimezoneForAlarm: 'Especificar o fuso horário para os alarmes',
  editName: 'Editar nome',
  groupImage: 'Imagem de grupo',
  addGroupImage: 'Adicionar imagem de grupo',
  selectAlarmType: 'Criar um alarme para',
  contactDetails: 'Dados de contacto',
  commonAlarms: 'Alarmes',
  commonGroups: 'Grupos',
  groupAlarmsTitle: 'Alarmes',
  selectRingtone: 'Selecionar toque de chamada',
  selectMusic: 'Selecionar música',
  select: 'Selecionar',
  personalAlarm: 'Alarme pessoal',
  groupAlarm: 'Alarme de grupo',
  newPersonalAlarm: 'Novo alarme pessoal',
  newGroupAlarm: 'Novo alarme de grupo',
  editPersonalAlarm: 'Editar alarme pessoal',
  editGroupAlarm: 'Editar alarme de grupo',
  rearrangeParticipants: 'Reorganizar os participantes',
  alarmConversation: 'Chat de alarme',
  timezoneMismatchDetected:
    'Algo nos diz que se encontra num fuso horário diferente do da última vez que utilizou o Galarm.\n\nReinicie o Galarm para que possamos adaptar-nos ao novo fuso horário.',
  deviceRestartRequired: 'Necessário reiniciar a aplicação',
  disabledAlarm: 'Alarme desativado {{name}}',
  newContact: 'Novo contacto',
  contactName: 'Nome do contacto',
  contactMobileNumber: 'Número de telefone de contacto',
  enterNameForContact: 'Introduzir o nome do contacto',
  pickCountryForContact: 'País do contacto',
  enterMobileNumberForContact: 'Introduzir o número de telefone do contacto',
  specifyNameForContact: 'O nome do contacto é obrigatório',
  specifyValidPhoneNumberForContact:
    'É necessário um número de telefone válido',
  addingContact: 'Adicionar contacto',
  deletingContact: 'Remover o contacto',
  editingContact: 'Contacto de edição',
  pending: 'Pendente',
  deleteContact: 'Remover contacto',
  galarmContact: 'Adicionado manualmente',
  phoneContact: 'Adicionado a partir dos contactos do telemóvel',
  confirmDeleteContactTitle: 'Remover {{name}}?',
  confirmDeleteContact: 'Não os poderá adicionar aos seus alarmes.',
  contactAdded:
    'Ótimo! Agora pode adicionar {{name}} como participante nos seus alarmes.',
  contactInvited:
    'Foi enviada uma mensagem de texto para {{name}} convidando-os a utilizar o Galarm.',
  changeSnoozeDuration: 'Mudança Duração',
  durationOfEvent: 'Duração do evento',
  upgradeToPremium: 'Atualizar para Premium',
  premium: 'Prémio',
  alarmLimitReached: 'Limite de alarme atingido',
  unableToConnectToGooglePlay:
    'Não é possível estabelecer ligação ao Google Play. Certifique-se de que tem sessão iniciada na sua conta Google.',
  cantLoadVideo: 'Não é possível carregar o vídeo',
  cantUpgradeToPremium: 'Não é possível atualizar para Premium',
  unableToConnectToIapAndroid:
    'Não é possível estabelecer ligação à Play Store. Abra a aplicação Play Store e certifique-se de que tem sessão iniciada.',
  unableToConnectToIapIos:
    'Não é possível ligar-se à App Store. Abra a aplicação da App Store e certifique-se de que tem sessão iniciada.',
  premiumFeaturesIos:
    'Como utilizador premium, obtém um limite maior de {{maxLimit}} alarmes simultâneos.\n\nA sua compra também nos ajuda a desenvolver mais funcionalidades.',
  premiumFeaturesAndroid:
    'Crie alarmes ilimitados actualizando para a versão premium.\n\nA sua compra também nos ajuda a desenvolver mais funcionalidades.',
  upgrade: 'Atualização',
  rvInternalError:
    'Não foi possível carregar o vídeo devido a um erro interno. No entanto, aumentámos o seu limite de alarmes em 1.',
  rvNetworkError:
    'Certifique-se de que tem uma boa ligação à Internet e tente novamente.',
  rvNotFoundError:
    'De momento, não foi possível encontrar um vídeo adequado. No entanto, aumentámos o seu limite de alarmes em 1.',
  dontRemindMe: 'Não me lembres',
  sameTimeAsBuddy: 'Na mesma altura que o amigo',
  noAlertForBuddyAlarm:
    'O utilizador optou por não ser alertado para este alarme.',
  noAlertRecipientIntervalDescription: 'Não será alertado.',
  chooseHours: 'Escolher horas',
  chooseMins: 'Selecionar minutos',
  changeHours: 'Alterar horas',
  moreOptions: 'Mais opções...',
  set: 'Conjunto',
  chooseNumberOfDays: 'Introduzir o número de dias',
  chooseNumberOfWeeks: 'Introduzir o número de semanas',
  numberOfDaysShouldBeAtLeastOne: 'O número de dias deve ser, pelo menos, 1',
  numberOfWeeksShouldBeAtLeastOne:
    'O número de semanas deve ser, pelo menos, 1',
  numberOfMonthsShouldBeAtLeastOne: 'O número de meses deve ser, pelo menos, 1',
  numberOfYearsShouldBeAtLeastOne: 'O número de anos deve ser, pelo menos, 1',
  frequency: 'Frequência',
  configuration: 'Configuração',
  endDate: 'Data de fim do alarme',
  noEndDate: 'Sem data de fim',
  alarmEndDateSetOnAlarm:
    'A data de fim do alarme está definida para {{endDateString}}.',
  noAlarmDateSetForAlarm: 'Não é definida uma data de fim para este alarme.',
  noOccurrenceForThisAlarmAsPerEndDate:
    'A data de fim não permite quaisquer ocorrências para o alarme.',
  alarmDateShouldBeBiggerThanAlarmStartDate:
    'A data de fim do alarme deve ser posterior à data de início do alarme.',
  aWeek: 'Uma semana',
  aMonth: 'Um mês',
  allInactiveAlarms: 'Todos os alarmes inactivos',
  chooseAlarmEndDate: 'Selecionar a data de fim do alarme',
  next: 'Seguinte',
  next7Days: '7 dias a partir de hoje',
  next30Days: '30 dias a partir de hoje',
  next12Months: '12 meses a partir de hoje',
  next10Years: '10 anos a partir de hoje',
  chooseDate: 'Escolher uma data...',
  learnMore: 'Saiba mais',
  repeatEvery: 'Repete-se a cada',
  repeatEveryDays: 'dia(s)',
  repeatEveryWeeks: 'semana(s)',
  repeatEveryMonths: 'mês(es)',
  repeatEveryYears: 'ano(s)',
  autoSnooze: 'Adiar alarmes automaticamente',
  autoSnoozePerAlarm: 'Alarme sonoro automático',
  unableToUploadGroupAvatar:
    'Não é possível carregar a imagem do grupo agora. Por favor, tente novamente mais tarde',
  addNewMembersToExistingAlarms:
    'Este grupo dispõe dos seguintes alarmes:\n\n{{alarmNames}}\n    \nPretende adicionar os novos membros a estes alarmes?',
  removeGroupMemberAlert:
    'A remoção do membro terá impacto nos seguintes alarmes:\n\n{{alarmNames}}\n\nAinda pretende remover o membro?',
  leaveGroupAlert:
    'A saída do grupo terá impacto nos alarmes seguintes:\n\n{{alarmNames}}\n\nAinda quer sair do grupo?',
  updateAppAfterFeedback:
    'Verificámos que está a utilizar uma versão mais antiga da aplicação. Actualize-a para obter uma melhor experiência.',
  changesAreLost:
    'Tem alterações não guardadas.\n\nPretende guardar antes de sair?',
  recipientAlarmIntervalGreaterThanRepetitionInterval:
    'O intervalo para "lembrar-me" deve ser inferior ao intervalo de repetição.',
  cascadingAlarmIntervalGreaterThanRepetitionInterval:
    'O intervalo "Tempo entre alertas dos participantes" deve ser inferior ao intervalo de repetição.',
  subscriptionCanceled:
    'A sua subscrição premium foi cancelada. As funcionalidades premium não estarão disponíveis até renovar a subscrição.',
  unableToRenewSubscription:
    'Ocorreu um problema ao renovar a sua subscrição premium. Certifique-se de que as suas informações de pagamento estão actualizadas.',
  notice: 'Aviso',
  ringerSettingsWillApplyToNewAlarms:
    'As alterações efectuadas nas definições de som e vibração aplicam-se apenas aos novos alarmes.\n\nPara os alarmes existentes, pode editar o alarme para alterar as suas definições de som e vibração.',
  renew: 'Renovar',
  galarmPremium: 'Galarm Premium',
  payMonthly: 'Pagamento mensal em {{price}} por mês',
  payYearly: 'Pagamento anual em {{price}} por ano',
  free: 'Grátis',
  unlimited: 'Ilimitado',
  personalAlarms: 'Alarmes pessoais',
  groupAlarms: 'Alarmes de grupo',
  buddyAlarms: 'Alarmes Buddy',
  alarmRepetitions: 'Repetições',
  ringtones: 'Toques de telemóvel',
  alarmHistory: 'Histórico de alarmes',
  base: 'Básico',
  advanced: 'Avançado',
  complete: 'Completo',
  alarmHistoryFree: 'Limitada',
  phoneMusicAsRingtone: 'Música do telemóvel como toque de chamada',
  startFreeTrial: 'Iniciar a avaliação gratuita de 14 dias',
  chargedAtEndOfFreeTrial:
    'Será cobrado após o fim da avaliação gratuita de 14 dias, a menos que cancele a sua subscrição.',
  monthlySubscription: 'Mensal',
  yearlySubscription: 'Anual',
  premiumRingtoneRequiresUpgrade:
    'Este toque de telemóvel requer uma subscrição Premium.',
  defaultPremiumFeaturesDescription:
    'Actualize para Premium para ativar estas funcionalidades fantásticas.',
  groupAlarmsPremiumFeaturesDescription:
    'É necessária uma subscrição premium para criar mais de 1 alarme de grupo.',
  buddyAlarmsPremiumFeaturesDescription:
    'É necessária uma subscrição premium para criar mais de 1 alarme para outra pessoa ("amigo").',
  shareableAlarmLinksPremiumFeaturesDescription:
    'É necessária uma subscrição premium para criar mais ligações de alarme partilháveis.',
  viewPremiumFeaturesDescription:
    'Obrigado por ser um utilizador premium. Como subscritor premium, pode usufruir destas funcionalidades interessantes.',
  accessToPremiumFeatures:
    'Terá acesso a estas funcionalidades como parte da subscrição:',
  alarmCategoryPremiumFeaturesDescription:
    'É necessária uma subscrição premium para criar novas categorias.',
  galarmWebPremiumFeaturesDescription:
    'É necessária uma subscrição premium para utilizar o Galarm num navegador da Web.',
  instantAlarmPremiumFeaturesDescription:
    'É necessária uma subscrição premium para criar mais alarmes instantâneos.',
  personalAlarmsFeatureDescription:
    'Utilize um alarme pessoal para acordar, tomar medicação ou qualquer outra tarefa para si. Opcionalmente, pode adicionar participantes que são alertados se falhar um alarme, para que o possam lembrar. Os participantes também são notificados quando marca o alarme como concluído.',
  groupAlarmsFeatureDescription:
    'Utilize um alarme de grupo para actividades de grupo, como passeios, festas ou qualquer outro evento. O alarme dispara ao mesmo tempo para todos os participantes e estes podem confirmar a sua participação.',
  buddyAlarmsFeatureDescription:
    'Crie alarmes para outra pessoa ("amigo") para a lembrar de coisas que tem de fazer. O utilizador é alertado para lembrar o contacto, caso este não tenha ouvido o alarme. Também será notificado quando o contacto marcar o alarme como concluído.',
  alarmRepetitionsFeatureDescription:
    'Crie alarmes com repetições avançadas como, por exemplo, de 30 em 30 minutos, de 2 em 2 horas e 45 em 45 minutos, de dois em dois dias, quinzenalmente e muito mais.',
  ringtonesFeatureDescription:
    'Escolha entre uma grande variedade de toques premium para os seus alarmes. Estamos continuamente a adicionar mais toques à oferta premium.',
  phoneMusicAsRingtoneFeatureDescription:
    'Escolha qualquer canção/música do seu telemóvel como toque de alarme.',
  shareableAlarmLinksFeatureDescription:
    'É possível partilhar alarmes com outras pessoas através de uma ligação.\n\nPor exemplo, um professor pode partilhar a ligação do alarme da turma com os alunos utilizando o e-mail, o WhatsApp ou qualquer aplicação de mensagens. Quando os alunos tocam na ligação, é criada uma cópia do alarme da turma nos seus telemóveis. Todos os alunos serão alertados à hora especificada para se juntarem à turma.',
  instantAlarmFeatureDescription:
    'Alerte instantaneamente um grupo de pessoas com o clique de um botão.\n\nUtilize este alarme para os avisar de um exercício de emergência, do início de um jogo, de uma tarefa/reunião urgente e de outros eventos que exijam atenção imediata.',
  alarmHistoryFeatureDescription:
    'Ver todas as respostas anteriores para cada alarme repetido. Isto permite-lhe descobrir facilmente quando faltou à aula de ginástica, quando a sua mãe não tomou os medicamentos, etc.',
  announceAlarmTitleFeatureDescription:
    'Anuncia o título do alarme quando o alarme toca.',
  exportToCalendarPremiumFeaturesDescription:
    'É necessária uma subscrição premium para exportar mais alarmes para o calendário.',
  exportToCalendarFeatureDescription:
    'Pode exportar alarmes para o seu calendário. Isto permite-lhe ver facilmente os seus alarmes no calendário do seu dispositivo e refletir melhor a sua disponibilidade.',
  galarmWebFeatureDescription:
    'Utilize o Galarm num browser da Web para criar, editar e eliminar alarmes.',
  preReminderFeatureDescription:
    'Defina pré-avisos para ser alertado sobre os próximos alarmes. Pode configurar a duração relativa para ser alertado antes da hora real do alarme, como 30 minutos antes, 1 dia antes, etc.',
  calendarViewFeatureDescription:
    'Veja os alarmes num calendário para ver facilmente os alarmes de qualquer dia.',
  updatePhoneNumberFeatureDescription:
    'Pode alterar o número de telefone utilizado para esta conta. Todos os seus alarmes, contactos, grupos e outras definições serão migrados para o novo número de telefone.',
  alarmPhotoFeatureDescription:
    'Adicione uma fotografia a um alarme para obter indicações visuais rápidas, como a fotografia de: um medicamento, instruções para uma tarefa, um item, etc. A fotografia será apresentada quando o alarme tocar.',
  taskListFeatureDescription:
    'Crie listas de tarefas para manter as tarefas relacionadas juntas.',
  calendarViewPremiumFeature: 'Vista do calendário',
  updatePhoneNumberPremiumFeature: 'Alterar o número de telefone',
  alarmNotFound: 'Alarme não encontrado',
  alarmNotFoundMessage:
    'Este alarme pode ter sido eliminado recentemente. Toque em "Remover" para desativar outras notificações para este alarme.',
  alarmExistsWithDeepLinkTitle: 'Alarme duplicado',
  alarmExistsWithDeepLinkMessage:
    'Um alarme criado através desta ligação já existe no seu telemóvel.',
  alarmDoesntHaveNextDate: 'O alarme não tem uma data próxima válida.',
  sharePersonalAlarmDeepLinkDescription:
    'Toque nesta hiperligação para criar um alarme para este evento em {{dateString}}.',
  joinGroupAlarmDeepLinkDescription:
    'Toque nesta ligação para participar neste evento de grupo em {{dateString}}',
  subscribedAlarmEdited:
    '{{alarmCreatorName}} foi atualizado {{alarmName}}. Pretende atualizar o seu alarme?',
  subscribedAlarmDeleted:
    '{{alarmCreatorName}} foi eliminado {{alarmName}}. Deseja apagar o seu alarme?',
  responseReceivedForTicket:
    'Respondemos ao seu bilhete #{{ticketId}} em: {{email}}. Se não estiver na sua caixa de entrada, verifique as suas pastas de spam para ver se existe um e-mail de support@galarm.zendesk.com.',
  newTicketReceived:
    'Obrigado por nos ter escrito. Criámos o bilhete #{{ticketId}} para acompanhar o seu pedido.\n\nResponderemos ao endereço de correio eletrónico que forneceu: {{email}}. Se este não estiver correto, envie novamente os seus comentários com o endereço de correio eletrónico correto.\n\nAdicione também support@galarm.zendesk.com ao seu livro de endereços de correio eletrónico para garantir a entrega adequada das nossas respostas. Também enviaremos uma notificação aqui quando tivermos respondido ao seu pedido.',
  sentAccountBackup:
    'Enviámos os seus dados de alarme para {{email}}. Se não estiverem na sua caixa de entrada, verifique se existe um e-mail de noreply@acintyo.com nas pastas de spam.',
  alarmDoesntExist: 'O alarme não existe.',
  shareLink: 'Partilhar ligação',
  joinedGroupAlarmFromDeepLinkMessage:
    'Será recordado de "{{alarmName}}" em {{alarmTime}} em {{alarmDate}}',
  joinedRepeatingGroupAlarmFromDeepLinkMessage: {
    zero: 'Lembrar-se-á de "{{alarmName}}" {{repetitionString}}',
    one: 'Será recordado de "{{alarmName}}" {{repetitionString}} em {{alarmTime}} a partir de {{alarmDate}}'
  },
  youHaveSubscribedToThisAlarm: 'Está a seguir este alarme de {{creatorName}}.',
  alreadySharedAlarmDescription: {
    zero: 'Não tem seguidores para este alarme.\n\nPode continuar a partilhar este alarme com mais pessoas utilizando uma ligação. Quando as pessoas tocarem na hiperligação, será criada uma cópia deste alarme no telemóvel delas.',
    one: '{{count}} pessoa está a seguir este alarme.\n\nPode continuar a partilhar este alarme com mais pessoas utilizando uma ligação. Quando as pessoas tocam na ligação, é criada uma cópia deste alarme no telemóvel delas.',
    other:
      '{{count}} pessoas estão a seguir este alarme.\n\nPode continuar a partilhar este alarme com mais pessoas utilizando uma ligação. Quando as pessoas tocam na ligação, é criada uma cópia deste alarme no telemóvel delas.'
  },
  shareParentAlarmDescription:
    'Está a seguir este alarme de {{creatorName}}.\n\nTambém pode partilhar este alarme com outras pessoas utilizando uma ligação. Quando as pessoas tocam na ligação, é criada uma cópia deste alarme no seu telemóvel e elas também podem começar a seguir este alarme.',
  shareableAlarmLinks: 'Ligações partilháveis',
  nPeopleFollowingAlarm: {
    zero: 'Não tem seguidores para este alarme.',
    one: '{{count}} pessoa está a seguir este alarme.',
    other: '{{count}} pessoas estão a seguir este alarme.'
  },
  likeUsOnFacebook: 'Facebook',
  followUsOnTwitter: 'Twitter',
  followUsOnInstagram: 'Instagram',
  youtube: 'YouTube',
  mediumBlog: 'Blogues no Medium',
  howtoVideos: 'Vídeos de instruções',
  followGalarmOnSocialMedia: 'Seguir a Galarm nas redes sociais',
  galarmOnSocialMedia: 'Galarm nas redes sociais',
  followGalarmOnSocialMediaDescription:
    'Veja notícias interessantes sobre actualizações de aplicações, utilização de funcionalidades, funcionalidades futuras, bem como as principais análises de aplicações. Também pode interagir com a equipa Galarm.',
  sharedAlarm: 'Editar alarme partilhado',
  editingPublishedAlarm: {
    one: 'Está a editar um alarme que partilhou anteriormente utilizando uma ligação. {{count}} pessoa está atualmente a seguir este alarme.\n\nQuando fizer quaisquer edições neste alarme, a pessoa será notificada das alterações.',
    other:
      'Está a editar um alarme que partilhou anteriormente utilizando uma ligação. {{count}} pessoas estão a seguir este alarme.\n\nQuando fizer quaisquer edições neste alarme, elas serão notificadas das alterações.'
  },
  editingSubscribedAlarm:
    'Está a seguir este alarme a partir de {{creatorName}}. Não receberá quaisquer actualizações para este alarme depois de o editar.',
  viewAlarm: 'Ver alarme',
  view: 'Ver',
  editAlarm: 'Editar alarme',
  thanksForTryingPremiumTitle: 'Está tudo pronto!',
  thanksForTryingPremiumMessage:
    'Esperamos que goste das funcionalidades premium.\n\nContacte o suporte através de "Definições > Feedback" ou envie-nos um e-mail para galarm@acintyo.com se tiver alguma dúvida.',
  subscribedAlarmEditedInAlarmDetails:
    '{{creatorName}} alterou o seu alarme.\nAtualizar o seu alarme?',
  subscribedAlarmDeletedInAlarmDetails:
    '{{creatorName}} apagou o seu alarme.\nApagar o alarme?',
  updateSubscribedAlarm: 'Sim, atualizar',
  deleteSubscribedAlarm: 'Sim, apagar',
  ignoreSubscribedAlarm: 'Não, ignorar',
  customRepetitionPremiumScreenDescription:
    'A repetição personalizada é uma funcionalidade premium.\n\nPode utilizar esta funcionalidade para criar alarmes com repetições avançadas. Os exemplos incluem:\n',
  repeatAlternateDate: 'Repetir em dias alternados',
  repeatEveryNDay: 'Repetir de 3 em 3 dias',
  repeatEveryNHoursAndMMinutes: 'Repetir a cada 2 horas e 30 minutos',
  repeatEveryNMinutes: 'Repetir a cada 45 minutos',
  repeatBiweekly: 'Repetir de 2 em 2 semanas',
  repeatEveryNWeeks: 'Repetir a cada 6 semanas',
  repeatEveryNMonths: 'Repetir de 3 em 3 meses (trimestralmente)',
  repeatEveryLastDayOfMonth: 'Repetir o último dia de cada mês',
  repeatDayOfWeekOfMonth: 'Repetição no 2º sábado de cada mês',
  repeatLastDayOfWeekOfMonth: 'Repetir na última sexta-feira de cada mês',
  customRepetition: 'Repetição personalizada',
  lastDayOfMonth: 'Último dia de cada mês',
  dayOfWeekInMonth: '{{weekNumberOfDayInMonth}} {{dayName}} de cada mês',
  lastDayOfWeekInMonth: 'Último {{dayName}} de cada mês',
  dayOfWeekInMonthInYearSummary:
    '{{weekNumberOfDayInMonth}} {{dayName}} de {{monthName}}',
  lastDayOfWeekInMonthInYearSummary: 'Último {{dayName}} de {{monthName}}',
  lastDayOfMonthInYearSummary: 'Último dia de {{monthName}}',
  dayOfWeekInMonthInYear:
    '{{weekNumberOfDayInMonth}} {{dayName}} de {{monthName}} todos os anos',
  lastDayOfWeekInMonthInYear:
    'Último {{dayName}} de {{monthName}} todos os anos',
  lastDayOfMonthInYear: 'Último dia de {{monthName}} todos os anos',
  chooseAMonthsConfiguration: 'Escolha uma configuração.',
  chooseADaysConfiguration: 'Escolha uma configuração',
  chooseAYearsConfiguration: 'Escolha uma configuração.',
  didntGetCodeNewUser:
    'Não recebeu o código?\n\nPode voltar a pedir o código ou ignorar o registo por agora. Pode registar-se mais tarde.',
  didntGetCodeExistingUser:
    'Não recebeu o código?\n\nPode pedir novamente o código ou cancelar o registo. Pode registar-se mais tarde.',
  didntGetCodeUpdatePhoneNumber:
    'Não recebeu o código?\n\nPode pedir novamente o código ou tentar mais tarde.',
  alreadySentCodeAgain:
    'O código foi reenviado para si. Se estiver a ter dificuldades em obter o código, ignore o registo por agora e registe-se mais tarde.',
  theme: 'Tema',
  announceAlarmName: 'Anunciar o título do alarme',
  ttsHelperText:
    'O título do alarme será anunciado quando o alarme tocar. Pode tocar no botão de reprodução em frente ao título do alarme para ouvir uma pré-visualização.',
  initializingTts: 'Inicialização do texto para voz',
  errorSpeakingAlarmName:
    'Não é possível indicar o título do alarme. Contacte o serviço de apoio a partir de "Definições > Feedback".',
  entityAlarm: 'alarme',
  entityGroup: 'grupo',
  entityContact: 'contacto',
  admin: 'Administrador',
  specifyAlarmTitleToSpeak: 'Especificar o título do alarme para falar',
  wakeup: 'Acordar',
  ttsPremiumScreenDescription:
    'O anúncio do título do alarme é uma funcionalidade premium.\n\nQuando activada, o título do alarme será anunciado quando o alarme tocar.\n\nPara pré-visualizar, forneça um título de alarme e toque em reproduzir.',
  unknownAlarmCategory: 'Categoria de alarme desconhecida',
  cantAnnounceAlarmTitle: 'O título do alarme não será anunciado',
  ringtoneDurationIsSmall:
    'A duração do toque deve ser de pelo menos 5 segundos para que o título do alarme seja anunciado.\n\nPretende continuar com estas definições?',
  noActionItems: 'Não há pontos de ação.',
  personNotInContacts: 'Esta pessoa não se encontra nos seus contactos Galarm.',
  light: 'Luz',
  dark: 'Escuro',
  systemDefault: 'Utilizar a definição do dispositivo',
  chooseTheme: 'Escolher tema',
  dontSeeAllYourContacts: 'Não vê todos os seus contactos?',
  lastContactsSyncedAt: 'Última sincronização em {{dateString}}',
  refresh: 'Atualizar',
  forever: 'Para sempre',
  autoSnoozeConfiguration: 'Configurar o Snooze',
  autoSnoozeConfigurationSummary:
    '{{autoSnoozeDurationLabel}}, {{autoSnoozeCountLabel}}',
  uncategorizedCategoryName: 'Não categorizado',
  uncategorizedCategoryScreenTitle: 'Sem categoria',
  setAlarmCategory: 'Definir categoria de alarme',
  changeAlarmCategory: 'Alterar a categoria do alarme',
  viewAlarmHistory: 'Ver histórico de alarmes',
  computingAlarmHistory: 'Resumo informático do histórico de alarmes...',
  alarmByCategories: '{{name}} Alarmes',
  alarmCategories: 'Categorias',
  editAlarmCategory: 'Editar categoria',
  deleteAlarmCategory: 'Eliminar categoria',
  newAlarmCategoryTitle: 'Nova categoria',
  newAlarmCategoryNamePlaceholder: 'Especificar um nome',
  editAlarmCategoryTitle: 'Editar categoria',
  alarmCategorySummary: {
    zero: '{{alarmsSummary}}',
    one: '{{alarmsSummary}}, {{count}} falhou',
    other: '{{alarmsSummary}}, {{count}} falhou'
  },
  numAlarms: {
    zero: 'Sem alarmes',
    one: '{{count}} alarme',
    other: '{{count}} alarmes'
  },
  numIncidents: {
    zero: 'Nenhum incidente',
    one: '{{count}} incidente',
    other: '{{count}} incidentes'
  },
  openedIncidents: 'Incidentes abertos',
  closedIncidents: 'Incidentes encerrados',
  incidentChart: 'Gráfico de incidentes (últimos 30 dias)',
  incidentCalendar: 'Calendário de incidentes',
  incidentDistribution: 'Distribuição de incidentes (últimos 30 dias)',
  noIncidentsOpenedClosedInLast30Days:
    'Nenhum incidente aberto ou encerrado nos últimos 30 dias',
  noIncidentsOpenedInLast30Days: 'Nenhum incidente aberto nos últimos 30 dias',
  color: 'Cor',
  createNewAlarmCategory:
    'Criar uma nova categoria tocando no botão + no canto inferior direito',
  setValidName: 'Definir um nome válido',
  specifyTimezoneForAlarmMessage:
    'Pode especificar um fuso horário quando cria alarmes. Os alarmes tocarão de acordo com o fuso horário especificado. \n\nSe escolher "Fuso horário do dispositivo", os alarmes tocarão de acordo com o fuso horário do dispositivo.',
  alarmCategoryFeatureDescription:
    'Crie as suas próprias categorias para os alarmes. As categorias permitem-lhe organizar facilmente os seus alarmes.',
  markAlarmDoneInAdvance: 'Marcar o alarme "Concluído" mais cedo?',
  skipAlarmInAdvance: '"Saltar" o alarme mais cedo?',
  markAlarmDoneInAdvanceMessageRepeatingAlarm:
    'O alarme não tocará em {{dateTimeString}} se o marcar como "Concluído" agora.',
  skipAlarmInAdvanceMessageRepeatingAlarm:
    'O alarme não tocará em {{dateTimeString}} se o ignorar agora.',
  markAlarmDoneInAdvanceMessageOneTimeAlarm:
    'O alarme não tocará em {{dateTimeString}} se marcar "Concluído" agora.',
  skipAlarmInAdvanceMessageOneTimeAlarm:
    'O alarme não tocará em {{dateTimeString}} se o ignorar agora.',
  alarmsByCategories: 'Alarmes por categorias',
  workAlarmCategory: 'Trabalho',
  homeAlarmCategory: 'Início',
  birthdaysAlarmCategory: 'Aniversários',
  more: 'Mais',
  alarmCategory: 'Categoria de alarme',
  editAlarmCategoryColor: 'Editar cor',
  editAlarmCategoryColorTitle: 'Editar cor para {{name}}',
  allUserCategoriesDeleted:
    'Todas as categorias de alarme criadas por si foram removidas porque a subscrição premium foi cancelada. Os alarmes nessas categorias aparecerão em "Não categorizado".',
  calendar: 'Calendário',
  eventExportedFromGalarm:
    '"Exportado de Galarm em {{dateString}} em {{timeString}}."',
  unableToExportEventToCalendar:
    'Não é possível exportar o evento para o calendário. Certifique-se de que tem permissão para aceder ao calendário no dispositivo. Envie-nos um feedback utilizando "Definições > Feedback" se o problema persistir.',
  scanQrCode:
    'Para aceder ao Galarm num navegador, vá a web.galarm.app e leia o código QR.',
  logOut: 'Sair do sistema',
  useHere: 'Utilizar aqui',
  appRunningElsewhere:
    'Foi encerrada a sessão. Gostaria de retomar esta sessão?',
  dateTime: 'Data e hora',
  selectAtLeastOneDayForRepetition:
    'Escolha pelo menos um dia em que o alarme se deve repetir.',
  chooseNumberOfDaysForAlarmRepetition:
    'Introduzir o número de dias após os quais o alarme deve repetir-se.',
  chooseNumberOfWeeksForAlarmRepetition:
    'Introduzir o número de semanas após as quais o alarme deve ser repetido.',
  chooseNumberOfMonthsForAlarmRepetition:
    'Introduzir o número de meses após os quais o alarme deve repetir-se.',
  chooseNumberOfYearsForAlarmRepetition:
    'Introduzir o número de anos após os quais o alarme deve repetir-se.',
  previouslySelectedContactsAreUnselected:
    'O(s) contacto(s) anteriormente selecionado(s) deixa(m) de estar selecionado(s).',
  previouslySelectedGroupIsUnselected:
    'O grupo anteriormente selecionado deixa de ser selecionado.',
  galarmWebInstructions:
    '1. Abra a aplicação Galarm no seu telemóvel\n2. Aceda ao separador "Definições" e toque em "Galarm Web"\n3. Certifique-se de que está a executar a aplicação mais recente se não vir a opção\n4. Digitalizar o código QR à direita',
  likeToCreateAlarm: 'Criar um alarme para...',
  payUtilityBills: 'Pagar facturas',
  wakeupAlarmName: 'Acordar',
  exerciseAlarmName: 'Exercício',
  drinkWaterAlarmName: 'Beber água',
  everyNHoursRepetitionStringShortSummary: {
    one: '{{startTimeString}} para {{endTimeString}} A cada hora',
    other: '{{startTimeString}} para {{endTimeString}} A cada {{count}} horas'
  },
  firstTimeManagementQuote:
    '"A má notícia é que o tempo voa. A boa notícia é que tu és o piloto."',
  firstTimeManagementQuoteAuthor: 'Michael Altshuler',
  secondTimeManagementQuote:
    '"Toda a gestão do tempo começa com o planeamento."',
  secondTimeManagementQuoteAuthor: 'Tom Greening',
  thirdTimeManagementQuote:
    '"Nunca deixes para amanhã aquilo que podes fazer hoje."',
  thirdTimeManagementQuoteAuthor: 'Benjamin Franklin',
  fourthTimeManagementQuote:
    '"O tempo é o que mais queremos, mas o que pior usamos."',
  fourthTimeManagementQuoteAuthor: 'William Penn',
  fifthTimeManagementQuote: '"Daqui a um ano, pode desejar ter começado hoje."',
  fifthTimeManagementQuoteAuthor: 'Karen Lamb',
  sixthTimeManagementQuote: '"Podeis adiar, mas o tempo não o fará".',
  sixthTimeManagementQuoteAuthor: 'Benjamin Franklin',
  tapToGetStarted: 'Toque em "+" para começar.',
  allCategories: 'Todas as categorias',
  createAlarmWithContact: 'Criar um alarme com {{name}}',
  createAlarmWithGroup: 'Criar um alarme com {{name}}',
  editGroup: 'Editar grupo',
  discardChanges: 'As alterações actuais serão eliminadas. Continuar?',
  alarmWillRingEveryNDays: {
    zero: 'O alarme tocará todos os dias.',
    one: 'O alarme tocará em dias alternados.',
    other: 'O alarme tocará a cada {{dayWithOrdinal}} dia.'
  },
  hoursString: { zero: '', one: '1 hora', other: '{{count}} horas' },
  minutesString: { zero: '', one: '1 minuto', other: '{{count}} minutos' },
  alarmWillRingEveryMHoursAndNMinutes:
    'O alarme tocará após cada {{repeatString}}, começando por {{dayString}}. Depois disso, {{otherDaysString}} e assim por diante.',
  alarmWillRingEveryNWeeks: {
    zero: 'O alarme tocará todas as semanas.',
    one: 'O alarme tocará de duas em duas semanas.',
    other: 'O alarme tocará a cada {{weekWithOrdinal}} semana.'
  },
  alarmWillRingEveryNMonths: {
    zero: 'O alarme tocará todos os meses.',
    one: 'O alarme tocará de dois em dois meses.',
    other: 'O alarme tocará a cada {{monthWithOrdinal}} mês.'
  },
  alarmWillRingEveryNYears: {
    zero: 'O alarme tocará todos os anos.',
    one: 'O alarme tocará de dois em dois anos.',
    other: 'O alarme tocará a cada {{yearWithOrdinal}} ano.'
  },
  ringParticipantAlarmsByDefaultTooltipMessage:
    'Quando esta preferência está activada, os alarmes de outras pessoas tocam sem os aceitar. Mesmo assim, deve aceitar o alarme para informar o criador de que tenciona participar no alarme.\n\nQuando esta preferência está desactivada, os alarmes adicionados para si por outros não tocarão no seu telemóvel, a menos que os aceite explicitamente.',
  specifyTimezoneForAlarmDefaultTooltipMessage:
    'Quando esta preferência está activada, é possível especificar um fuso horário quando se criam alarmes. Os alarmes tocarão de acordo com o fuso horário especificado.\n\nQuando esta preferência está desactivada, os alarmes tocam de acordo com o fuso horário do dispositivo.',
  ringOnEarphoneOnlyTooltipTitle:
    'Comportamento do alarme quando os auriculares estão ligados',
  ringOnEarphoneOnlyTooltipMessage:
    'Quando os auriculares estão ligados, pode configurar os seus alarmes para tocarem apenas nos auriculares ou no altifalante do telefone e nos auriculares.\n\nQuando os auriculares não estão ligados, os alarmes tocam no altifalante do telefone.',
  gestureOptionsTooltipTitle: 'Gesto para desligar um alarme',
  gestureOptionsTooltipMessage:
    'Quando um alarme toca no ecrã de bloqueio, pode desligá-lo tocando num botão ou deslizando um botão.\n\nEsta definição permite-lhe escolher como pretende ignorar um alarme.',
  notificationSettingsTooltipTitle: 'Definições de notificação',
  notificationSettingsTooltipMessage:
    'Active as notificações que pretende receber e desactive as que não pretende receber.',
  messagesForAlarm: 'Mensagens para {{name}}',
  systemNotificationsTooltipMessage:
    'Notificações geradas pela aplicação para eventos como:\n  - quando os alarmes são afectados devido à mudança de fuso horário do contacto\n  - quando um contacto convidado se regista no Galarm\n  - quando os alarmes são afectados pelo facto de alguém apagar a sua conta',
  galarmWebNotificationsTooltipMessage:
    'As notificações enviadas pelo Galarm Web.',
  alarmsfromOthersNotificationsTooltipMessage:
    'As notificações que são enviadas quando é adicionado a um alarme como participante.',
  alarmChatNotifictionsTooltipMessage:
    'Notificações enviadas para mensagens de chat num alarme.',
  groupNotificationsTooltipMessage:
    'Notificações enviadas quando o utilizador é adicionado a um grupo, removido de um grupo ou quando outra pessoa é adicionada/removida de um grupo.',
  alarmAcknowledgementNotificationsTooltipMessage:
    'Notificações que são enviadas quando um participante reconhece um alarme no qual também é participante.',
  sharedAlarmNotificationsTooltipMessage:
    'As notificações que são enviadas:\n  - quando alguém subscreve um alarme partilhado por si através de uma ligação\n  - quando um alarme subscrito pelo utilizador é alterado',
  sleepTimer: 'Música para dormir',
  sleepTimerTitle: 'Tocar música para',
  ongoingSleepTimer: 'Música para dormir em curso',
  stopSleepTimer: 'Quer parar a música atual?',
  restartSleepTimer: 'Pretende parar a música atual e começar uma nova?',
  play: 'Jogar',
  youAreOffline:
    'Está atualmente offline. Entre em linha para efetuar quaisquer operações.',
  sleepMusicTooltip:
    'Reproduz sons naturais para relaxar o corpo e a mente. A música pára de tocar após a duração especificada.',
  alarmLinkCopiedToClipboard:
    'Ligação do alarme copiada para a área de transferência.',
  confirmLogOut: 'Deseja terminar a sessão?',
  appNotRunningMessage:
    'Detectámos que o Galarm não está a funcionar no seu telemóvel. Toque para iniciar o Galarm de modo a não perder nenhum alarme importante.',
  tapToEnterNotesForAlarm: 'Toque para introduzir notas',
  accountAlreadyExists: 'Conta existente encontrada',
  accountsMerged:
    'Encontrámos uma conta existente associada a este número de telemóvel. Fundimos as duas contas.',
  upgradeToPremiumToAssignUserDefinedCategory:
    'Atualizar para Premium para selecionar a categoria definida pelo utilizador.',
  userDefinedCategoryNotSetForAlarm:
    'Atualizar para Premium para atribuir automaticamente a categoria definida pelo utilizador. A categoria não está definida para o alarme.',
  alarmDetails: 'Detalhes do alarme',
  profileDetails: 'Perfil',
  activeWebSession:
    'Já tem uma sessão Web ativa. Pode terminar a sessão ou iniciar uma nova sessão.\n\nAo iniciar uma nova sessão, sairá automaticamente da sessão Web atualmente ativa.',
  startNewSession: 'Iniciar uma nova sessão',
  confirmWebSessionLogout:
    'Esta ação faz com que saia da sua sessão Web atual. Pode iniciar uma nova sessão digitalizando novamente o código.',
  defaultInstantAlarmTitleForGroup: '{{groupName}} Alerta de {{creatorName}}',
  defaultInstantAlarmTitleForContact: 'Alerta de {{creatorName}}',
  atleastOneMemberShouldBeActive:
    'Pelo menos um participante deve estar ativo.',
  activeParticipants: 'Participantes activos',
  acceptAlarm: 'Aceitar o alarme',
  rejectAlarm: 'Rejeitar alarme',
  nConfirmed: '{{count}} confirmado',
  nDeclined: '{{count}} recusado',
  nPending: '{{count}} pendente',
  everyoneConfirmed: 'Todos confirmaram',
  everyoneDeclined: 'Todos recusaram',
  appTagline: 'Nunca perder nada',
  usePreviousBackup: 'Sou um utilizador registado >',
  viewAlarmsByCategories: 'Ver alarmes por categorias',
  viewAllAlarms: 'Ver todos os alarmes',
  switchToSummaryView: 'Mudar para a vista de resumo',
  switchToListView: 'Mudar para a vista de lista',
  hideHourlyAlarms: 'Ocultar alarmes horários',
  calendarView: 'Calendário Galarm',
  viewRecentlyDeletedAlarms: 'Ver alarmes eliminados recentemente',
  deletedOnDate: 'Eliminado em {{alarmDate}}',
  recentlyDeletedAlarmFooterText: 'Este alarme foi eliminado em {{alarmDate}}',
  noRecentlyDeletedAlarms: 'Não há alarmes eliminados recentemente',
  restoreAlarmsFromMobileApp:
    'Estes alarmes serão automaticamente eliminados após 30 dias da sua eliminação. Se pretender restaurar algum deles, aceda ao ecrã "Alarmes recentemente eliminados" da sua aplicação móvel e toque no respetivo ícone de restauração.',
  deleteInactiveAlarms: 'Eliminar alarmes inactivos',
  defaultConfigurationUpdated: 'A configuração predefinida foi actualizada.',
  notAlerted: '(excluído)',
  alarmKeepsRingingHelp: 'Ajuda! O meu alarme está sempre a tocar',
  alarmKeepsRingingHelpMessage:
    'Actualizámos os seus alarmes e enviámos o relatório do problema.',
  alarmKeepsRingingHelpAlert:
    'Iremos atualizar os seus alarmes e já não deverá observar este problema. Enviaremos também um relatório do problema à nossa equipa de assistência para investigação.\n\n  Se o problema persistir, contacte-nos através de "Definições > Feedback".',
  sendingFeedback: 'Enviar feedback',
  sendingProblemReport: 'Envio de relatório de problemas',
  fixingAlarms: 'Atualizar os alarmes',
  existingAccountFound: 'Cópia de segurança restaurada',
  existingAccountDataRestored: {
    zero: 'Parabéns, todos os dados foram restaurados a partir da cópia de segurança.',
    one: 'Parabéns, todos os dados foram restaurados a partir da cópia de segurança.',
    other:
      'Parabéns, todos os dados foram restaurados a partir da cópia de segurança.'
  },
  noExistingAccountFound: 'Nenhuma cópia de segurança encontrada',
  createdNewAccount:
    'Este número de telefone não foi registado no Galarm. Criámos uma nova conta com este número de telefone.',
  iosCloseToAllowedNumNotificationsScheduled: {
    zero: 'O iOS permite que uma aplicação agende até 64 notificações. Utilizou todas essas notificações. É possível que perca alguns alarmes.',
    one: 'O iOS permite que uma aplicação agende até 64 notificações. Utilizou {{numNotifications}} dessas notificações.'
  },
  acknowledgeAlarmToScheduleNextOccurrence:
    'Esta é a última notificação para este alarme. Prima longamente esta notificação e tome uma ação para agendar a próxima ocorrência deste alarme.',
  lastNotificationForOneTimeAlarm:
    'Esta é a última notificação para este alarme.',
  criticalAlertsMessageTitle: 'Alarmes de toque quando o telefone está mudo',
  criticalAlertsMessageDescription:
    '\nÉ possível configurar o Galarm para utilizar os Alertas críticos do iOS para tocar alarmes quando o telemóvel está silenciado. Gostaria de o ativar para todos os alarmes?\n\nTambém é possível activá-lo seletivamente para alguns alarmes editando a definição "Som e vibração > Alertas críticos".',
  allowCriticalAlerts: 'Permitir alertas críticos',
  criticalAlertsPermissionDenied:
    'O Galarm requer permissão do seu telemóvel para mostrar alertas críticos. Active esta permissão nas definições do seu telemóvel.',
  enableCriticalAlertsForAllAlarms: 'Sim, ativar para todos os alarmes',
  selectivelyEnableCriticalAlerts: 'Não, vou ativar seletivamente',
  firstName: 'Nome próprio',
  lastName: 'Apelido',
  email: 'Correio eletrónico',
  password: 'Palavra-passe',
  confirmPassword: 'Confirmar a palavra-passe',
  signup: 'Inscrever-se',
  login: 'Iniciar sessão',
  alreadySignedUp: 'Já se inscreveu?',
  notAUser: 'Não é um utilizador?',
  subscribe: 'Subscrever',
  choosePlan: 'Configurar plano',
  billingCycle: 'Ciclo de faturação',
  needHelpToGetStarted: 'Precisa de ajuda para começar?',
  browser: 'Navegador: {{name}}',
  deviceOs: 'Dispositivo: {{name}}',
  lastActive: 'Último ativo: {{timestamp}}',
  signUp: 'Inscrever-se',
  useGalarmOnWeb: 'Utilizar o Galarm na Web',
  welcomeUser: 'Bem-vindo {{name}}',
  changePassword: 'Alterar a palavra-passe',
  saveAlarm: 'Guardar o alarme',
  galarmUserTip: 'Sabia que?',
  sendAlarm: 'Enviar alarme',
  saveGroup: 'Guardar grupo',
  webAppBestViewedOnDesktop:
    'O URL (web.galarm.app) destina-se a lançar uma interface Galarm num navegador Web no seu computador portátil ou de secretária.\n\nPara utilizar o Galarm no seu dispositivo móvel, instale a aplicação Android a partir do Google Play ou a aplicação iOS a partir da App Store utilizando uma das ligações abaixo:',
  scanQrCodeMessage: 'Digitalizar código QR',
  reloadQrCode: 'Clique para recarregar o código QR',
  invalidQrCodeScanned: 'Código QR inválido digitalizado.',
  unableToScanCode: 'Não é possível digitalizar o código',
  unableToScanCodeMessage:
    'Houve um problema na leitura do código. Por favor, tente novamente. Se continuar a ter problemas, envie-nos um feedback a partir da aplicação móvel utilizando "Definições > Feedback".',
  problemWithEnterpriseAccountSetup:
    'Ocorreu um problema ao configurar a sua conta Galarm Pro. Contacte o suporte técnico.',
  problemWithCreatingCheckoutSession:
    'Ocorreu um problema ao criar uma sessão de checkout. Contacte o apoio técnico.',
  problemWithCreatingCustomerPortalSession:
    'Ocorreu um problema ao criar uma sessão do portal do cliente. Contacte o apoio técnico.',
  problemWithCreatingApiKey:
    'Ocorreu um problema ao criar uma chave de API. Contacte o apoio técnico.',
  creatingAnAccount: 'Criar uma conta',
  currentPlan: 'Plano atual: {{name}} ({{quantity}} lugares)',
  myAccount: 'Informações sobre a conta',
  new: 'Novo',
  deleteAllAlarmHistoryAlertTitle: 'Eliminar o histórico de alarmes',
  deleteAllAlarmHistoryAlertMessage:
    'Toque em "Continuar" para eliminar o histórico deste alarme. Esta ação não pode ser anulada.',
  ageOutInactiveAlarmsMessage:
    'Tem {{count}} alarmes que estão inactivos há mais de 60 dias. Ter muitos alarmes inactivos pode afetar o desempenho da aplicação.\n\nPretende eliminar estes alarmes?',
  duplicateAlarm: 'Alarme duplicado',
  graduallyIncreaseVolume: 'Aumentar gradualmente o volume',
  graduallyIncreaseVolumeTooltipMessage:
    'Quando esta preferência está activada, o volume do alarme aumenta gradualmente até ao volume do toque configurado. Isto permite-lhe parar o alarme antes de este ficar demasiado alto.\n\nQuando esta preferência está desactivada, os alarmes tocam no volume de toque configurado durante toda a duração do toque.',
  teams: 'Equipas',
  alerts: 'Alertas',
  listeners: 'Ouvintes',
  noMembersConfiguredInEnterpriseAccount:
    'Ainda não adicionou nenhum membro. Os membros são utilizadores que podem ser adicionados à política de escalonamento de um ouvinte e são alertados para incidentes recebidos pelo ouvinte. Estas pessoas já devem estar registadas no Galarm.',
  noTeamsConfiguredInEnterpriseAccount:
    'Ainda não criou nenhuma equipa. As equipas são grupos de membros que podem ser adicionados à política de escalonamento de um ouvinte e são alertados para incidentes recebidos pelo ouvinte.',
  noAlertsConfiguredInEnterpriseAccount:
    'Ainda não criou nenhum ouvinte. Pode configurar os ouvintes para ouvir os incidentes comunicados pelas ferramentas de monitorização. Depois de criar um ouvinte, pode ver instruções sobre como integrar o ouvinte com várias ferramentas de monitorização.\n\nÉ possível configurar vários níveis de escalonamento para cada ouvinte.',
  noShiftsConfiguredInEnterpriseAccount:
    'Ainda não criou nenhuma configuração de turno.',
  noRotationsConfiguredInEnterpriseAccount: 'Ainda não criou nenhuma rotação.',
  rotations: 'Rotações',
  addRotation: 'Adicionar rotação',
  enterRotationName: 'Introduzir o nome da rotação',
  rotationType: 'Tipo de rotação',
  numberOfHoursInRotation: 'Número de horas em rotação',
  numberOfDaysInRotation: 'Número de dias em rotação',
  numberOfWeeksInRotation: 'Número de semanas de rotação',
  specify: 'Especificar...',
  noIntegrationsConfiguredInEnterpriseAccount:
    'Ainda não configurou nenhuma configuração de integração.',
  loadingUser: 'Carregar informações do utilizador',
  addMember: 'Adicionar membro',
  add: 'Adicionar',
  noUserFoundWithMobileNumber:
    'Não foi encontrado nenhum utilizador Galarm registado com este número de telefone. Certifique-se de que introduz o número de telefone com o indicativo do país sem quaisquer caracteres especiais, como parênteses, travessões, espaços em branco, etc.',
  memberWithPhoneNumberAlreadyAdded:
    'O membro com este número de telefone já foi adicionado.',
  memberWithEmailAlreadyAdded: 'O membro com este e-mail já foi adicionado.',
  assignKeyToMember: 'Atribuir chave de licença',
  revokeKeyFromMember: 'Revogar a chave de licença',
  cantAssignKeyToMember: 'Não é possível atribuir uma chave a um membro',
  noEnterpriseLicenseKeyAvailable:
    'Não existem chaves de licença disponíveis. Actualize o seu plano.',
  addTeam: 'Adicionar equipa',
  enterTeamName: 'Introduzir um nome de equipa',
  addAlert: 'Adicionar ouvinte',
  low: 'Baixa',
  medium: 'Médio',
  high: 'Elevado',
  severity: 'Gravidade',
  enterAlertName: 'Introduzir o nome do ouvinte',
  organization: 'Organização',
  startTime: 'Hora de início',
  endTime: 'Tempo final',
  shiftName: 'Deslocação {{index}}',
  addShift: 'Adicionar turno',
  assignees: 'Destinatários',
  shiftStartEndTime: '{{startTime}} - {{endTime}}',
  assignee: 'Cessionário',
  specifyAssigneeForShift: 'Indicar o responsável pelo turno',
  selectAtLeastOneMember: 'Selecionar pelo menos um membro',
  editMember: 'Editar membro',
  editTeam: 'Editar equipa',
  cantRemoveMemberAssignedToTeamsOrAlerts:
    'O membro faz parte de uma equipa ou está atribuído a um ouvinte. Retire o membro dessas equipas/ouvintes antes de o apagar.\nEquipas: {{teams}}\nOuvintes: {{alerts}}',
  cantRevokeKeyFromMemberAssignedToTeamsOrAlerts:
    'O membro faz parte de uma equipa ou está atribuído a um ouvinte. Retire o membro dessas equipas/ouvintes antes de revogar a chave.\nEquipas: {{teams}}\nOuvintes: {{alerts}}',
  cantDeleteTeamAssignedToAlerts:
    'A equipa faz parte de um ouvinte. Remova a equipa do ouvinte antes de a eliminar.\nOuvintes: {{alerts}}',
  cantDeleteRotationAssignedToAlerts:
    'A rotação faz parte de um ouvinte. Remova a equipa do ouvinte antes de a eliminar.\nOuvintes: {{alerts}}',
  editAlert: 'Editar ouvinte',
  timezoneDisplayString: '(GMT{{offset}}) {{timezone}}',
  apiKey: 'Chave API',
  viewApiKey: 'Ver chave API',
  apiKeyWarningMessage:
    'Mantenha a sua chave API sempre segura. Não a partilhe com ninguém. Se você acha que sua chave de API foi comprometida, toque no botão "Alterar" acima para alterá-la. Depois de alterar a chave, terá de especificar a nova chave em todos os locais onde está a ativar incidentes utilizando a API REST',
  integrationConfigs: 'Configurações de integração',
  addIntegrationConfig: 'Adicionar configuração de integração',
  editIntegrationConfig: 'Editar configuração de integração',
  integrationSource: 'Fonte de integração',
  apiToken: 'Token API',
  integrations: 'Integrações',
  selectPlaceholder: 'Selecionar...',
  zendeskSubdomain: 'Subdomínio do Zendesk',
  creatorTimeForBuddyAlarm: '{{timeString}} ({{remindMeAfter}})',
  nextFiveAlarms: 'Próximos 5 alarmes',
  categories: 'Categorias',
  viewingAlarmsDueToday: 'Prazo de entrega dos alarmes de visualização: hoje',
  viewingAlarmsDueTomorrow:
    'Os alarmes de visualização devem ser entregues amanhã',
  expiredAlarms: 'Alarmes expirados',
  viewingExpiredAlarms: 'Visualizar alarmes expirados',
  noAlarmsDueToday: 'Não há alarmes para hoje',
  noAlarmsDueTomorrow: 'Não há alarmes para amanhã',
  noExpiredAlarms: 'Sem alarmes expirados',
  viewingAllAlarms: 'Ver todos os alarmes',
  viewingRecentlyDeletedAlarms: 'Ver alarmes recentemente eliminados',
  summaryView: 'Experimentar a vista de resumo?',
  summaryViewDescription:
    'Ver os alarmes organizados por datas de vencimento e categorias.\n\nPode voltar à vista de lista utilizando o botão ⋮ no canto superior direito.',
  disableBatteryOptimizationMessageOnStartup:
    'O Galarm precisa de ser executado em segundo plano para que os alarmes toquem de forma fiável. Autorize a permissão no ecrã seguinte.',
  disableBatteryOptimizationMessage:
    'O Galarm precisa de ser executado em segundo plano para que os alarmes toquem de forma fiável.',
  allowNotificationsToDisplayAlarms:
    'O Galarm precisa de permissão para mostrar notificações quando os alarmes tocam.',
  scheduleExactAlarmsMessage:
    'O Galarm precisa de autorização para programar alarmes exactos.',
  alarmCategoryWithSameNameExists:
    'Já existe uma categoria com este nome. Por favor, especifique um nome diferente.',
  alarmRepetitionsPremiumFeature: 'Opções avançadas de repetição',
  ringtonesPremiumFeature: 'Mais toques',
  galarmWebPremiumFeature: 'Utilizar o Galarm na Web',
  taskListPremiumFeature: 'Criar várias listas de tarefas',
  preReminderPremiumFeature: 'Pré-lembrete para alarmes',
  alarmCategoriesPremiumFeature: 'Categorias de alarme personalizadas',
  shareableAlarmLinksPremiumFeature: 'Ligações de alarme partilháveis',
  announceAlarmNamePremiumFeature: 'Anunciar o título do alarme',
  manageSubscription: 'Gerir a subscrição',
  purchaseRestored: 'Compra restaurada.',
  unableToRetrieveSubscriptionPlansAndroid:
    'Não é possível estabelecer ligação ao Google Play para efetuar a atualização. Certifique-se de que está ligado à Internet e tente novamente. \n\nSe o problema persistir, comunique-o à nossa equipa de assistência.',
  unableToRetrieveSubscriptionPlansIos:
    'Não é possível estabelecer ligação à App Store para efetuar a atualização. Certifique-se de que está ligado à Internet e tente novamente. \n\nSe o problema persistir, comunique-o à nossa equipa de assistência.',
  drawOverOtherApps: 'Mostrar sobre outras aplicações',
  drawOverOtherAppsHelperText:
    'Permitir que o Galarm mostre alertas de alarme na parte superior',
  nTimes: { one: 'Uma vez', other: '{{count}} tempos' },
  learnHowToUseFocus: 'Aprender o foco',
  enhancedNotificationsDeprecated: 'Notificações melhoradas obsoletas',
  enhancedNotificationsDeprecatedMessage:
    'Descobrimos que as Notificações melhoradas não são fiáveis e causam um consumo excessivo de bateria. Utilize o iOS Focus para permitir que o Galarm toque os alarmes quando o telemóvel está em Não incomodar.',
  usingIosFocus: 'Utilizar o iOS Focus',
  iosFocusModePara1:
    'O Galarm não tocará quando o interrutor de toque do telefone estiver desligado ou quando o volume do toque for 0.\n\nA Apple recomenda a utilização do "Focus" quando pretender manter o telemóvel em silêncio, mas ainda assim permitir que algumas aplicações o notifiquem. Utilize as seguintes instruções para configurar o Focus no seu telemóvel.',
  iosFocusModePara2:
    'Pode ver instruções mais detalhadas com capturas de ecrã em https://galarm.zendesk.com/hc/en-us/articles/10906890793236-Allow-alarms-to-ring-on-silent-on-iOS',
  settingFocus: 'Configurar o Focus:',
  settingFocusFootNotes:
    'Tenha em atenção que o Focus está disponível no iOS 15 e superior.\n\nPara questões ou comentários, contacte-nos através de "Definições > Comentários".',
  enablingFocus: 'Ativar o foco:',
  settingFocusInstruction1: 'Aceda a "Definições do telemóvel > Foco".',
  settingFocusInstruction2: 'Toque no perfil de foco "Não incomodar".',
  settingFocusInstruction3: 'Toque em "Aplicações" e adicione o Galarm.',
  settingFocusInstruction4:
    'Faça o mesmo para outros perfis Focus que tenha configurado.',
  enablingFocusInstruction1:
    'Deslize para baixo a partir do canto superior direito para ver o Centro de Controlo.',
  enablingFocusInstruction2: 'Toque em Foco.',
  enablingFocusInstruction3:
    'Toque em Não incomodar ou em qualquer outro perfil de foco que pretenda ativar.',
  enablingFocusInstruction4:
    'O Foco atualmente ativado parece estar selecionado. Pode tocar novamente para o desativar.',
  startDateForRotation: 'Data de início da rotação',
  startTimeForRotation: 'Hora de início da rotação',
  member: 'Membro',
  multipleShiftsInADay: 'Vários turnos num dia',
  escalationPolicy: 'Política de escalonamento',
  atTimeOfEvent: 'No momento do evento',
  afterNMinutes: 'Após {{count}} minutos',
  afterNHours: { one: 'Após 1 hora', other: 'Após {{count}} horas' },
  afterDuration: 'Depois de {{durationString}}',
  addTrigger: 'Adicionar acionador',
  viewCode: 'Ver código',
  apiCodes: 'Códigos API',
  viewIncidents: 'Ver incidentes',
  incidentsForAlert: 'Incidentes para o ouvinte {{alertName}}',
  timestamp: 'Carimbo de data/hora',
  acknowledged: 'Reconhecido',
  noIncidentsForAlert: 'Ainda não há incidentes para este ouvinte',
  apiCodeCopiedToClipboard:
    'O código API foi copiado para a área de transferência.',
  triggerName: 'Gatilho {{index}}',
  delay: 'Atraso',
  chooseAssigneePlaceholder: 'Selecionar Destinatário...',
  delayForTrigger: 'Atraso para disparo',
  assigneeWillBeAlertedAfterDelay:
    'O cessionário será alertado após {{durationString}}.',
  escalationForIncidentsForAlert:
    'Escalonamento do incidente em {{incidentDate}} para o ouvinte {{alertName}}',
  alertIncidentAcknowledged: 'Reconhecido por {{memberName}} em {{dateString}}',
  alertIncidents: 'Incidentes',
  viewingAlertIncidents: 'Visualização de incidentes',
  createOrganization: 'Criar organização',
  youAreNotPartOfAnyOrganization:
    'Não faz parte de nenhuma organização. Crie uma organização para começar.',
  tellUsAboutYourOrganization: 'Fale-nos da sua organização',
  noActiveSubscription: 'Não tem uma subscrição ativa.',
  chooseOrganization: 'Escolha uma organização para continuar.',
  noTriggersForIncident:
    'Não foram encontrados accionadores para este incidente.',
  role: 'Papel',
  viewer: 'Visualizador',
  newOrganization: 'Nova organização',
  noOrganizations: 'Não faz parte de nenhuma organização.',
  maxOrganizationsReached:
    'O utilizador pode fazer parte de um máximo de 10 organizações.',
  yourOrganizations: 'Organizações',
  urlNotFound: 'O URL não corresponde a nenhuma rota suportada.',
  triggerAlert: 'Incidente de desencadeamento',
  recaptchaFailed: 'A verificação reCAPTCHA falhou. {{error}}',
  loadingAccountInformation: 'Carregar informações da conta',
  loadingAuthInfo: 'Carregar informações de autenticação',
  allAlerts: 'Todos os ouvintes',
  recentlyTriggeredAlerts: 'Ouvintes recentemente accionados',
  recentlyClosedIncidents: 'Incidentes encerrados recentemente',
  noRecentlyTriggeredAlerts: 'Nenhum ouvinte foi acionado recentemente.',
  noRecentlyClosedIncidents: 'Nenhum incidente foi encerrado recentemente.',
  closedAt: 'Fechado em {{dateTimeString}}',
  dashboard: 'Painel de controlo',
  loggedIntoOrganizationAccount:
    '(organização: {{organizationName}}, função: {{role}})',
  viewIncidentEscalation: 'Ver Escalonamento de incidentes',
  closeIncident: 'Incidente próximo',
  closingIncident: 'Incidente de encerramento',
  unableToRefreshEnterpriseAccount: 'Não é possível atualizar a sua conta.',
  viewAuthToken: 'Ver token de autenticação',
  problemWithCreatingAuthToken:
    'Ocorreu um problema ao criar um token de autenticação. Contacte o apoio técnico.',
  authToken: 'Token de autenticação',
  authTokenWarningMessage:
    'Mantenha este token de autenticação sempre seguro. Não o partilhe com ninguém. Se você acha que sua chave de API foi comprometida, toque no botão "Alterar" acima para alterá-la. Depois de alterar o token, você precisará especificar o novo token em todos os locais em que estiver acionando incidentes usando a API REST',
  authTokenCopiedToClipboard:
    'O token de autenticação foi copiado para a área de transferência.',
  resendVerificationEmail: 'Reenviar e-mail de verificação',
  loggedInUsingEmail:
    'Iniciou sessão utilizando o correio eletrónico {{email}}.',
  checkingIfMemberIsAppUser:
    'Verificar se o membro é um utilizador da aplicação.',
  settingMemberDetails: 'Definir os detalhes do membro.',
  addingTeam: 'Adicionar equipa.',
  editingTeam: 'Equipa de edição.',
  addingRotation: 'Adicionar rotação.',
  editingRotation: 'Editar a rotação.',
  assigningKeyToMember: 'Atribuição de chave a membro.',
  revokingKeyFromMember: 'Revogação da chave do membro.',
  deletingTeam: 'Eliminar a equipa.',
  deletingRotation: 'Eliminar a rotação.',
  addingAlert: 'Adicionar ouvinte.',
  editingAlert: 'Ouvinte de edição.',
  deletingAlert: 'Eliminar o ouvinte.',
  triggeringAlert: 'Incidente de desencadeamento.',
  creatingOrganizationAccount: 'Criar uma conta de organização.',
  creatingCheckoutSession: 'Iniciar a sua sessão de checkout.',
  creatingAnAccountForMember: 'Criar uma conta para membro.',
  passwordResetEmailSent:
    'Enviámos-lhe um e-mail para redefinir a sua palavra-passe. Altere a sua palavra-passe e inicie sessão novamente.',
  changeOwner: 'Mudar de proprietário',
  changeOrganizationOwner: 'Alterar o proprietário da organização',
  newOwnerEmail: 'Email do novo proprietário',
  saveOnYearlySubscription: 'Guardar {{percent}}%',
  annual: 'Anual',
  resetAlarms: 'Atualizar alarmes',
  resetAlarmsAlert:
    'Todos os alarmes foram actualizados. Se continuar a observar problemas com os alarmes, envie um bilhete utilizando a opção "Comunicar um problema".',
  resetAlarmsTooltipMessage:
    'Se estiver a ter problemas com alarmes, como alarmes eliminados que tocam no telefone, actualize os alarmes utilizando esta opção. Se o problema persistir, envie um bilhete utilizando a opção "Comunicar um problema".',
  resetTempPassword: 'Repor a palavra-passe temporária',
  currPassword: 'Palavra-passe atual',
  tempPassword: 'Palavra-passe temporária',
  newPassword: 'Nova palavra-passe',
  reset: 'Reiniciar',
  loggingIn: 'Iniciar sessão',
  resettingTempPassword: 'Repor a palavra-passe temporária',
  changingPassword: 'Alterar a palavra-passe',
  numSeats: 'Número de lugares',
  helpMeDecide: 'Ajuda-me a decidir',
  numEnterpriseSeatsHelper:
    'Deve escolher o número de lugares como o número de pessoas na sua organização que irão utilizar a aplicação para receber incidentes. Ao configurar um ouvinte, você pode especificar sua política de escalonamento. É possível ter vários níveis em uma política de escalonamento. Por exemplo, pode especificar que o primeiro nível de escalonamento é para uma equipa de 3 pessoas. Se o primeiro nível de escalonamento não responder no prazo de 5 minutos, o segundo nível de escalonamento é dirigido a uma equipa de 5 pessoas. Neste caso, pode especificar o número de lugares como 8.',
  numBusinessSeatsHelper:
    'Escolha o número de lugares para corresponder ao número de pessoas que irão utilizar o Galarm para receber alarmes e lembretes.',
  perSeatPrice: '{{price}} por {{interval}} por lugar',
  doneForToday: 'Reconhecido',
  getStarted: 'Começar a trabalhar',
  firstAlertInstructions: 'Instruções para o primeiro ouvinte',
  learnMoreAboutGalarmEnterprise: 'Saiba mais sobre o Galarm Pro',
  home: 'Início',
  team: 'Equipa',
  alert: 'Alerta',
  listener: 'Ouvinte',
  forgotPassword: 'Esqueceu-se da palavra-passe?',
  forgotPasswordTitle: 'Esqueci-me da palavra-passe',
  sendPasswordResetEmail: 'Enviar e-mail de redefinição de senha',
  sendingPasswordResetEmail: 'Enviar e-mail de reposição da palavra-passe',
  field: 'Campo',
  description: 'Descrição',
  requestIntegration: 'Solicitar outra integração',
  integrationDetails: 'Detalhes da integração',
  enterIntegrationDetails: 'Fornecer pormenores de integração',
  galarmEnterpriseFreeTrial:
    'Inscrever-se para uma avaliação gratuita de 14 dias',
  galarmEnterpriseFreeTrialDescription: 'Automatizar a gestão de incidentes',
  bySigningUpYouAgreeToOur:
    'Ao inscrever-se, o utilizador concorda com a nossa',
  and: 'e',
  alarmSubscribers: 'Seguidores do alarme',
  otherSubscribersCount: {
    one: 'Mais 1 seguidor que não está nos seus contactos',
    other: '{{count}} mais seguidores que não estão nos seus contactos'
  },
  getAccountData: 'Exportar dados de alarme',
  getAccountDataPrompt:
    'Indique um endereço de correio eletrónico para o qual enviaremos um ficheiro de texto com todos os seus alarmes.',
  getAccountDataConfirmation:
    'Tenha em atenção que lhe enviaremos por correio eletrónico um ficheiro de texto com todos os seus alarmes para {{email}}.',
  sendingAccountData: 'Exportar dados de alarme...',
  sentAccountData: 'Dados de alarme enviados para {{email}}.',
  errorSendingAccountData: 'Erro ao exportar dados de alarme: {{error}}',
  sendingEmailVerificationLink:
    'Envio da ligação de verificação por correio eletrónico',
  viewLogs: 'Ver registos',
  message: 'Mensagem',
  billing: 'Faturação',
  newIncidentForAlert: 'Novo incidente para o ouvinte {{alertName}}',
  clickNextAfterAddingMembers:
    'Clique em "Seguinte" depois de adicionar membros',
  clickDoneAfterAddingAlerts:
    'Clique em "Done" (Concluído) para concluir a configuração e aceder ao Dashboard (Painel de controlo).',
  alertCreatedSuccessfully:
    'O ouvinte foi criado com êxito. Pode ativar este ouvinte a partir do ícone do menu de três pontos em frente do nome do ouvinte.',
  fixSubscription: 'Fixar subscrição',
  fixSubscriptionDescription:
    'Detectámos um problema com a sua subscrição. A sua subscrição contém {{numSeats}} lugar(es) mas atribuiu chaves a {{numSeatsAssigned}} membro(s). Pode atribuir no máximo {{numSeats}} chave(s).',
  fixSubscriptionChooseOption:
    'Corrija a sua subscrição utilizando uma das opções abaixo.',
  fixSubscriptionContactAdmin: 'Contacte o administrador da sua organização.',
  unassignKeysFromMembers:
    'Anular a atribuição de, pelo menos, {{extraKeysAssigned}} chave(s) dos membros',
  addMoreSeatsToSubscription:
    'Adicione pelo menos {{extraKeysAssigned}} lugar(es) à sua subscrição',
  revokeMembersKey: 'Revogar a chave dos deputados',
  revokeMembersKeyDescription:
    'Escolha os membros cujas chaves gostaria de revogar.',
  revoke: 'Revogar',
  unableToRevokeMembersKey:
    'Ocorreu um erro ao revogar a chave dos membros. Contacte a assistência.',
  problemWithLoadingProducts:
    'Problema com o carregamento de produtos. {{error}}',
  verificationCompleted: 'Verificação concluída',
  deviceAlreadyRegistered:
    'Este número de telefone já foi verificado anteriormente neste dispositivo.',
  phoneNumberAutoFilled:
    'O Galarm utilizará o número de telefone selecionado da sua conta Google.',
  unableToRegisterPreVerifiedUser:
    'Não foi possível registar o utilizador pré-verificado.',
  allowNotifications: 'Permitir notificações',
  allowNotificationsDescription:
    'Para visualizar os alarmes quando estes tocam.',
  scheduleExactAlarms: 'Programar alarmes',
  scheduleExactAlarmsHelperText:
    'Permitir que o Galarm toque os alarmes à hora exacta.',
  scheduleExactAlarmsDescription: 'Para tocar os alarmes à hora exacta.',
  disableBatteryOptimization: 'Gerir a otimização da bateria',
  disableBatteryOptimizationDescription:
    'Para permitir que o Galarm seja executado em segundo plano.',
  requestRequiredPermissions:
    'O Galarm requer as seguintes permissões para tocar alarmes',
  allowDisallowedPermissions: 'Toque para permitir',
  notificationsAreEnabled:
    'A permissão para mostrar notificações já é permitida.',
  connectingToStore: 'Ligação à loja...',
  restartApp: 'Reiniciar a aplicação',
  updateAvailable: 'Atualização disponível',
  english: 'Inglês',
  russian: 'Russo',
  german: 'alemão',
  french: 'francês',
  spanish: 'espanhol',
  italian: 'italiano',
  portuguese: 'Português',
  japanese: 'Japonês',
  korean: 'coreano',
  indonesian: 'indonésio',
  changeAppLanguage: 'Alterar o idioma da aplicação',
  appLanguage: 'Idioma da aplicação',
  iosChangeAppLanguagePara1:
    'O Galarm escolhe o idioma da aplicação a partir das definições do seu telemóvel. Siga estes passos para alterar o idioma da aplicação do Galarm:',
  iosChangeAppLanguageInstruction1:
    'Abra "Definições do telemóvel > Geral > Idioma e região". Certifique-se de que o idioma que pretende utilizar para o Galarm está presente na lista de idiomas preferidos.',
  iosChangeAppLanguageInstruction2:
    'Volte às "Definições do telefone" e toque em "Galarm" na lista de aplicações na parte inferior.',
  iosChangeAppLanguageInstruction3:
    'Toque em "Idioma" e seleccione o idioma que pretende definir para o Galarm. Voltar à aplicação depois de escolher o idioma',
  androidChangeAppLanguagePara1:
    'O Galarm escolhe o idioma da aplicação a partir das definições do seu telemóvel. Siga estes passos para alterar o idioma da aplicação do Galarm:',
  androidChangeAppLanguageInstruction1:
    'Toque no botão "Abrir definições da aplicação" abaixo.',
  androidChangeAppLanguageInstruction2:
    'Toque em "Idioma" e seleccione o idioma que pretende utilizar no Galarm.',
  androidChangeAppLanguageInstruction3:
    'Depois de escolher o idioma, volte aqui e clique no botão "Reiniciar a aplicação" abaixo.',
  problemRetrievingSubscriptionData:
    'Houve um problema ao recuperar as suas informações de subscrição. Estamos a encaminhá-lo para a página da conta. Verifique o estado da sua subscrição e contacte o apoio técnico se continuar a ter problemas.',
  deletingOrganization: 'Eliminar a organização...',
  deleteOrganization: 'Eliminar organização',
  deleteOrganizationDescription:
    'Tem a certeza de que pretende eliminar a sua organização?\n  \n  Esta é uma operação irreversível. Todos os dados da sua organização serão eliminados.\n  \n  Certifique-se de que cancelou a sua subscrição antes de eliminar a organização.',
  galarmEnterpriseTrialDescription:
    'Existe um período experimental gratuito de 14 dias durante o qual pode cancelar a subscrição sem qualquer encargo.',
  galarmEnterpriseTrialAlreadyAvailed:
    'Já utilizou a avaliação gratuita de 14 dias. Contacte o suporte se precisar de mais tempo para avaliar o produto.',
  signinWithGoogle: 'Iniciar sessão com o Google',
  signinWithGithub: 'Iniciar sessão com o GitHub',
  verifyAccount: 'Verificar conta',
  verifyAccountMessage:
    'Deverá ter recebido um e-mail com um código de verificação. Introduza o código abaixo para verificar a sua conta. Se não tiver recebido o código, pode pedir um novo código clicando no botão "Reenviar código".',
  verificationCode: 'Código de verificação',
  optionalString: '{{string}}',
  quickReminderTitle: 'Título (facultativo)',
  accountVerified: 'Conta verificada',
  accountVerifiedMessage: 'Inicie sessão utilizando as suas credenciais.',
  multipleUsersWithSameEmail:
    'Existem vários utilizadores com o mesmo e-mail. Contacte a assistência.',
  multipleUsersWithSameMobileNumber:
    'Existem vários utilizadores com o mesmo número de telemóvel. Contacte a assistência.',
  startTrial: 'Iniciar o ensaio',
  nextSteps: 'Próximos passos',
  firstTimeSetupComplete:
    'Concluiu a configuração inicial do Galarm Enterprise e criou o seu primeiro ouvinte. Seguem-se alguns passos seguintes que pode seguir a partir daqui.',
  testTheAlertInstruction:
    'Consulte as instruções em {{link}} para saber como testar o ouvinte que acabou de criar.',
  integrateTheAlertInstruction:
    'Consulte as instruções em {{link}} para integrar o ouvinte com várias ferramentas de monitorização.',
  createMoreAlertsInstruction:
    'Pode adicionar mais ouvintes acedendo ao separador "Ouvintes" à esquerda.',
  addMoreMembersInstruction:
    'Pode adicionar mais membros acedendo ao separador "Membros" à esquerda.',
  createTeamsInstruction:
    'Pode agrupar membros em "Equipas" e alertar todas as equipas quando ocorre um incidente. Crie equipas no separador "Equipas" à esquerda e utilize-as na configuração do ouvinte.',
  testTheAlert: 'Testar o ouvinte',
  integrateTheAlert: 'Integrar o ouvinte',
  createMoreAlerts: 'Criar mais ouvintes',
  addMoreMembers: 'Adicionar mais membros',
  createTeams: 'Criar equipas',
  contactSupport: 'Apoio ao contacto',
  pastDue: 'Vencido',
  canceled: 'Cancelado',
  unpaid: 'Não pago',
  incomplete: 'Incompleto',
  incomplete_expired: 'Incompleto Expirado',
  trialing: 'Teste gratuito',
  paused: 'Em pausa',
  plan: 'Plano',
  status: 'Estado',
  renewsOn: 'Renova em',
  yourRoles: 'As suas funções',
  enterprisePlan: '{{quantity}} lugares, renova cada {{interval}}',
  currentOrganization: 'Organização atual',
  useThisOrganization: 'Utilizar esta organização',
  fixPayment: 'Fixar pagamento',
  fixingPayment: 'Fixação do pagamento',
  addPaymentInformation: 'Adicionar informações de pagamento',
  noPaymentInformationAvailable:
    'Não existem informações de pagamento disponíveis. Actualize a página se tiver adicionado recentemente informações de pagamento.',
  openBillingPortal: 'Portal de faturação aberto',
  startupWizardDescription:
    'Este assistente ajudá-lo-á a começar e a criar o seu primeiro ouvinte.',
  userNotFound: 'Utilizador não encontrado',
  wrongPassword: 'Palavra-passe errada',
  emailAlreadyInUse: 'Correio eletrónico já utilizado',
  restoreDeletedAlarms: 'Restaurar alarmes eliminados',
  deleteAlarmAlert:
    'Os alarmes eliminados podem ser restaurados a partir de "Alarmes recentemente eliminados". Pode vê-los tocando no menu de três pontos (⋮) no canto superior direito do separador "Alarmes".\n\nOs alarmes eliminados permanecem aí durante 30 dias, após os quais são permanentemente eliminados.',
  slackVerificationCode:
    'O seu Código de Verificação do Slack é {{verificationCode}}.',
  dndAccess: 'Acesso ao Não Perturbe',
  dndHelperText:
    'Permitir que o Galarm toque os alarmes quando o telefone está em Não incomodar.',
  cantRestoreInstantAlarmMessage:
    'Os alarmes instantâneos não podem ser restaurados. Se necessário, pode ver o chat e outras informações relativas a este alarme. Este alarme será removido permanentemente após 30 dias desde a sua eliminação.',
  upgradeToUnlockAllFeatures:
    'Atualização para desbloquear todas as funcionalidades',
  otherSignupOptions: 'Outras opções de registo:',
  renewSubscription: 'Renovar a subscrição',
  subscriptionOnHold:
    'Em espera (O seu plano permite {{subscriptionQty}} lugares\nmas atribuiu {{numSubscriptionKeys}} lugares.',
  checklists: 'Tarefas',
  checklist: 'Lista de tarefas',
  typeToAddTask: 'Tipo para adicionar tarefa',
  specifyTaskName: 'Especificar o nome da tarefa',
  otherChecklists: 'Listas de tarefas',
  defaultChecklistName: 'sem título',
  addTasksToChecklist:
    'Adicionar tarefas utilizando a caixa de entrada na parte inferior',
  addTasksToDefaultChecklist:
    'Adicione tarefas utilizando a caixa de entrada na parte inferior... ou inicie uma nova lista de tarefas clicando no botão + no canto superior direito',
  createNewChecklist:
    'Pode criar novas listas de tarefas para manter um registo das tarefas relacionadas num único local.',
  addNewChecklist: 'Nova lista de tarefas',
  alarmPhoto: 'Foto do alarme',
  alarmPhotoFeatureTitle: 'Foto do alarme',
  alarmPhotoFeatureDescriptionPremiumUser:
    'Utilizando esta funcionalidade, pode adicionar uma fotografia a um alarme para obter indicações visuais rápidas, como a fotografia de: um medicamento, instruções para uma tarefa, um item, etc. A fotografia será apresentada quando o alarme tocar.',
  alarmPhotoFeatureDescriptionFreeUser:
    'Utilizando esta funcionalidade, pode adicionar uma fotografia a um alarme para obter indicações visuais rápidas, como a fotografia de: um medicamento, instruções para uma tarefa, um item, etc. A fotografia será apresentada quando o alarme tocar.\n  \nEsta é uma funcionalidade premium. Ser-lhe-á pedido que actualize para a versão premium para poder utilizar esta funcionalidade.',
  alarmPhotoPremiumFeature: 'Adicionar fotografia às notas de alarme',
  installPendingAppUpdate:
    'A última atualização da aplicação foi descarregada e está pronta a ser instalada.',
  numTasks: {
    zero: 'Nenhuma tarefa',
    one: '1 tarefa',
    other: '{{count}} tarefas'
  },
  numTasksWithCompleted: {
    one: '1 tarefa, {{completed}} concluída',
    other: '{{count}} tarefas, {{completed}} concluído'
  },
  muteAlarms: 'Silenciar alarmes',
  unmuteAlarms: 'Ativar o som dos alarmes',
  alarmsAreMuted:
    'Todos os seus alarmes estão atualmente silenciados. Toque em para ativar o som.',
  alarmsHaveBeenMuted:
    'Os seus alarmes foram silenciados. Não tocarão até que os desactive.',
  alarmsHaveBeenUnmuted:
    'Os seus alarmes foram desactivados. Eles devem tocar conforme configurado.',
  deleteChecklist: 'Eliminar lista de tarefas',
  confirmDeleteChecklist: 'Eliminar a lista de tarefas?',
  confirmDeleteCompletedTasks: 'Eliminar tarefas concluídas?',
  confirmResetCompletedTasks: 'Repor tarefas concluídas?',
  confirmDeleteTask: 'Eliminar {{taskName}}?',
  showCompletedTasks: 'Mostrar tarefas concluídas',
  hideCompletedTasks: 'Ocultar tarefas concluídas',
  deleteCompletedTasks: 'Eliminar tarefas concluídas',
  resetCompletedTasks: 'Repor tarefas concluídas',
  yourGroups: 'Os seus grupos',
  newAlarmWith: 'Novo alarme com {{name}}',
  newGroupWith: 'Novo grupo com {{name}}',
  alertContact: 'Alerta {{name}}',
  alertGroup: 'Alerta {{name}}',
  instantAlarmMovedToFabNotice:
    'A funcionalidade Alarme instantâneo está agora disponível a partir do botão "+" no canto inferior direito deste ecrã. O ícone do "altifalante" será removido do canto superior direito numa versão futura.',
  tasksIntroTitle: 'Tarefas e listas de tarefas',
  tasksIntroDescription:
    'As tarefas são os seus afazeres que não têm um prazo. Pode adicioná-las e geri-las rapidamente aqui, marcando-as à medida que as executa.\n\nTambém pode criar listas de tarefas para agrupar tarefas semelhantes. Por exemplo, pode criar uma lista de tarefas para as suas compras de supermercado, outra para as suas tarefas de trabalho e assim por diante.',
  groupsMovedToContactsScreen:
    'Os "Grupos" foram fundidos neste separador. Os seus grupos aparecem na parte superior, em "Os seus grupos". Pode criar novos grupos tocando no botão "+" no canto inferior direito.',
  loadingTasks: 'Carregando tarefas...',
  tryPremium: 'Experimentar o Premium',
  buyPremiumToUnlockFeature:
    'Esta é uma funcionalidade premium. Desbloqueie esta e muitas outras funcionalidades interessantes com o nosso plano premium que inclui uma avaliação gratuita de 14 dias.',
  newTask: 'Nova tarefa',
  newTaskList: 'Nova lista de tarefas',
  enterTaskName: 'Introduzir o nome da tarefa',
  enterTaskListName: 'Introduzir o nome da lista de tarefas',
  taskName: 'Nome da tarefa',
  taskListName: 'Nome da lista de tarefas',
  checklistTasks: '{{name}}',
  editTaskListName: 'Editar o nome da lista de tarefas',
  editTask: 'Editar tarefa',
  currentRelease: '(atualmente instalado)',
  id: 'ID',
  subscriptionData: 'Dados de subscrição',
  organizations: 'Organizações',
  showRotationCalendar: 'Calendário de rotação de espectáculos',
  rotationCalendar: 'Calendário de rotação',
  editShift: 'Mudança de edição',
  startDateForShift: 'Data de início do turno',
  startTimeForShift: 'Hora de início do turno',
  endDateForShift: 'Data de fim do turno',
  endTimeForShift: 'Hora de fim do turno',
  editingShift: 'Turno de edição',
  addingShift: 'Adicionar turno',
  products: 'Produtos',
  prices: 'Planos',
  planType: 'Tipo de plano',
  business: 'Negócios',
  enterprise: 'Empresa',
  compare: 'Comparar',
  apiSupport: 'Suporte API para monitorizar serviços',
  accessToConsoleToViewAndCloseIncidents:
    'Painel de controlo para gerir incidentes',
  triggerInstantAlertsFromTheConsole:
    'Acionar incidentes a partir da Consola Empresarial',
  coreFeatures: 'Características principais:',
  everythingInBusiness: 'Tudo no plano de negócios e:',
  updatePlanInstructions:
    'Pode atualizar o seu plano clicando no botão "Abrir o portal de faturação" à direita e escolhendo o botão "Atualizar plano" no portal de faturação.',
  membersDescription:
    'Comprou {{subscriptionKeysCount}} lugares -- {{assignedSubscriptionKeysCount}} atribuídos e {{unassignedSubscriptionKeysCount}} disponíveis. Uma marca de verificação à frente do nome de um membro indica um lugar atribuído.',
  centralBilling: 'Faturação central',
  saveTwoMonths: 'Guardar 2 meses',
  owner: 'Proprietário',
  enterpriseAccountSummary:
    'Faz parte da organização {{organizationName}} inscrita no plano Galarm Pro {{planType}}.',
  perYearPerUser: 'por utilizador/ano',
  perMonthPerUser: 'por utilizador/mês',
  creationDate: 'Data de criação',
  updatePhoneNumber: 'Alterar o número de telefone',
  confirmCountryCodeAndEnterUpdatedPhoneNumber:
    'Confirme o indicativo do país e introduza o seu novo número de telefone. A sua conta será associada a este número de telefone depois de concluída a verificação.',
  unableToUpdatePhoneNumber:
    'Não é possível alterar o número de telefone: {{error}}. Por favor, contacte a assistência.',
  phoneNumberUpdated: 'Número de telefone alterado com sucesso.',
  phoneNumberUpdatedMessage:
    'Pode começar a utilizar a aplicação normalmente. Por favor, peça aos seus contactos para actualizarem o seu número de telefone na lista de contactos.',
  memberChangedPhoneNumber:
    '{{memberName}} mudou o seu número de telefone. Actualize o seu livro de endereços para continuar a adicioná-los aos seus alarmes.',
  preferredSourceForIncidents: 'Fonte preferida para incidentes',
  apiCodesDescription:
    'Utilize as seguintes instruções para integrar o ouvinte com uma fonte',
  rotation: 'Rotação',
  noAlarmsForThisDay: 'Não há alarmes para este dia',
  markAlarmsAsComplete: 'Marcar alarmes como concluídos',
  markAlarmsAsCompleteExplanation:
    'Os alarmes únicos serão marcados como concluídos e aparecerão na secção de alarmes "Inactivos".\n\nOs alarmes repetidos serão marcados como concluídos e serão movidos para a ocorrência seguinte.',
  enterIncidentPrefix: 'Máximo de 5 caracteres',
  incidentPrefix: 'Prefixo do incidente',
  incidentPrefixAlreadyExists: 'O prefixo do incidente já existe',
  removeTask: 'Remover tarefa?',
  incidentPrefixLengthExceeded:
    'O prefixo do incidente tem mais de 5 caracteres',
  criticalAlerts: 'Alertas críticos',
  criticalAlertsConfiguration:
    'O alarme toca em {{criticalAlertsVolume}}% do volume, mesmo quando o telefone está silenciado ou quando o Focus está ligado.',
  criticalAlertsDisabledShort:
    'O alarme não tocará quando o telemóvel estiver silenciado.',
  criticalAlertsConfigurationGlobal:
    'Os alarmes tocam em {{criticalAlertsVolume}}% do volume, mesmo quando o telefone está no modo silencioso ou o Focus está ligado.',
  criticalAlertsDisabledShortGlobal:
    'Os alarmes não tocarão quando o telefone estiver silenciado.',
  criticalAlertsEnabled:
    'Os alertas críticos estão activados. Os alarmes tocam mesmo quando o telemóvel está no modo silencioso ou quando o Focus está ligado.',
  criticalAlertsDisabled:
    'Os alertas críticos estão desactivados. Os alarmes não tocarão quando o telemóvel estiver silenciado.',
  enableCriticalAlerts: 'Ativar alertas críticos',
  summer_soft: 'verão suave',
  beeps: 'Bips',
  bollywood_nights: 'Noites de Bollywood',
  gentle_reminder: 'Lembrete suave',
  himalayan_piper: 'Piper dos Himalaias',
  holiday_joy: 'Alegria das férias',
  hollywood_adventure: 'Aventura em Hollywood',
  melody: 'Melodia',
  morning_rooster: 'Galo da manhã',
  morning_sunshine: 'Sol da manhã',
  sweet_sea: 'Mar Doce',
  old_school_ring: 'Tring Tring',
  MyAlarmNotification: '{{alarmName}} - Está na altura.',
  ParticipantCascadingAlarmNotification:
    '{{alarmName}} - É altura de recordar {{alarmCreatorName}}.',
  ParticipantSimultaneousAlarmNotification:
    '{{alarmName}}. Chegou o momento de confirmar a sua participação.',
  InstantAlarmNotification:
    '{{alarmName}}. Solicita-se a vossa atenção imediata.',
  MyAlarmNotificationShort: 'Está na altura.',
  ParticipantCascadingAlarmNotificationShort:
    'É altura de recordar {{alarmCreatorName}}.',
  ParticipantSimultaneousAlarmNotificationShort:
    'É altura de confirmar a sua participação.',
  InstantAlarmNotificationShort: 'Solicita-se a vossa atenção imediata.',
  MyRecipientAlarmNotification:
    '{{alarmName}} - É altura de recordar {{alarmRecipientName}}.',
  MyRecipientAlarmNotificationShort:
    'É altura de recordar {{alarmRecipientName}}.',
  RecipientAlarmNotification:
    '{{alarmName}} (por {{alarmCreatorName}}) - Está na altura.',
  RecipientAlarmNotificationShort: 'Está na altura - por {{alarmCreatorName}}.'
}
