import React from 'react'
import { TextInput } from 'grommet'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import moment from 'moment'
import MomentUtils from '@date-io/moment'
import { I18n } from 'galarm-config'

const DateInput = ({ value, onChange, ...dateInputProps }) => {
  const format = 'D MMM YYYY'
  const dateValue = value ? moment(value) : null

  const onTimeChange = date => {
    onChange(date.toDate())
  }
  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <DatePicker
        value={dateValue}
        onChange={onTimeChange}
        format={format}
        TextFieldComponent={props => (
          <TextInput {...props} placeholder={I18n.t('chooseDate')} />
        )}
        {...dateInputProps}
      />
    </MuiPickersUtilsProvider>
  )
}

export default DateInput
