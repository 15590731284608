import React, { useEffect, useState } from 'react'
import { List, Box } from 'grommet'
import md5 from 'md5'
import LoadEarlier from './LoadEarlier'
import Message from './Message'

const MessageContainer = props => {
  const scrollToBottomRef = React.useRef(null)

  const [messagesData, setMessageData] = useState([])

  const prepareMessages = messages =>
    messages.map((m, i) => {
      const previousMessage = messages[i + 1] || {}
      const nextMessage = messages[i - 1] || {}
      // add next and previous messages to hash to ensure updates
      const toHash = JSON.stringify(m) + previousMessage._id + nextMessage._id
      return {
        ...m,
        previousMessage,
        nextMessage,
        hash: md5(toHash)
      }
    })

  useEffect(() => {
    const messagesData = prepareMessages(props.messages)
    setMessageData(messagesData)
  }, [props.messages])

  useEffect(() => {
    scrollToBottom()
  }, [messagesData])

  const renderLoadEarlier = () => {
    if (props.loadEarlier === true) {
      const loadEarlierProps = {
        ...props
      }
      if (props.renderLoadEarlier) {
        return props.renderLoadEarlier(loadEarlierProps)
      }
      return <LoadEarlier {...loadEarlierProps} />
    }
    return null
  }

  const messageSummary = item => {
    const messageProps = {
      ...props,
      key: item._id,
      currentMessage: item,
      previousMessage: item.previousMessage,
      nextMessage: item.nextMessage,
      position: item.user._id === props.user._id ? 'right' : 'left'
    }
    return <Message {...messageProps} style={{ color: 'textColor' }} />
  }

  const scrollToBottom = () => {
    scrollToBottomRef.current?.scrollIntoView({ behavior: 'smooth' })
  }

  return (
    <Box overflow="auto">
      {renderLoadEarlier()}
      <List
        pad="xsmall"
        border={false}
        step={20}
        {...props.listViewProps}
        data={messagesData.sort((a, b) => a.createdAt - b.createdAt)}>
        {messageSummary}
      </List>
      <div ref={scrollToBottomRef} />
    </Box>
  )
}

export default MessageContainer
