import React from 'react'
import { Box, Text, Menu, CheckBox } from 'grommet'
import { Constants, I18n } from 'galarm-config'
import HoverBox from './HoverBox'
import { useSelector } from 'react-redux'
import { DateTimeUtils } from 'galarm-shared'

const ConfigureAutoSnooze = ({
  ringerSettings,
  onSaveRingerSettings,
  isGlobal
}) => {
  const deviceOs = useSelector(state => state.userInfo.deviceOs)

  const onAutoSnoozeChange = event => {
    onSaveRingerSettings({
      ...ringerSettings,
      autoSnooze: event.target.checked
    })
  }

  const onAutoSnoozeDurationChange = item => {
    onSaveRingerSettings({
      ...ringerSettings,
      autoSnoozeDuration: item.value
    })
  }

  const onAutoSnoozeCountChange = item => {
    onSaveRingerSettings({
      ...ringerSettings,
      autoSnoozeCount: item.value
    })
  }

  const autoSnoozeDurations = Constants.AutoSnoozeDurations.map(item => ({
    label: item.label,
    onClick: () => onAutoSnoozeDurationChange(item)
  }))

  const autoSnoozeCounts = Constants.AutoSnoozeCounts.map(item => ({
    label: item.label,
    onClick: () => onAutoSnoozeCountChange(item)
  }))

  const autoSnoozeCountLabel = Constants.AutoSnoozeCounts.find(obj => {
    return obj.value === ringerSettings.autoSnoozeCount
  }).label

  const autoSnoozeDuration = Constants.AutoSnoozeDurations.find(obj => {
    return obj.value === ringerSettings.autoSnoozeDuration
  })

  const autoSnoozeDurationLabel = autoSnoozeDuration
    ? autoSnoozeDuration.label
    : DateTimeUtils.getDurationAsString(ringerSettings.autoSnoozeDuration)

  if (!autoSnoozeDurationLabel) {
    autoSnoozeDurations.push({
      label: DateTimeUtils.getDurationAsString(
        ringerSettings.autoSnoozeDuration
      ),
      onClick: () =>
        onAutoSnoozeCountChange({
          label: DateTimeUtils.getDurationAsString(
            ringerSettings.autoSnoozeDuration
          ),
          value: ringerSettings.autoSnoozeDuration
        })
    })
  }

  return (
    <Box width={'400px'}>
      <HoverBox>
        <Text>
          {isGlobal ? I18n.t('autoSnooze') : I18n.t('autoSnoozePerAlarm')}
        </Text>
        <Box>
          <CheckBox
            toggle={true}
            checked={ringerSettings.autoSnooze}
            onChange={onAutoSnoozeChange}
          />
        </Box>
      </HoverBox>
      <HoverBox>
        <Text>{I18n.t('duration')}</Text>
        <Menu
          dropBackground="lightTint"
          label={autoSnoozeDurationLabel}
          items={autoSnoozeDurations}
        />
      </HoverBox>
      {
        // Only allow user to choose count for Android. For iOS, it is always 2
        deviceOs === 'android' && (
          <HoverBox>
            <Text>{I18n.t('repeat')}</Text>
            <Menu
              dropBackground="lightTint"
              label={autoSnoozeCountLabel}
              items={autoSnoozeCounts}
            />
          </HoverBox>
        )
      }
    </Box>
  )
}

export default ConfigureAutoSnooze
