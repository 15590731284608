import 'firebase/compat/app'
import 'firebase/compat/database'
import 'firebase/compat/storage'
import 'firebase/compat/auth'
import 'firebase/compat/analytics'
import 'firebase/compat/functions'
import 'firebase/compat/messaging'
import 'firebase/compat/remote-config'

import React from 'react'
import { createRoot } from 'react-dom/client'
import { GlobalConfig } from 'galarm-config'
import { Provider } from 'react-redux'
import configureStore, {
  purgeStateIfReducerVersionChanged
} from './store/configureStore'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { ReactNotifications } from 'react-notifications-component'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import BuildMetadata from './buildMetadata.json'

import 'react-notifications-component/dist/theme.css'

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0
})

const { store, persistor } = configureStore()
GlobalConfig.store = store
GlobalConfig.persistor = persistor
purgeStateIfReducerVersionChanged(persistor)

const { majorVersion, minorVersion, patchVersion } = BuildMetadata
const release = `${majorVersion}.${minorVersion}.${patchVersion}`
GlobalConfig.release = release

const rootContainer = document.getElementById('root')
const root = createRoot(rootContainer)
root.render(
  <Provider store={store}>
    <ReactNotifications />
    <App />
  </Provider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
