import React, { useEffect, useRef } from 'react'
import { Box, Text } from 'grommet'
import { I18n, Constants } from 'galarm-config'
import objGet from 'lodash/get'
import GroupAvatar from './GroupAvatar'
import { StringUtils, Utils, Selectors } from 'galarm-shared'
import { useSelector } from 'react-redux'
import { Close } from 'grommet-icons'
import BadgeProvider from './BadgeProvider'
import ContactAvatar from './ContactAvatar'
import { SecondaryText } from 'web-components'

const { sortedContactListSelector, sortedGroupListSelector } = Selectors

const ParticipantAvatarWithCross = ({ item, onClickCross }) => {
  const resolvedName =
    item.known === false
      ? `~` + StringUtils.getFirstName(item.name)
      : StringUtils.getFirstName(item.name)

  const contacts = useSelector(state => sortedContactListSelector(state))

  // We need to find the contact object so that we can find the thumbnail url
  const contact = Utils.getObjectWithId(contacts, item.id)

  const avatarThumbnailUrl = objGet(
    contact,
    'images.avatarThumbnailUrl',
    undefined
  )

  const avatarSource = avatarThumbnailUrl ? avatarThumbnailUrl : undefined

  const badge = <Close color="white" size="small" />

  return (
    <Box flex={{ shrink: 0 }} pad="small" align="start" gap="xsmall">
      <BadgeProvider
        badge={badge}
        onClick={() => onClickCross(item.id)}
        location="bottomRight">
        <ContactAvatar url={avatarSource} name={item.name} />
      </BadgeProvider>
      <SecondaryText pad="small" textAlign="center">
        {resolvedName}
      </SecondaryText>
    </Box>
  )
}

const SelectedParticipants = ({
  backupGroup,
  backupContacts,
  type, // eslint-disable-line no-unused-vars
  onClickCross
}) => {
  const scrollToRightRef = useRef(null)

  const groups = useSelector(state => sortedGroupListSelector(state))

  useEffect(() => {
    scrollToRightRef.current?.scrollIntoView({
      behavior: 'smooth'
    })
  })

  // If no group or contacts is selected then show a message
  if (backupGroup === null && backupContacts.length === 0) {
    return (
      <Box pad={{ left: 'small', top: 'small' }} flex={{ shrink: 0 }}>
        <Text>
          {type === Constants.AlarmTypes.CASCADING
            ? I18n.t('selectParticipantsForPersonalAlarm')
            : I18n.t('selectParticipantsForGroupAlarm')}
        </Text>
      </Box>
    )
  }
  // If a group is selected, just show the group avatar and the number of participants
  else if (backupGroup) {
    // We need to find the group so that we can find the thumbnail url
    const group = Utils.getObjectWithId(groups, backupGroup.id)
    if (!group) {
      return undefined
    }

    const avatarThumbnailUrl = objGet(
      group,
      'images.avatarThumbnailUrl',
      undefined
    )

    const avatarSource = avatarThumbnailUrl ? avatarThumbnailUrl : undefined

    const numMembers = backupGroup.members.length
    const numActiveMembers = backupGroup.members.filter(
      member => member.state !== Constants.ParticipantStates.INACTIVE
    ).length
    const numInactiveMembers = numMembers - numActiveMembers
    const participantString =
      numMembers === 0
        ? I18n.t('noMembers')
        : I18n.t('selectedGroupDescription', {
            numActiveMembers,
            numInactiveMembers,
            count: numInactiveMembers
          })

    return (
      <Box gap="xsmall" pad={{ left: 'small', top: 'small' }}>
        <GroupAvatar url={avatarSource} name={backupGroup.name} />
        <SecondaryText margin="small">{participantString}</SecondaryText>
      </Box>
    )
  } else if (backupContacts.length > 0) {
    // If backup contacts are selected, show a contact avatar with a cross and the
    // name of the contact
    return (
      <Box direction="row" overflow={{ horizontal: 'auto' }}>
        {backupContacts.map((contact, index) => {
          return (
            <ParticipantAvatarWithCross
              key={index}
              item={contact}
              onClickCross={onClickCross}
            />
          )
        })}
        <div ref={scrollToRightRef} />
      </Box>
    )
  }
  return null
}

export default SelectedParticipants
