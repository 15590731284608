import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Box, Text, Button, List } from 'grommet'
import DateInput from './DateInput'
import TimeInput from './TimeInput'
import { Add, FormSubtract } from 'grommet-icons'
import { colorThemes, I18n, Constants } from 'galarm-config'
import { DateTimeUtils } from 'galarm-shared'
import { NavigationUtils } from 'galarm-ps-api'
import moment from 'moment-timezone'
import uuid from 'react-native-uuid'
import arrUniqBy from 'lodash/uniqBy'
import * as RNLocalize from 'react-native-localize'

const OccurrenceSummary = ({
  id,
  date,
  removeOccurrence,
  onDateChange,
  index
}) => {
  const colorScheme = useSelector(state => state.userSettings.colorScheme)

  return (
    <Box
      direction="row"
      align="center"
      justify="between"
      round="small"
      gap="xsmall">
      <DateInput
        value={date ? new Date(date) : null}
        onChange={newDate => onDateChange(newDate, id)}
      />
      <TimeInput
        value={date ? new Date(date).getTime() : null}
        onChange={newDate => onDateChange(newDate, id)}
      />
      {index > 1 && (
        <Button
          icon={<FormSubtract color="white" />}
          onClick={() => removeOccurrence(id)}
          plain
          style={{
            backgroundColor: colorThemes.getColorTheme(colorScheme).primary,
            borderRadius: '50%',
            padding: '5px'
          }}
        />
      )}
    </Box>
  )
}

const SelectSpecificDatesRepetition = props => {
  const [occurrences, setOccurrences] = useState(
    props.repeat.split(',').map(dateString => ({
      id: uuid.v4(),
      date: moment(dateString).valueOf()
    }))
  )

  useEffect(() => {
    onSaveRepetition()
  }, [occurrences])

  const onSaveRepetition = () => {
    const validOccurrences = occurrences.filter(o => o.date)
    const uniqueOccurrences = arrUniqBy(validOccurrences, item => item.date)

    if (uniqueOccurrences.length === 0) {
      NavigationUtils.showAlert(I18n.t('chooseAtLeastOneOccurrence'))
      return
    }

    uniqueOccurrences.sort((a, b) => a.date - b.date)

    const repeatString = uniqueOccurrences
      .map(o =>
        DateTimeUtils.getFormattedDateAsStringInTimezone(
          o.date,
          RNLocalize.getTimeZone()
        )
      )
      .join(',')

    props.onSaveAlarmRepetition(
      Constants.RepeatTypes.SPECIFIC_DATES,
      repeatString
    )
  }

  const addOccurrence = () => {
    setOccurrences(prevOccurrences => {
      // Create the new occurrence
      const newOccurrence = {
        id: uuid.v4(),
        startDate: prevOccurrences[prevOccurrences.length - 1].date
      }

      // Insert the new occurrence after the found index
      const updatedOccurrences = [...prevOccurrences, newOccurrence]

      return updatedOccurrences
    })
  }

  const removeOccurrence = id => {
    setOccurrences(prevOccurrences => prevOccurrences.filter(o => o.id !== id))
  }

  const onDateChange = (newDate, id) => {
    // The date is passed as a timestamp value - so we can use it as it is
    setOccurrences(prevOccurrences =>
      prevOccurrences.map(o =>
        o.id === id
          ? {
              ...o,
              date: newDate
            }
          : o
      )
    )
  }

  return (
    <Box overflow="auto">
      <Text margin={'small'}>{I18n.t('addOrRemoveAlarmOccurrences')}</Text>
      <Button
        icon={<Add />}
        label={I18n.t('addOccurrence')}
        onClick={addOccurrence}
        margin={'small'}
        alignSelf="center"
      />
      <List data={occurrences} border={false}>
        {({ id, date }) => (
          <OccurrenceSummary
            id={id}
            date={date}
            removeOccurrence={removeOccurrence}
            onDateChange={onDateChange}
            index={occurrences.length}
          />
        )}
      </List>
    </Box>
  )
}

export default SelectSpecificDatesRepetition
