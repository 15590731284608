import React, { Component, createRef } from 'react'
import { I18n } from 'galarm-config'
import { Constants } from 'galarm-config'
import { colorThemes } from 'galarm-config'
import { connect } from 'react-redux'
import { FirebaseProxy } from 'galarm-ps-api'
import moment from 'moment-timezone'
import { AlarmUtils, DateTimeUtils } from 'galarm-shared'
import isEmpty from 'lodash/isEmpty'
import { Utils } from 'galarm-shared'
import { Box, Text, TextInput } from 'grommet'
import { Checkmark } from 'grommet-icons'
import { SecondaryText } from 'web-components'

class SelectYearsRepetition extends Component {
  static defaultProps = {
    repeat: '2'
  }

  constructor(props) {
    super(props)
    this.everyNYearsRef = createRef()
  }

  state = {
    repeat: this.props.repeat
  }

  onChooseYears = () => {
    this.everyNYearsRef.current?.focus()
    this.setState(
      {
        repeat: '2'
      },
      () => {
        if (this.state.repeat) {
          this.props.onSaveAlarmRepetition(
            Constants.RepeatTypes.YEARS,
            this.state.repeat
          )
          FirebaseProxy.logEvent(
            Constants.UserAnalyticsEvents.CHOOSE_YEARLY_REPEAT_TYPE,
            {
              [Constants.UserAnalyticsEventParameters.REPEAT_STRING]:
                Constants.REPEAT_EVERY_N_YEARS_REPEAT_STRING
            }
          )
        }
      }
    )
  }

  onChangeYears = event => {
    const newYearsInt = parseInt(event.target.value)
    this.setState(
      {
        repeat: newYearsInt ? newYearsInt.toString() : ''
      },
      () => {
        if (this.state.repeat) {
          this.props.onSaveAlarmRepetition(
            Constants.RepeatTypes.YEARS,
            this.state.repeat
          )
          FirebaseProxy.logEvent(
            Constants.UserAnalyticsEvents.CHOOSE_YEARLY_REPEAT_TYPE,
            {
              [Constants.UserAnalyticsEventParameters.REPEAT_STRING]:
                Constants.REPEAT_EVERY_N_YEARS_REPEAT_STRING
            }
          )
        }
      }
    )
  }

  onChooseLastDayOfMonth = monthName => {
    this.everyNYearsRef.current?.blur()
    this.setState(
      {
        repeat: Constants.LAST_DAY_OF_MONTH_REPEAT_STRING + ':' + monthName
      },
      () => {
        this.props.onSaveAlarmRepetition(
          Constants.RepeatTypes.YEARS,
          this.state.repeat
        )
        FirebaseProxy.logEvent(
          Constants.UserAnalyticsEvents.CHOOSE_YEARLY_REPEAT_TYPE,
          {
            [Constants.UserAnalyticsEventParameters.REPEAT_STRING]:
              Constants.LAST_DAY_OF_MONTH_REPEAT_STRING
          }
        )
      }
    )
  }

  onChooseDayOfWeekInMonthRepeatOption = (
    weekNumberOfMonth,
    dayName,
    monthName
  ) => {
    this.everyNYearsRef.current?.blur()
    this.setState(
      {
        repeat:
          Constants.DAY_OF_WEEK_IN_MONTH_REPEAT_STRING +
          ':' +
          weekNumberOfMonth +
          ':' +
          dayName +
          ':' +
          monthName
      },
      () => {
        this.props.onSaveAlarmRepetition(
          Constants.RepeatTypes.YEARS,
          this.state.repeat
        )
        FirebaseProxy.logEvent(
          Constants.UserAnalyticsEvents.CHOOSE_YEARLY_REPEAT_TYPE,
          {
            [Constants.UserAnalyticsEventParameters.REPEAT_STRING]:
              Constants.DAY_OF_WEEK_IN_MONTH_REPEAT_STRING
          }
        )
      }
    )
  }

  onChooseLastDayOfWeekInMonthRepeatOption = (dayName, monthName) => {
    this.everyNYearsRef.current?.blur()
    this.setState(
      {
        repeat:
          Constants.LAST_DAY_OF_WEEK_IN_MONTH_REPEAT_STRING +
          ':' +
          dayName +
          ':' +
          monthName
      },
      () => {
        this.props.onSaveAlarmRepetition(
          Constants.RepeatTypes.YEARS,
          this.state.repeat
        )
        FirebaseProxy.logEvent(
          Constants.UserAnalyticsEvents.CHOOSE_YEARLY_REPEAT_TYPE,
          {
            [Constants.UserAnalyticsEventParameters.REPEAT_STRING]:
              Constants.LAST_DAY_OF_WEEK_IN_MONTH_REPEAT_STRING
          }
        )
      }
    )
  }

  render() {
    let selectedOption = 0
    let yearsValue = '',
      yearsInt = 0

    if (
      this.state.repeat.startsWith(Constants.LAST_DAY_OF_MONTH_REPEAT_STRING)
    ) {
      selectedOption = 4
    } else if (
      this.state.repeat.startsWith(Constants.DAY_OF_WEEK_IN_MONTH_REPEAT_STRING)
    ) {
      selectedOption = 2
    } else if (
      this.state.repeat.startsWith(
        Constants.LAST_DAY_OF_WEEK_IN_MONTH_REPEAT_STRING
      )
    ) {
      selectedOption = 3
    } else {
      yearsValue = this.state.repeat
      yearsInt = parseInt(this.state.repeat)
      selectedOption = 1
    }

    const dayNumber = moment(this.props.date).day()
    const monthNumber = moment(this.props.date).month()
    let dayName = Constants.DayRepetitionOptions[dayNumber]
    let monthName = Constants.MonthRepetitionOptions[monthNumber]
    let weekNumberOfDayInMonth = AlarmUtils.computeDayOfWeekInMonthForDate(
      this.props.date
    )
    const showLastDayOfMonthRepeatOption =
      AlarmUtils.checkIfDayOfMonthIsLastDayOfWeekInMonth(this.props.date)

    return (
      <Box width="450px">
        <Box
          onClick={this.onChooseYears}
          direction="row"
          pad="small"
          justify="between"
          align="center"
          background={
            selectedOption === 1
              ? colorThemes.getColorTheme(this.props.colorScheme)
                  .listItemSelectedColor
              : colorThemes.getColorTheme(this.props.colorScheme)
                  .textBackgroundColor
          }>
          <Box direction="row" align="center">
            <Text>{I18n.t('repeatEvery')}</Text>
            <Box pad={{ horizontal: 'small' }} width="80px">
              <TextInput
                ref={this.everyNYearsRef}
                size="medium"
                value={yearsValue}
                onChange={this.onChangeYears}
                margin="small"
              />
            </Box>
            <Text margin={{ right: '10px' }}>{I18n.t('repeatEveryYears')}</Text>
          </Box>
          {selectedOption === 1 && <Checkmark />}
        </Box>
        {selectedOption === 1 && (
          <Box
            pad="small"
            background={
              colorThemes.getColorTheme(this.props.colorScheme)
                .listItemSelectedColor
            }>
            <SecondaryText>
              {isEmpty(yearsValue)
                ? I18n.t('numberOfYearsShouldBeAtLeastOne')
                : I18n.t('alarmWillRingEveryNYears', {
                    yearWithOrdinal: Utils.getNumberWithOrdinal(yearsInt),
                    count: yearsInt - 1
                  })}
            </SecondaryText>
          </Box>
        )}
        <Box
          direction="row"
          pad="small"
          align="center"
          justify="between"
          onClick={() =>
            this.onChooseDayOfWeekInMonthRepeatOption(
              weekNumberOfDayInMonth,
              dayName,
              monthName
            )
          }
          background={
            selectedOption === 2
              ? colorThemes.getColorTheme(this.props.colorScheme)
                  .listItemSelectedColor
              : colorThemes.getColorTheme(this.props.colorScheme)
                  .textBackgroundColor
          }>
          <Text>
            {I18n.t('dayOfWeekInMonthInYear', {
              weekNumberOfDayInMonth: DateTimeUtils.getDayOfWeekInMonthOrdinal(
                weekNumberOfDayInMonth
              ),
              dayName: I18n.t(dayName),
              monthName: I18n.t(monthName)
            })}
          </Text>
          {selectedOption === 2 && <Checkmark />}
        </Box>
        {showLastDayOfMonthRepeatOption && (
          <Box
            direction="row"
            pad="small"
            align="center"
            justify="between"
            onClick={() =>
              this.onChooseLastDayOfWeekInMonthRepeatOption(dayName, monthName)
            }
            background={
              selectedOption === 3
                ? colorThemes.getColorTheme(this.props.colorScheme)
                    .listItemSelectedColor
                : colorThemes.getColorTheme(this.props.colorScheme)
                    .textBackgroundColor
            }>
            <Text>
              {I18n.t('lastDayOfWeekInMonthInYear', {
                dayName: I18n.t(dayName),
                monthName: I18n.t(monthName)
              })}
            </Text>
            {selectedOption === 3 && <Checkmark />}
          </Box>
        )}
        <Box
          direction="row"
          pad="small"
          align="center"
          justify="between"
          onClick={() => this.onChooseLastDayOfMonth(monthName)}
          background={
            selectedOption === 4
              ? colorThemes.getColorTheme(this.props.colorScheme)
                  .listItemSelectedColor
              : colorThemes.getColorTheme(this.props.colorScheme)
                  .textBackgroundColor
          }>
          <Text>
            {I18n.t('lastDayOfMonthInYear', {
              monthName: I18n.t(monthName)
            })}
          </Text>
          {selectedOption === 4 && <Checkmark />}
        </Box>
      </Box>
    )
  }
}

const mapStateToProps = state => ({
  colorScheme: state.userSettings.webColorScheme
})

export default connect(mapStateToProps)(SelectYearsRepetition)
