import React, { useEffect, useMemo, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Selectors, ActionCreators, AlarmUtils } from 'galarm-shared'
import {
  Box,
  Card,
  CardBody,
  CheckBox,
  Layer,
  Text,
  TextInput,
  Heading,
  TextArea
} from 'grommet'
import { Constants, I18n, colorThemes, GlobalConfig } from 'galarm-config'
import { FirebaseProxy, NavigationUtils } from 'galarm-ps-api'
import { Fab, Action } from 'react-tiny-fab'
import { Add, Close, FormEdit, FormTrash } from 'grommet-icons'
import TintedImage from './TintedImage'
import { SecondaryText } from 'web-components'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

const { defaultChecklistSelector, upgradePurchasedSelector } = Selectors

const NewTaskFab = ({ onAddTask }) => {
  const colorScheme = useSelector(state => state.userSettings.webColorScheme)
  const [createNewTask, setCreateNewTask] = useState(false)
  const [newTaskName, setNewTaskName] = useState('')

  const onNewTask = () => {
    setCreateNewTask(true)
  }

  return (
    <>
      <Fab
        mainButtonStyles={{
          backgroundColor: colorThemes.getColorTheme(colorScheme).primary
        }}
        style={{
          bottom: 0,
          left: 0
        }}
        icon={<Add color="white" />}
        event="hover"
        onClick={onNewTask}
        text={I18n.t('newTask')}
      />
      {createNewTask && (
        <Layer
          background="lightTint"
          onEsc={() => setCreateNewTask(false)}
          onClickOutside={() => setCreateNewTask(false)}>
          <Box overflow="auto" width="500px" margin="medium" gap="medium">
            <Box direction="row" align="center" justify="between">
              <Heading level={3} margin="none">
                {I18n.t('enterTaskName')}
              </Heading>
              <Close
                color="textColor"
                onClick={() => setCreateNewTask(false)}
              />
            </Box>
            <TextArea
              autoFocus
              placeholder={I18n.t('taskName')}
              onChange={event => {
                if (event.target.value.endsWith('\n')) {
                  onAddTask(newTaskName)
                  setNewTaskName('')
                  return
                }

                setNewTaskName(event.target.value)
              }}
              value={newTaskName}
            />
          </Box>
        </Layer>
      )}
    </>
  )
}

const NewTaskAndTaskListFab = ({ onAddTask, onAddTaskList }) => {
  const colorScheme = useSelector(state => state.userSettings.webColorScheme)
  const upgradePurchased = useSelector(upgradePurchasedSelector)

  const [createNewTask, setCreateNewTask] = useState(false)
  const [newTaskName, setNewTaskName] = useState('')
  const [createNewTaskList, setCreateNewTaskList] = useState(false)
  const [newTaskListName, setNewTaskListName] = useState('')

  const onNewTask = () => {
    setCreateNewTask(true)
  }

  const onNewTaskList = () => {
    if (!upgradePurchased) {
      NavigationUtils.showGalarmPremiumScreen('taskListPremiumFeature')
      return
    }

    setCreateNewTaskList(true)
  }

  return (
    <>
      <Fab
        mainButtonStyles={{
          backgroundColor: colorThemes.getColorTheme(colorScheme).primary
        }}
        style={{
          bottom: 0,
          left: 0
        }}
        icon={<Add color="white" />}
        event="hover"
        alwaysShowTitle={true}>
        <Action
          text={I18n.t('newTask')}
          onClick={onNewTask}
          style={{
            backgroundColor: colorThemes.getColorTheme(colorScheme).primary
          }}>
          <TintedImage
            tintColor="white"
            src={require('galarm-res/img/web/ic_task.svg').default}
          />
        </Action>
        <Action
          text={I18n.t('newTaskList')}
          onClick={onNewTaskList}
          style={{
            backgroundColor: colorThemes.getColorTheme(colorScheme).primary
          }}>
          <TintedImage
            tintColor="white"
            src={require('galarm-res/img/web/ic_task_list.svg').default}
          />
        </Action>
      </Fab>
      {createNewTask && (
        <Layer
          background="lightTint"
          onEsc={() => setCreateNewTask(false)}
          onClickOutside={() => setCreateNewTask(false)}>
          <Box overflow="auto" width="500px" margin="medium" gap="medium">
            <Box direction="row" align="center" justify="between">
              <Heading level={3} margin="none">
                {I18n.t('enterTaskName')}
              </Heading>
              <Close
                color="textColor"
                onClick={() => setCreateNewTask(false)}
              />
            </Box>
            <TextArea
              autoFocus
              placeholder={I18n.t('taskName')}
              onChange={event => {
                if (event.target.value.endsWith('\n')) {
                  onAddTask(newTaskName)
                  setNewTaskName('')
                  return
                }

                setNewTaskName(event.target.value)
              }}
              value={newTaskName}
            />
          </Box>
        </Layer>
      )}
      {createNewTaskList && (
        <Layer
          background="lightTint"
          onEsc={() => setCreateNewTaskList(false)}
          onClickOutside={() => setCreateNewTaskList(false)}>
          <Box overflow="auto" width="500px" margin="medium" gap="medium">
            <Box direction="row" align="center" justify="between">
              <Heading level={3} margin="none">
                {I18n.t('enterTaskListName')}
              </Heading>
              <Close
                color="textColor"
                onClick={() => setCreateNewTaskList(false)}
              />
            </Box>
            <TextInput
              autoFocus
              placeholder={I18n.t('taskListName')}
              onChange={event => setNewTaskListName(event.target.value)}
              value={newTaskListName}
              onKeyUp={event => {
                if (event.key === 'Enter') {
                  onAddTaskList(newTaskListName)
                  setNewTaskListName('')
                  setCreateNewTaskList(false)
                }
              }}
            />
          </Box>
        </Layer>
      )}
    </>
  )
}

// eslint-disable-next-line no-unused-vars
const TaskSummary = ({ task, onChangeItemName, onRemoveItem, onCheckItem }) => {
  const [itemChecked, setItemChecked] = useState(task.checked)
  const [showDeleteItem, setShowDeleteItem] = useState(false)
  const [showEditItem, setShowEditItem] = useState(false)
  const [editedItemName, setEditedItemName] = useState(task.name)

  const changeItemName = () => {
    onChangeItemName(task.id, editedItemName)
  }

  const onCheckItemInternal = event => {
    setItemChecked(event.target.checked)
    onCheckItem(task)
  }

  const onRemoveItemInternal = () => {
    NavigationUtils.showAlert(
      I18n.t('confirm'),
      I18n.t('confirmDeleteTask', { taskName: task.name }),
      [
        {
          text: I18n.t('no')
        },
        {
          text: I18n.t('yes'),
          onPress: () => onRemoveItem(task.id)
        }
      ],
      false
    )
  }

  return (
    <Box
      direction="row"
      align="center"
      onMouseEnter={() => setShowDeleteItem(true)}
      onMouseLeave={() => setShowDeleteItem(false)}>
      <CheckBox
        size="large"
        checked={itemChecked}
        onChange={onCheckItemInternal}
      />
      <Box
        flex
        margin={{ vertical: 'xsmall', left: 'small' }}
        onClick={() => setShowEditItem(true)}>
        <Text>{task.name}</Text>
      </Box>
      {showDeleteItem ? (
        <Box
          onClick={onRemoveItemInternal}
          pad={{ left: 'none', right: 'small' }}>
          <FormTrash />
        </Box>
      ) : (
        <Box pad="small" />
      )}
      {showEditItem && (
        <Layer
          background="lightTint"
          onEsc={() => setShowEditItem(false)}
          onClickOutside={() => setShowEditItem(false)}>
          <Box overflow="auto" width="500px" margin="medium" gap="medium">
            <Box direction="row" align="center" justify="between">
              <Heading level={3} margin="none">
                {I18n.t('editTask')}
              </Heading>
              <Close color="textColor" onClick={() => setShowEditItem(false)} />
            </Box>
            <TextArea
              autoFocus
              placeholder={I18n.t('taskName')}
              onChange={event => {
                if (event.target.value.endsWith('\n')) {
                  changeItemName()
                  setShowEditItem(false)
                  return
                }

                setEditedItemName(event.target.value)
              }}
              value={editedItemName}
            />
          </Box>
        </Layer>
      )}
    </Box>
  )
}

const TaskListSummary = ({ taskList, onEditTaskList, onChooseTaskList }) => {
  const { name, items } = taskList

  const [taskListName, setTaskListName] = useState(name)

  // Show the edit task list dialog
  const [editTaskList, setEditTaskList] = useState(false)

  // Show the edit icon when the mouse is over the task list
  const [showEditTaskList, setShowEditTaskList] = useState(false)

  const numTasks = items.length
  const numCompletedTasks = items.filter(item => item.checked).length

  const onEditTaskListInternal = event => {
    event.stopPropagation()
    setEditTaskList(true)
    return true
  }

  return (
    <Box flex>
      <Box
        flex
        direction="row"
        align="start"
        onMouseEnter={() => setShowEditTaskList(true)}
        onMouseLeave={() => setShowEditTaskList(false)}>
        <Box flex gap="xsmall" onClick={() => onChooseTaskList(taskList.id)}>
          <Text>{name}</Text>
          <SecondaryText>
            {numCompletedTasks > 0
              ? I18n.t('numTasksWithCompleted', {
                  count: numTasks,
                  completed: numCompletedTasks
                })
              : I18n.t('numTasks', { count: numTasks })}
          </SecondaryText>
        </Box>
        {showEditTaskList && (
          <Box onClick={onEditTaskListInternal} pad="small">
            <FormEdit />
          </Box>
        )}
      </Box>
      {editTaskList && (
        <Layer
          background="lightTint"
          onEsc={() => setEditTaskList(false)}
          onClickOutside={() => setEditTaskList(false)}>
          <Box overflow="auto" width="500px" margin="medium" gap="small">
            <Heading level={3} margin="none">
              {I18n.t('editTaskListName')}
            </Heading>
            <TextInput
              autoFocus
              placeholder={I18n.t('taskListName')}
              onChange={event => setTaskListName(event.target.value)}
              value={taskListName}
              onKeyUp={event => {
                if (event.key === 'Enter') {
                  onEditTaskList(taskList, taskListName)
                  setEditTaskList(false)
                }
              }}
            />
          </Box>
        </Layer>
      )}
    </Box>
  )
}

const TaskList = () => {
  const dispatch = useDispatch()

  const allTaskLists = useSelector(state => state.alarms.checklists)
  const defaultTaskList = useSelector(defaultChecklistSelector)
  let currentlySelectedTaskList
  let currentlySelectedTaskListId = useSelector(
    state => state.appState.currentlySelectedTaskListId
  )
  if (!currentlySelectedTaskListId) {
    currentlySelectedTaskListId = defaultTaskList?.id
    currentlySelectedTaskList = defaultTaskList
  } else {
    currentlySelectedTaskList = allTaskLists.find(
      taskList => taskList.id === currentlySelectedTaskListId
    )
  }

  useEffect(() => {
    // If default checklist doesn't exist, create it
    if (!defaultTaskList) {
      AlarmUtils.createDefaultTaskList()
    }
  }, [defaultTaskList])

  console.log(
    'currentlySelectedTaskList',
    currentlySelectedTaskListId,
    currentlySelectedTaskList
  )

  if (!currentlySelectedTaskListId) {
    return (
      <Box flex align="center" justify="center">
        <Text color="darkTint">{I18n.t('loadingTasks')}</Text>
      </Box>
    )
  }

  const {
    id,
    creator = GlobalConfig.uid,
    name = '',
    items: origItems = [],
    isDefault = false,
    showCompletedItems = true,

    // These are not stored on the database
    checklists = []
  } = currentlySelectedTaskList

  const [items, setItems] = useState(origItems)

  console.log('origItems', origItems)
  console.log('items', items)

  let orderedItems = useMemo(() => {
    return origItems.slice().sort((a, b) => b.order - a.order)
  }, [items, currentlySelectedTaskList])

  const onChangeItemName = (itemId, newName) => {
    const newItems = orderedItems.map(item => {
      if (item.id === itemId) {
        return { ...item, name: newName }
      }
      return item
    })
    setItems(newItems)
    dispatch(
      ActionCreators.updateChecklist({
        id,
        creator,
        name,
        isDefault,
        showCompletedItems,
        items: newItems,
        lastUpdatedAt: Date.now()
      })
    )
    FirebaseProxy.logEvent(
      Constants.UserAnalyticsEvents.RENAME_ITEM_IN_CHECKLIST,
      {}
    )
  }

  const onRemoveItem = itemId => {
    const newItems = orderedItems.filter(item => item.id !== itemId)
    setItems(newItems)
    dispatch(
      ActionCreators.updateChecklist({
        id,
        creator,
        name,
        isDefault,
        showCompletedItems,
        items: newItems,
        lastUpdatedAt: Date.now()
      })
    )
    FirebaseProxy.logEvent(
      Constants.UserAnalyticsEvents.REMOVE_ITEM_IN_CHECKLIST,
      {}
    )
  }

  const onCheckItem = checkedItem => {
    const index = orderedItems.findIndex(item => item.id === checkedItem.id)
    const newItems = [...orderedItems]
    newItems[index].checked = !checkedItem.checked
    setItems(newItems)
    dispatch(
      ActionCreators.updateChecklist({
        id,
        creator,
        name,
        isDefault,
        showCompletedItems,
        items: newItems,
        lastUpdatedAt: Date.now()
      })
    )
    FirebaseProxy.logEvent(
      checkedItem.checked
        ? Constants.UserAnalyticsEvents.UNCHECK_ITEM_IN_CHECKLIST
        : Constants.UserAnalyticsEvents.CHECK_ITEM_IN_CHECKLIST,
      {}
    )
  }

  const onChooseTaskList = taskListId => {
    dispatch(ActionCreators.setCurrentlySelectedTaskListId(taskListId))
  }

  const onAddTask = taskName => {
    const newItems = [
      ...orderedItems,
      {
        id: GlobalConfig.rootFirebaseRef.push().key,
        order: orderedItems.length,
        name: taskName,
        checked: false
      }
    ]
    setItems(newItems)
    dispatch(
      ActionCreators.updateChecklist({
        id,
        creator,
        name,
        isDefault,
        showCompletedItems,
        items: newItems,
        lastUpdatedAt: Date.now()
      })
    )
    FirebaseProxy.logEvent(
      Constants.UserAnalyticsEvents.ADD_ITEM_TO_CHECKLIST,
      {}
    )
  }

  const onAddTaskList = taskListName => {
    const newChecklist = {
      id: GlobalConfig.rootFirebaseRef.push().key,
      creator: GlobalConfig.uid,
      name: taskListName,
      items: [],
      lastUpdatedAt: Date.now(),
      showCompletedItems: true
    }
    dispatch(ActionCreators.addChecklist(newChecklist))
    dispatch(ActionCreators.setCurrentlySelectedTaskListId(newChecklist.id))
    FirebaseProxy.logEvent(Constants.UserAnalyticsEvents.CREATE_CHECKLIST, {})
  }

  const onEditTaskList = (taskList, taskListName) => {
    dispatch(
      ActionCreators.updateChecklist({
        ...taskList,
        name: taskListName,
        lastUpdatedAt: Date.now()
      })
    )
    FirebaseProxy.logEvent(Constants.UserAnalyticsEvents.RENAME_CHECKLIST, {})
  }

  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    margin: `0 0 5px 0`,

    // change background colour if dragging
    background: 'transparent',

    // styles we need to apply on draggables
    ...draggableStyle
  })

  const reorder = (startIndex, endIndex) => {
    if (startIndex === endIndex) {
      return items
    }

    // Reassign order values in descending order
    const result = [...orderedItems]
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)
    for (let i = 0; i < result.length; i++) {
      result[i].order = result.length - i - 1
    }
    console.log('reordered', result)
    return result
  }

  const onDragEnd = result => {
    // dropped outside the list
    if (!result.destination) {
      return
    }

    const newItems = reorder(result.source.index, result.destination.index)
    setItems(newItems)
    dispatch(
      ActionCreators.updateChecklist({
        id,
        creator,
        name,
        isDefault,
        showCompletedItems,
        items: newItems,
        lastUpdatedAt: Date.now()
      })
    )

    FirebaseProxy.logEvent(
      Constants.UserAnalyticsEvents.CHANGE_ITEM_ORDER_IN_CHECKLIST,
      {}
    )
  }

  if (!showCompletedItems) {
    orderedItems = orderedItems.filter(item => !item.checked)
  }

  console.log('orderedItems', orderedItems)

  return (
    <Box flex overflow="auto" style={{ position: 'relative' }}>
      <Box>
        <Box flex={{ shrink: 0 }} gap="xsmall" pad="small">
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {provided => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {orderedItems.map((item, index) => {
                    return (
                      <Draggable
                        key={item.id}
                        draggableId={item.id}
                        index={index}>
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            )}>
                            <Card
                              flex={{ shrink: 0 }}
                              key={item.id + item.name + item.checked}
                              background="textBackgroundColor">
                              <CardBody pad="small" gap="small">
                                <TaskSummary
                                  task={item}
                                  onChangeItemName={onChangeItemName}
                                  onRemoveItem={onRemoveItem}
                                  onCheckItem={onCheckItem}
                                />
                              </CardBody>
                            </Card>
                          </div>
                        )}
                      </Draggable>
                    )
                  })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </Box>
        {checklists.length > 0 && (
          <Box flex={{ shrink: 0 }}>
            <Box background="lightTint" pad="small">
              <Text color="darkTint">{I18n.t('otherChecklists')}</Text>
            </Box>
            <Box gap="xsmall" pad="small">
              {checklists.map(checklist => {
                return (
                  <Card
                    flex={{ shrink: 0 }}
                    key={checklist.id + checklist.name}
                    background="textBackgroundColor">
                    <CardBody direction="row" pad="small" gap="small">
                      <TaskListSummary
                        taskList={checklist}
                        onEditTaskList={onEditTaskList}
                        onChooseTaskList={onChooseTaskList}
                      />
                    </CardBody>
                  </Card>
                )
              })}
            </Box>
          </Box>
        )}
      </Box>
      {currentlySelectedTaskListId === defaultTaskList.id ? (
        <NewTaskAndTaskListFab
          onAddTask={onAddTask}
          onAddTaskList={onAddTaskList}
        />
      ) : (
        <NewTaskFab onAddTask={onAddTask} />
      )}
    </Box>
  )
}

export default TaskList
